import Container from '@material-ui/core/Container'
import React, {useState} from 'react'
import {Button, CircularProgress, Snackbar, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {noop, get} from "lodash/fp";
import {getNextRoundTypeToSelect, getRounds, getSelectedPairing} from "../../../../reducers/adminSelectors";
import {saveRoundWinners, selectPairing} from "../../../../actions/adminActions";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import Grid from "@material-ui/core/Grid";
import {Alert, Checkbox} from "@mui/material";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {getRoundWinnerFieldFromRoundType} from "./OralArgUtils";
import {Link} from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TeamName from 'components/team/TeamName';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const SelectWinner = ({selectedPairingId = 0, getRounds = noop,
                          getNextRoundTypeToSelect = noop,
                          selectedRoundType = '',
                          getSelectedPairing = noop,
                          selectedPairing = {},
                          saveRoundWinners = noop,
                          selectPairing = noop,
                          saveWinnerError = ''}) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSaving, setIsSaving] = React.useState(false)
    const [saved, setSaved] = useState(false);
    const [winner,setWinner] = useState()

    const onSave = async e => {
        e.preventDefault()
        setIsSaving(true)
        const nextRoundType = getNextRoundTypeToSelect(selectedRoundType)
        const loser = winner === selectedPairing.claimant.id ? selectedPairing.respondent.id : selectedPairing.claimant.id;
        const result = await saveRoundWinners([{ teamId:winner, roundType: nextRoundType, winner:  true},{ teamId:loser, roundType: nextRoundType, winner:  false}])
        await selectPairing(selectedPairingId)
        setIsSaving(false)
        if (result) {
            setSaved(true)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                getRounds()
                const result = await getSelectedPairing(selectedPairingId)
                const currentRoundTypeField = getRoundWinnerFieldFromRoundType(getNextRoundTypeToSelect(result.roundType))
                const claimantWinners = get('claimant.EliminationRoundWinners',result)
                const respondentWinners = get('respondent.EliminationRoundWinners',result)
                if (claimantWinners[currentRoundTypeField] === true) {
                    setWinner(result.claimant.id)
                }else if (respondentWinners[currentRoundTypeField] === true){
                    setWinner(result.respondent.id)
                }

                setIsLoading(false)
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <Container maxWidth='xl' className={classes.root}>
            {isLoading &&
                <CircularProgress/>
            }
            {!isLoading &&
                <form onSubmit={onSave}>
                    <Button style={{ margin: '0 0 2rem 0' }} variant='text' component={Link}  to='/admin/oral-arguments' >
                        <ArrowBackIosIcon /> Back to Oral  Arguments
                    </Button>
                    <Typography variant='h4'>Select Winner</Typography>
                    <Typography> <strong><TeamName team={selectedPairing.claimant} adminUse={true} /></strong> vs. <strong><TeamName team={selectedPairing.respondent} adminUse={true} /></strong> ({format(utcToZonedTime(selectedPairing.pairingTime, 'Asia/Hong_Kong'), 'iii, dd MMMM hh:mm aaa')})</Typography>

                    <Grid container style={{flexGrow: 1, padding: '20px',}} spacing={6}>
                        <Grid item xs={4} md={4}>
                            <Typography>Claimant: <TeamName team={selectedPairing.claimant} adminUse={true} /> ({selectedPairing.claimant.jurisdiction}) </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox  checked={winner === selectedPairing.claimant.id}
                                                   name='claimantWinner'
                                                   onChange={() => setWinner(selectedPairing.claimant.id)}
                                                   color='primary' />
                                    }
                                    label='Winner?'
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: 1, padding: '20px',}} spacing={6}>
                        <Grid item xs={4} md={4}>
                            <Typography>Respondent: <TeamName team={selectedPairing.respondent} adminUse={true} /> ({selectedPairing.respondent.jurisdiction}) </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox  checked={winner === selectedPairing.respondent.id}
                                                   name='respondentWinner'
                                                   onChange={() => setWinner(selectedPairing.respondent.id)}
                                                   color='primary' />
                                    }
                                    label='Winner?'
                                />
                            </FormGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Button variant='contained'
                                type='submit'
                                disabled={isSaving}>Save</Button>
                        {isSaving &&
                            <CircularProgress style={{marginLeft: '2rem'}}/>
                        }
                    </Grid>
                    {saveWinnerError &&
                        <Typography color='error'>{saveWinnerError}</Typography>
                    }
                    <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                        <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                            Winner saved
                        </Alert>
                    </Snackbar>
                </form>

            }
        </Container>
    )
}


SelectWinner.propTypes = {
    selectedPairing: PropTypes.object,
    getSelectedPairing: PropTypes.func,
}

export default connect(
    (state, ownProps) => ({
        selectedPairing: state.admin.selectedPairing,
        selectedPairingId: ownProps.match.params.pairingId,
        selectedRoundType: state.admin.selectedRoundType,
        saveWinnerError: state.admin.saveWinnerError
    }), {
        getRounds: getRounds,
        getSelectedPairing: getSelectedPairing,
        getNextRoundTypeToSelect: getNextRoundTypeToSelect,
        saveRoundWinners: saveRoundWinners,
        selectPairing: selectPairing
    })(SelectWinner)

