import { applyMiddleware, compose, createStore } from 'redux'
import rootReducer from './reducers/rootReducer'
import { compact } from 'lodash/fp'
import { routerMiddleware } from 'connected-react-router'
/**
 * Enable Redux Dev Tools Extension when running in browser that has it installed
 * TODO: consider logOnly or same settings for production
 * (https://github.com/zalmoxisus/redux-devtools-extension/blob/master/npm-package/logOnly.js)
 */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// :: void => bool
const isDev = () => process.env.REACT_APP_ENV === 'development'

const configureStore = (initialState = {}, history) => {
    const middlewares = compact([
        require('redux-thunk').default,
        routerMiddleware(history),
        isDev() && require(`redux-logger`).logger
    ])
    return createStore(
        rootReducer(history),
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    )
}

export default configureStore
