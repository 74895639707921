import {Button, CircularProgress, FormGroup, Snackbar, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {filter, find, get, isEmpty, map, noop, size, without, xor} from "lodash/fp";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getSelectedArbitrator, getJuryTeams} from "../../reducers/arbitratorSelectors";
import * as actions from "../../actions/arbitratorActions";
import { TEAM_ROLE_TYPE } from "../../utils/constants";
import {Alert} from "@mui/material";
import TeamName from "../team/TeamName";

const JuryRankings = ({
    juryRankings = [],
    claimantJuryTeams = [],
    respondentJuryTeams = [],
    saveJuryRankings = noop,
    getJuryTeams = noop,
    juryRankingsSaveError = '',
    teamRoleType
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [saved, setSaved] = useState(false);
    const [rankings, setRankings] = useState([])
    const [isLoading, setIsLoading] = useState(true);

    const juryTeams = teamRoleType === TEAM_ROLE_TYPE.CLAIMANT ? claimantJuryTeams : respondentJuryTeams

    useEffect(() => {
        async function getData() {
            setIsLoading(true)
            await getJuryTeams(teamRoleType)
            setIsLoading(false)
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const canSave = () => {
        //Every team must have a ranking
        const ids = map(cp => cp.team.id, juryTeams)
        const rankingIds = map(r => r.teamId,rankings)

        return !isEmpty(rankingIds) && xor(ids, rankingIds).length === 0
    }

    const onSave = async (e) => {
        try {
            e.preventDefault()
            setIsSaving(true)
            const didSave = await saveJuryRankings(rankings, teamRoleType)

            if (didSave) {
                setSaved(true)
            }
        } finally {
            setIsSaving(false)
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const onRankingChanged = (e, teamId) => {
        const val = parseInt(e.target.value)
        if (e.target.checked) {
            setRankings([...rankings, {rank: val, teamId}])
        }else{
            const newRankings = without(filter(r => r.teamId === teamId && r.rank === val,rankings),rankings)
            setRankings(newRankings)
        }
    }

    const isRanked = (teamId,rankValue) =>{
        const ranks = isRankingCompleted() ? juryRankings : rankings

        const val = parseInt(rankValue)
        const hasRanking = find(r => r.teamId === teamId && r.rank === val,ranks)
        return hasRanking !== undefined
    }

    const isBlockedFromRanking = (teamId,rankValue) =>{
        const val = parseInt(rankValue)
        const teamRanking = find(r => r.teamId === teamId,rankings)
        const rankedValues = map(r => r.rank,rankings)
        if (teamRanking === undefined && !rankedValues.includes(val))
            return false

        if (teamRanking !== undefined && teamRanking.rank !== val)
            return true

        return rankedValues.includes(val) && get('rank',teamRanking) !== val
    }

    const isRankingCompleted = ()  => {
        return !isEmpty(juryRankings)
    }

    const buildRankingRows = team => {
        const result = []
        for (let i = 1; i <= size(juryTeams); i++) {
            const ranking = {value: (size(juryTeams) - i) +1, name: `${(size(juryTeams) - i) + 1}`}
            result.unshift(
                <FormControlLabel
                    key={`${team.id}-${ranking.value}`}
                    className='arbitrator-pairings-control-label'
                    disabled={isBlockedFromRanking(team.id, ranking.value)}
                    control={
                        <div style={{position: 'relative'}}>
                            <Checkbox
                                checked={isRanked(team.id, ranking.value)}
                                disabled={isBlockedFromRanking(team.id, ranking.value) || isRankingCompleted()}
                                onChange={e => onRankingChanged(e, team.id)}
                                value={ranking.value}
                                color='primary'
                            />
                            {ranking.value === 1 &&
                                <Typography style={{
                                    margin: '0',
                                    position: 'absolute',
                                    top: '28px',
                                    left: '0',
                                    right: '0',
                                    textAlign: 'center',
                                    fontSize: '11px',
                                }}
                                >worst</Typography>
                            }
                            {ranking.value === size(juryTeams) &&
                                <Typography  style={{
                                    margin: '0',
                                    position: 'absolute',
                                    top: '28px',
                                    left: '0',
                                    right: '0',
                                    textAlign: 'center',
                                    fontSize: '11px',
                                }}
                                >best</Typography>
                            }
                        </div>
                    }
                    label={ranking.name}
                />)
        }
        return result;
    }

    return (
    <form onSubmit={onSave}>
        {isLoading &&
            <CircularProgress/>
        }
        {!isLoading &&
        <>
            <Grid style={{padding: 0}}>
                {map(p =>   {
                    return (
                        <Grid item key={p.team.id} className='arbitrator-pairing'>
                            <Typography className='arbitrator-pairing-school'>
                                <a href={p.url}><TeamName team={p.team}/> memorandum.pdf</a>
                            </Typography>

                            <FormGroup row style={{alignItems: 'center'}}>
                                <label className='label-ranking'>Ranking:</label>
                                {buildRankingRows(p.team)}
                            </FormGroup>
                            {/*</Box>*/}
                        </Grid>)
                }, juryTeams)
                }
            </Grid>
            <Grid style={{padding: 0}}>
                {!isRankingCompleted() &&
                <Grid item>
                    <Typography style={{margin: '2rem 0 0'}}>Your final rankings may only be submitted once. Once you have submitted your rankings, no changes can be made.</Typography>
                </Grid>   
                }            
                
                <Grid item style={{marginTop: '2rem', display: 'flex'}}>
                    {!isRankingCompleted() &&
                    <>                        
                        <Button variant='contained'
                                type='submit'
                                color='primary'
                                disabled={isSaving || !canSave()}                                
                                >Save</Button>
                    </>
                    }
                    {isRankingCompleted() &&
                    <Typography variant="body1">Rankings have been submitted.</Typography>
                    }
                    {isSaving &&
                    <CircularProgress size={30} style={{marginLeft: '2rem'}}/>
                    }
                    {juryRankingsSaveError &&
                    <Typography color='error'>{juryRankingsSaveError}</Typography>
                    }
                </Grid>
                <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000}>
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Rankings saved
                    </Alert>
                </Snackbar>
            </Grid>
        </>
        }
    </form>
    )
}


JuryRankings.propTypes = {
    saveJuryRankings: PropTypes.func,
    teamRoleType: PropTypes.oneOf([TEAM_ROLE_TYPE.CLAIMANT,TEAM_ROLE_TYPE.RESPONDENT])
}

export default connect(
    (state, ownProps) => ({
        juryRankingsSaveError : state.arbitrator.juryRankingsSaveError,
        claimantJuryTeams: state.arbitrator.claimantJuryTeams,
        respondentJuryTeams: state.arbitrator.respondentJuryTeams,
        ...ownProps
    }),
    {
        getSelectedArbitrator: getSelectedArbitrator,
        saveJuryRankings: actions.saveJuryRankings,
        getJuryTeams : getJuryTeams
    }
)(JuryRankings)