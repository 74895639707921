import * as actionTypes from '../actions/actionTypes/userActionTypes'

const user = (state = {}, action) => {
    const { type, payload } = action

    switch(type) {
        case actionTypes.USER_LOGIN_SUCCEEDED:
        case actionTypes.USER_LOGIN_FROM_SESSION_SUCCEEDED:
            return { ...payload, username: payload.idToken.payload.email }
        case actionTypes.USER_INFO_SUCCEEDED:
            return {...state, ...payload}
        case actionTypes.USER_LOGIN_FAILED:
            return { ...state, loginError: 'Error logging in. Make sure your email address and your password is correct.'}
        default:
            return state
    }
}

export default user