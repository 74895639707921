import {Button, Grid, Typography} from "@material-ui/core";
import {DataGridPro, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid-pro";
import React from "react";
import {get, getOr, last, noop} from 'lodash/fp'
import { format } from "date-fns";
import { formatCurrency, asHKCurrency} from "../helpers";
import {utcToZonedTime} from "date-fns-tz";
import FormHelperText from "@material-ui/core/FormHelperText";
import {PrintOutlined} from "@material-ui/icons";
import {connect} from "react-redux";
import downloadInvoice from "./downloadInvoice";

const paymentHistory = ({paymentHistory = [], busy= false, showAdminFeatures = false, selectedTeam, isTeamMemberOpen= {}, overrideTeam}) => {

    const team = overrideTeam || selectedTeam
    function CustomFooterBalanceComponent(props) {
        return (
            <Typography variant='h6' style={{ 
                borderTop: "1px solid rgba(0, 0, 0, 0.42)", 
                fontWeight: "bold",
                textAlign: "end", 
                margin: "0",
                paddingRight: "10px",
                minHeight: "52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
                gap: "4px",
            }}>Total Due: {asHKCurrency(props.totalBalance < 0 ? Math.abs(props.totalBalance) : props.totalBalance)}</Typography>
        );
    }


    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                {showAdminFeatures &&
                <GridToolbarExport
                      className="toolbar-button"
                      printOptions={{ disableToolbarButton: true }}
                      csvOptions={{
                          fileName: `Transaction History - ${team.school} - ${format(new Date(),'yyyy-MM-dd')}`,
                          utf8WithBom: true,
                      }}                  
                />
                }
                <Button color="primary"
                        size="small"
                        startIcon={<PrintOutlined />}
                        aria-haspopup="menu"
                        onClick={() => downloadInvoice(team,paymentHistory,isTeamMemberOpen)}
                >Print Invoice</Button>
            </GridToolbarContainer>
        );
      }

    const columns = [
        {
            field: 'createdAt',
            headerName: 'Date/Time HKT',
            sortable: false,
            width: 195,            
            valueGetter: params => format(utcToZonedTime(params.row.createdAt,'Asia/Hong_Kong'), 'yyyy-MM-dd HH:mm'),
            renderCell: params => format(utcToZonedTime(params.row.createdAt,'Asia/Hong_Kong'), 'dd MMMM yyyy HH:mm')
        },
        {
            field: 'description',
            headerName: 'Description',
            sortable: false,
            flex: 1,
            renderCell: params => <div style={{display:"flex", flexDirection:"column"}}>
                <div>{params.row.description}</div>
                { showAdminFeatures &&
                    <FormHelperText style={{marginTop:"-4px"}}>{params.row.createdBy}</FormHelperText>
                }
            </div>
        },
        {
            field: 'amountOwed',
            headerName: 'Amount Owed',
            sortable: false,
            width: 130,
            align:'right',
            renderCell: params => asHKCurrency(params.row.amount < 0 ? Math.abs(params.row.amount) : 0 ),
            valueGetter: params => params.row.amount < 0 ? Math.abs(params.row.amount) : 0
        },
        {
            field: 'amountReceived',
            headerName: 'Amount Received',
            sortable: false,
            width: 130,
            align:'right',
            renderCell: params => asHKCurrency(params.row.amount < 0 ? 0 : Math.abs(params.row.amount) ),
            valueGetter: params => params.row.amount < 0 ? 0 :  Math.abs(params.row.amount)
        },
        {
            field: 'balance',
            headerName: 'Balance',
            sortable: false,
            width: 130,
            align:'right',
            renderCell: params => asHKCurrency(params.row.balance < 0 ? Math.abs(params.row.balance) : params.row.balance ),
            valueGetter: params => params.row.balance < 0 ? Math.abs(params.row.balance) : params.row.balance
        }
    ]

    const totalBalance = getOr(0,'balance',last(paymentHistory))
    return (
        <>
            <Typography variant='h6' component='h2' style={{marginTop: "2rem"}}>Transaction History</Typography>
            <Grid item xs={12}>
                <div id="paymentsTable" style={{marginTop: '1rem', width: '100%'}}>
                    <DataGridPro
                        initialState={{
                            sorting: {
                            sortModel: [{ field: 'createdAt', sort: 'asc' }],
                            },
                        }}
                        rows={paymentHistory}
                        getRowHeight={() => 'auto'}
                        getEstimatedRowHeight={() => 200} 
                        loading={busy}
                        columns={columns}
                        disableSelectionOnClick
                        autoHeight={true}
                        components={{
                            Toolbar: CustomToolbar,
                            Footer: CustomFooterBalanceComponent,
                        }}
                        componentsProps={{
                            footer: { totalBalance }                            
                        }}
                        sx={{
                            '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
                            '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
                            '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
                          }}                       
                    />
                </div>
            </Grid>
        </>
    )
}

export default connect((state, ownProps) => ({
    selectedTeam: state.team.selectedTeam,
    overrideTeam: ownProps.overrideTeam,
    paymentHistory: ownProps.paymentHistory,
    busy: ownProps.busy,
    showAdminFeatures: ownProps.showAdminFeatures,
    isTeamMemberOpen: ownProps.isTeamMemberOpen
}))(paymentHistory)
