import Select from "./common/Select";
import React, {useEffect, useState} from "react";
import {buildArbitratorOptionList} from "../utils/arbitratorUtils";

export const ArbitratorConflictDropDown = ({name, onChange,value,label,arbitrators,pairing}) => {

    const [options, setOptions] = useState([])

    useEffect(() => {
        setOptions(buildArbitratorOptionList(pairing.claimant,pairing.respondent,arbitrators))
    }, [arbitrators,pairing]);

    return (
        <Select name={name}
                onChange={e => onChange(e)}
                value={value} label={label}
                options={options}/>
    )
}