import React from 'react'
import { connect } from 'react-redux'
import './styles/App.css'

import Header from './components/common/Header'

const App = ({ children = [] }) => {
  return (
      <div className='app'>
        <header>
          <Header />
        </header>
          <main className='app__body'>{children}</main>
      </div>
  )
}

export default connect((state, ownProps) => ({
  children: ownProps.children,
  loading: state.loading
}))(App)
