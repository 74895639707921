import React from 'react'
import {CircularProgress } from '@material-ui/core'
import {DataGridPro, LicenseInfo } from '@mui/x-data-grid-pro';
import {get, noop} from 'lodash/fp';
import {connect} from "react-redux";
import {getSelectedTeam, getSelectedTeamsPairings} from "../../reducers/teamSelectors";
import PropTypes from "prop-types";
import Container from "@material-ui/core/Container";
import {ACCEPTED} from "../../consts/status";
import Unauthorized from "../Unauthorized";
import Typography from "@material-ui/core/Typography";
//import {dateFormater, timeFormater} from "../../utils/dateUtils";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {friendlyRoundName, CustomTooltip} from "../helpers";
import {getCurrentMoot} from '../../reducers/mootSelectors'
import TeamName from "./TeamName";
LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

const OralArguments = ({
                           team = {},
                           moot,
                           selectedTeamsPairings = [],
                           getSelectedTeam = noop,
                           getCurrentMoot = noop,
                           getSelectedTeamsPairings = noop
                       }) => {

    const [isLoading, setIsLoading] = React.useState(false)
    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                await getCurrentMoot()
                await getSelectedTeam()
                await getSelectedTeamsPairings()
                setIsLoading(false)                
            }catch(e){
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    const columns = [
        {
            field: 'roundType',
            headerName: 'Round',
            flex: 1,
            sortable: false,
            valueGetter: params => friendlyRoundName(get('pairing.roundType',params.row))
        },
        {
            field: 'Date',
            headerName: 'Date',
            align: 'right',
            width: 130,
            sortable: false,
            //renderCell: params => dateFormater(params.row.pairing.pairingTime)
            renderCell: params => format(utcToZonedTime(params.row.pairing.pairingTime,'Asia/Hong_Kong'),'iii, dd MMMM'),
            valueGetter: params => format(utcToZonedTime(params.row.pairing.pairingTime,'Asia/Hong_Kong'),'yyyy/MM/dd HH:mm'),
            
        },
        {
            field: 'time',
            headerName: 'Time',
            align: 'right',
            width: 100,
            sortable: false,
            //renderCell: params => timeFormater(params.row.pairing.pairingTime)
            valueGetter: params => format(utcToZonedTime(params.row.pairing.pairingTime,'Asia/Hong_Kong'),'h:mm aaa')
        },
        {
            field: 'claimant',
            headerName: 'Claimant',
            flex: 1,
            sortable: false,
            renderCell: params => (<a href={`${params.row.claimantDocUrl}`}><TeamName team={params.row.claimant} /></a>)
        },
        {
            field: 'respondent',
            headerName: 'Respondent',
            flex: 1,
            sortable: false,
            renderCell: params => (<a href={`${params.row.respondentDocUrl}`}><TeamName team={params.row.respondent}/></a>)
        },
        {
            field: 'room',
            headerName: 'Room',
            flex: 1,
            sortable: false,
            renderCell: params => {
                if (!get('row.pairing.room',params)){
                    return <CustomTooltip content='available soon' />
                } else {
                    return <CustomTooltip content={params.row.pairing.room} />
                }
            }
        },
        /*
        {
            field: 'hearingLink',
            headerName: 'Virtual Hearing Link',
            flex: 1,
            sortable: false,
            renderCell: params => {
                if (!get('row.pairing.hearingLink',params)){
                    return (<span>coming soon</span>)
                }else{
                    return (<a target="_blank" rel="noreferrer" href={`${get('row.pairing.hearingLink',params)}`}>Click Here</a>)
                }
            }
        },
        */
                          
        {
            field: 'chairArbitrator',
            headerName: 'Arbitrator',
            flex: 1,
            sortable: false,
            valueGetter: params => {
                 if(moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === true) {
                    return '—'
                 } else {
                    return `${get('pairing.chairArbitrator.firstName',params.row)?? ''} ${get('pairing.chairArbitrator.lastName',params.row)?? ''}`
                 }
                }
            },
        {
            field: 'secondArbitrator',
            headerName: 'Arbitrator',
            flex: 1,
            sortable: false,
            valueGetter: params => {
                if(moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === true) {
                   return '—'
                } else {
                   return `${get('pairing.secondArbitrator.firstName',params.row)?? ''} ${get('pairing.secondArbitrator.lastName',params.row)?? ''}`
                }
            }
        },
        {
            field: 'thirdArbitrator',
            headerName: 'Arbitrator',
            flex: 1,
            sortable: false,
            valueGetter: params => {
                if(moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === true) {
                   return '—'
                } else {
                   return `${get('pairing.thirdArbitrator.firstName',params.row)?? ''} ${get('pairing.thirdArbitrator.lastName',params.row)?? ''}`
                }
            }
        },
              
        ]

    const isAllowed = get('status',team) === ACCEPTED
    const isPending = get('status',team) === null


    return (
            <Container maxWidth='xl' style={{paddingBottom: '10rem'}}>                
                { isLoading &&
                <CircularProgress />
                }
                {!isLoading && !isAllowed && !isPending &&
                <Unauthorized />
                }
                { !isLoading && isPending && <div style={{ marginLeft: '4rem' }}>
                <Typography variant='h5' component='h1'>This section it not yet available</Typography>
                </div>}
                {!isLoading && isAllowed && moot.showOralDetailsTeamOpenDate.notYetOpen === true &&
                       <>
                       <Typography variant='h4' component='h1'>Oral Arguments</Typography> 
                       <Typography style={{marginTop: '1rem'}}>The schedule is still tentative and being finalized. The Vis East Administration will send an email when the schedules are finalized.</Typography>
                       </>
                }
                {!isLoading && isAllowed && moot.showOralDetailsTeamOpenDate.notYetOpen === false &&
                <>
                <Typography variant='h4' component='h1'>Oral Arguments</Typography>
                <Typography>
                    Please see below your schedule for the General Rounds. The schedule may change due to various factors. You will be notified if changes occur.
                    </Typography>
                    <Typography><strong><u>ALL</u></strong> oral argument swill take place <strong>in person</strong> at the West Wing, Justice Place, 11 Ice House Street (on the corner of Ice House Street and Queens Road Central), in Central on Hong Kong Island. The Vis East Centre is located on the second floor (2/F). Room numbers will be available on your schedule once they have been finalized. If no room number appears, you will receive such information when you check-in with the Vis East Centre. Teams are requested to arrive <strong><u>no earlier</u></strong> than <strong><u>15 minutes</u></strong> in advance of their oral argument and <strong><u>no later</u></strong> than <strong><u>10 minutes</u></strong> before their scheduled oral argument to check in with the Vis East Administration.</Typography>              
                    <Typography>
                    As a kind reminder, pursuant to the Vis East Rules, no team shall practice with, plead against, or watch a team that it is scheduled to meet in the general rounds. Teams are also prohibited from the viewing of arguments in practice rounds (including pre-moots) of teams that they are scheduled to plead against in the general rounds. Any violation of the Vis East Rules will <strong>immediately disqualify</strong> a team from participation in the elimination rounds. Please make sure that you are familiar with the Vis East Rules. For any questions or concerns, please email the Vis East Administration at: <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.
                    </Typography>                   
                    <div style={{height: 700, marginTop: '2rem', width: '100%'}}>
                        <DataGridPro
                            rows={selectedTeamsPairings || []}
                            autoHeight
                            //Use the line below instead, if you just want the first two pairings
                            //rows={take(2,selectedTeamsPairings) || []}
                            columns={columns}
                            disableSelectionOnClick
                        />
                    </div>                    
                </>
                }
            </Container>
    )
}



OralArguments.propTypes = {
    teamMembers: PropTypes.arrayOf(PropTypes.object),
    getSelectedTeam: PropTypes.func,
    getSelectedTeamsPairings: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        team: state.team.selectedTeam,
        moot: state.moot.currentMoot,
        selectedTeamsPairings: state.team.selectedTeamsPairings
    }),{
        getSelectedTeam: getSelectedTeam,
        getSelectedTeamsPairings: getSelectedTeamsPairings,
        getCurrentMoot: getCurrentMoot
    })( OralArguments)