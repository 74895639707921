export const USER_SIGNUP_REQUESTED = 'USER_SIGNUP_REQUESTED';
export const USER_SIGNUP_SUCCEEDED = 'USER_SIGNUP_SUCCEEDED';
export const USER_SIGNUP_FAILED = 'USER_SIGNUP_FAILED';

export const SAVE_TEAM_REGISTRATION_DATA_REQUESTED = 'SAVE_TEAM_REGISTRATION_DATA_REQUESTED'
export const SAVE_TEAM_REGISTRATION_DATA_SUCCEEDED = 'SAVE_TEAM_REGISTRATION_DATA_SUCCEEDED'
export const SAVE_TEAM_REGISTRATION_DATA_FAILED = 'SAVE_TEAM_REGISTRATION_DATA_FAILED'

export const SAVE_ARBITRATOR_REGISTRATION_DATA_REQUESTED = 'SAVE_ARBITRATOR_REGISTRATION_DATA_REQUESTED'
export const SAVE_ARBITRATOR_REGISTRATION_DATA_SUCCEEDED = 'SAVE_ARBITRATOR_REGISTRATION_DATA_SUCCEEDED'
export const SAVE_ARBITRATOR_REGISTRATION_DATA_FAILED = 'SAVE_ARBITRATOR_REGISTRATION_DATA_FAILED'