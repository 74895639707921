
import React, {cloneElement} from 'react'
import {Menu, IconButton} from '@mui/material';
import {MoreVert} from "@mui/icons-material";
import _ from "lodash/fp";

export default function ActionMenu ({ children }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={handleMenuOpen}>
                <MoreVert />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                {
                    _.map(child => {
                        return cloneElement(child,{ onClick: () => {
                            if (child?.props?.onClick) {
                                child.props.onClick();
                                handleMenuClose()
                            }
                        }})
                    },children)
                }
            </Menu>
        </>
    );
};