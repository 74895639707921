import React from 'react'
import {DataGridPro, GridToolbarContainer, LicenseInfo, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {get, isEmpty, includes, filter} from "lodash/fp";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {Typography, Tooltip} from "@material-ui/core";
import {Edit,Gavel} from "@mui/icons-material";
import { useHistory } from 'react-router'
import {ADMIN} from "../../../../consts/roles";
import {getArbitratorConflictGridCell, hasConflict} from "../../../../utils/arbitratorUtils";
import TeamName from 'components/team/TeamName';
import {CustomTooltip} from "../../../helpers";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const ScheduleTable = ({ selectedRoundSchedule = [], user = {}, moot, dateFilter}) => {
    const history = useHistory()

    const onEdit = pairingId => {
        history.push(`/admin/oral-arguments/edit/${pairingId}`)
    }
    const onInputScores = pairingId => {
        history.push(`/admin/oral-arguments/score/${pairingId}`)
    }

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer style={{gap:'20px'}}>
            <GridToolbarColumnsButton className="toolbar-button" />
            <GridToolbarFilterButton className="toolbar-button" />
            <GridToolbarDensitySelector className="toolbar-button" />
            <GridToolbarExport
            className="toolbar-button" 
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
                fileName: `General Round Schedule - ${format(new Date(),'yyyy-MM-dd')}`,
                utf8WithBom: true,
              }}
            />
            <div style={{display:'flex'}}>
                <Typography style={{margin:'-2px 0 0', padding:'2px 0.25rem', textAlign:'left', fontSize:'11px', color:'#000000', backgroundColor:`${moot.showOralDetailsTeamOpenDate.notYetOpen === true ? '#FFFF8A' : '#B9E9C9'}`}}>General Rounds schedule <strong>{moot.showOralDetailsTeamOpenDate.notYetOpen === true ? 'is not' : 'is'} </strong> visible to Teams.
                </Typography>
                {/*
                <Typography style={{margin:'-2px 0 0 4px', padding:'2px 0.25rem', textAlign:'left', fontSize:'11px', color:'#000000', backgroundColor:`${moot.showOralDetailsArbitratorsOpenDate.notYetOpen === true ? '#FFFF8A' : '#B9E9C9'}`}}>General Rounds schedule <strong>{moot.showOralDetailsArbitratorsOpenDate.notYetOpen === true ? 'is not' : 'is'} </strong> visible to Arbitrators.
                </Typography>
                 */}
            </div>
            
          </GridToolbarContainer>
        );
    }

    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            align: 'right',
            flex: 1,
            hide: true
        },
        {
            field: 'claimantId',
            headerName: 'Claimant Id',
            align: 'right',
            flex: 1,
            hide: true
        },
        {
            field: 'cliamant',
            headerName: 'Claimant',
            flex: 1,
            renderCell: params => <TeamName team={params.row.claimant} adminUse={true} />,
            valueGetter: params => (params.row.claimant.hideTeamName === true ? `TEAM ID ${params.row.claimant.id}` : params.row.claimant.school)
        },
        {
            field: 'claimantPractice',
            headerName: 'Claimant Practice?',
            flex: 1,
            hide: true,
            valueGetter: params => params.row.claimantPractice
        },
        {
            field: 'respondentId',
            headerName: 'Respondent Id',
            align: 'right',
            flex: 1,
            hide: true
        },
        {
            field: 'respondent',
            headerName: 'Respondent',
            flex: 1,
            renderCell: params => <TeamName team={params.row.respondent} adminUse={true} />,
            valueGetter: params => (params.row.respondent.hideTeamName === true ? `TEAM ID ${params.row.respondent.id}` : params.row.respondent.school)
        },
        {
            field: 'respondentPractice',
            headerName: 'Respondent Practice?',
            flex: 1,
            hide: true,
            valueGetter: params => params.row.respondentPractice
        },
        {
            field: 'pairingDate',
            headerName: 'Date HKT',
            align: 'right',
            width: 130,
            renderCell: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'iii, dd MMMM'),
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'yyyy/MM/dd'),    
        },
        {
            field: 'pairingTime',
            headerName: 'Time HKT',
            align: 'right',
            width: 100,
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'hh:mm aaa')
        },
        {
            field: 'pairingDateTime',
            headerName: 'Date & Time HKT',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'yyyy/MM/dd HH:mm'),
        },
        {
            field: 'hearingLink',
            headerName: 'Virtual Hearing Link',
            flex: 1,
            hide: true,
            sortable: false,
            renderCell: params => {
                if (!params.row.hearingLink){
                    return (<span>coming soon</span>)
                }else{
                    return (<a target="_blank" rel="noreferrer" href={`${params.row.hearingLink}`}>Click Here</a>)
                }
            }
        },    
        {
            field: 'room',
            headerName: 'Room',
            flex: 1,
            sortable: false,
            renderCell: params => {
                if (!get('row.room',params)){                    
                    return <CustomTooltip content='available at check-in' />
                } else {
                    return <CustomTooltip content={params.row.room} />
                }
            }
        },
        {
            field: 'chairArbitratorId',
            headerName: 'First Arbitrator Id',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => `${params.row.chairArbitrator?.id ?? ''}`
        },
        {
            field: 'chairArbitrator',
            headerName: 'First Arbitrator',
            flex: 1,
            valueGetter: params => (`${params.row.chairArbitrator?.firstName ?? ''} ${params.row.chairArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.chairArbitrator)
        },
        {
            field: 'secondArbitratorId',
            headerName: 'Second Arbitrator Id',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => `${params.row.secondArbitrator?.id ?? ''}`
        },
        {
            field: 'secondArbitrator',
            headerName: 'Second Arbitrator',
            flex: 1,
            valueGetter: params => (`${params.row.secondArbitrator?.firstName ?? ''} ${params.row.secondArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.secondArbitrator)
        },
        {
            field: 'thirdArbitratorId',
            headerName: 'Third Arbitrator Id',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => `${params.row.thirdArbitrator?.id ?? ''}`
        },
        {
            field: 'thirdArbitrator',
            headerName: 'Third Arbitrator',
            flex: 1,
            valueGetter: params => (`${params.row.thirdArbitrator?.firstName ?? ''} ${params.row.thirdArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.thirdArbitrator)
        },
        {
            field: 'firstReserveArbitratorId',
            headerName: 'First Reserve Id',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => `${params.row.firstReserveArbitrator?.id ?? ''}`
        },
        {
            field: 'firstReserveArbitrator',
            headerName: 'First Reserve',
            flex: 1,
            hide: true,
            valueGetter: params => (`${params.row.firstReserveArbitrator?.firstName ?? ''} ${params.row.firstReserveArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.firstReserveArbitrator)
        },
        {
            field: 'secondReserveArbitratorId',
            headerName: 'Second Reserve Id',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => `${params.row.secondReserveArbitrator?.id ?? ''}`
        },
        {
            field: 'secondReserveArbitrator',
            headerName: 'Second Reserve',
            flex: 1,
            hide: true,
            valueGetter: params => (`${params.row.secondReserveArbitrator?.firstName ?? ''} ${params.row.secondReserveArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.secondReserveArbitrator)
        },
        {
            field: 'thirdReserveArbitratorId',
            headerName: 'Third Reserve Id',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => `${params.row.thirdReserveArbitrator?.id ?? ''}`
        },
        {
            field: 'thirdReserveArbitrator',
            headerName: 'Third Reserve',
            flex: 1,
            hide: true,
            valueGetter: params => (`${params.row.thirdReserveArbitrator?.firstName ?? ''} ${params.row.thirdReserveArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.thirdReserveArbitrator)
        },
        {
            field: 'claimantScore',
            headerName: 'Claimant Score',
            align: 'right',
            width: 130,
            valueGetter: params => {
                const score = params.row.PairingScore
                if (isEmpty(score))
                    return 0
                return score.firstClaimantChairScore + score.firstClaimantArb1Score + score.firstClaimantArb2Score +
                    score.secondClaimantChairScore + score.secondClaimantArb1Score + score.secondClaimantArb2Score
            }
        },
        {
            field: 'respondentScore',
            headerName: 'Respondent Score',
            align: 'right',
            width: 130,
            valueGetter: params => {
                const score = params.row.PairingScore
                if (isEmpty(score))
                    return 0
                return score.firstRespondentChairScore + score.firstRespondentArb1Score + score.firstRespondentArb2Score  +
                    score.secondRespondentChairScore + score.secondRespondentArb1Score + score.secondRespondentArb2Score
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            flex: 1,
            getActions: params => [

                 <GridActionsCellItem label="Edit" onClick={() => onEdit(params.row.id)}
                                      disabled={!includes(ADMIN,user.roles)}
                                      icon={
                                          <Tooltip title='Edit'>
                                              <Edit/>
                                          </Tooltip>
                                      }/>,
                 <GridActionsCellItem label="Input Scores"
                                      onClick={() => onInputScores(params.row.id)}
                                      icon={
                                          <Tooltip title='Input Scores'>
                                              <Gavel/>
                                          </Tooltip>
                                      }
                 />
                 ]
        },
    ]

    const getFilteredSchedule = (selectedRoundSchedule,dateFilter) => {
        if (isEmpty(dateFilter) || dateFilter === 'All'){
            return selectedRoundSchedule
        }
        return filter(s => format(utcToZonedTime(s.pairingTime,'Asia/Hong_Kong'), 'EEEE') === dateFilter,selectedRoundSchedule)
    }

    return (
        <div style={{height: 700, width: '100%'}}>
            <DataGridPro
                rows={getFilteredSchedule(selectedRoundSchedule,dateFilter)}
                columns={columns}
                disableSelectionOnClick
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'pairingDateTime', sort: 'asc' }],
                    },
                  }}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    )
}


ScheduleTable.propTypes = {
    selectedRoundSchedule: PropTypes.arrayOf(PropTypes.object),
}

export default connect(
    (state, ownProps) => ({
        user: state.user,
        selectedRoundSchedule: state.admin.selectedRoundSchedule,
        dateFilter: ownProps.dateFilter
    }))( ScheduleTable)
