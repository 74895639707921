import * as actions from '../actions/adminActions'
import {isEmpty, get, findLastIndex, indexOf, size, startCase} from "lodash/fp";
import {TEAM_ROLE_TYPE} from "../utils/constants";

export const getAllArbitrators = (hardRefresh = false) => async (dispatch,getState) => {
    let result = get('admin.arbitrators',getState())
    if (isEmpty(result) || hardRefresh){
        result = await (actions.getAllArbitrators())(dispatch,getState)
    }
    return result
}

export const getAllTeams = (hardRefresh = false) => async (dispatch,getState) => {
    const existing = get('admin.teams',getState())
    if (isEmpty(existing) || hardRefresh){
        return await (actions.getAllTeams())(dispatch,getState)
    }
    return existing
}

export const getMemoAssignableTeams = () => async (dispatch,getState) => {
    const existing = get(`admin.assignableTeams`,getState())
    if (isEmpty(existing)){
        return await (actions.getMemoAssignableTeams())(dispatch,getState)
    }
    return existing
}

export const getAllClarifications = () => async (dispatch,getState) => {
    const existing = get('admin.clarifications',getState())
    if (isEmpty(existing)){
        await (actions.getAllClarifications())(dispatch,getState)
    }
}

export const getAllMemoRankings = (role) => async (dispatch,getState) => {
    let rankings = []
    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        rankings = get('admin.claimantRankings',getState())
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        rankings = get('admin.respondentRanking',getState())
    }
    if (isEmpty(rankings)){
        await (actions.getAllMemoRankings(role))(dispatch,getState)
    }
}


export const getAllMemoAssignments = (role,hardRefresh = false) => async (dispatch,getState) => {
    let assignments = []
    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        assignments = get('admin.claimantAssignments',getState())
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        assignments = get('admin.respondentAssignments',getState())
    }
    if (hardRefresh || isEmpty(assignments)){
        assignments = await (actions.getAllMemoAssignments(role))(dispatch,getState)
    }
    return assignments
}

export const getAllJuryMemoRankings = (role) => async (dispatch,getState) => {
    let rankings = []
    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        rankings = get('admin.juryClaimantRankings',getState())
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        rankings = get('admin.juryRespondentRankings',getState())
    }
    if (isEmpty(rankings)){
        await (actions.getAllJuryMemoRankings(role))(dispatch,getState)
    }
}

export const getAllJuryMemoAssignments = role => async (dispatch,getState) => {
    let rankings = []
    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        rankings = get('admin.juryClaimantAssignments',getState())
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        rankings = get('admin.juryRespondentAssignments',getState())
    }
    if (isEmpty(rankings)){
        await (actions.getAllJuryMemoAssignments(role))(dispatch,getState)
    }
}


export const getOralArgumentSchedule = roundType => async (dispatch, getState) => {
    const existing = get('admin.oralArgumentSchedule',getState())
    if (isEmpty(existing)){
        await (actions.getOralArgumentSchedule(roundType))(dispatch,getState)
    }
}


export const getSelectedPairing = id => async (dispatch, getState) => {
    const existing = get('admin.selectedPairing',getState())
    if (isEmpty(existing) || existing.id !== Number.parseInt(id)){
        return await actions.selectPairing(id)(dispatch,getState)
    }
    return existing
}

export const getSelectedRoundSchedule = roundType => async (dispatch, getState) => {
    const schedule = get('admin.selectedRoundSchedule',getState())
    let currentRoundType = get('admin.selectedRoundType',getState())
    if (isEmpty(schedule) || currentRoundType !== roundType){
        return await (actions.selectRound(roundType))(dispatch,getState)
    }
    return schedule
}

export const getNextRoundTypeToSelect = currentRoundType => (dispatch,getState) => {
    const allRounds = get('admin.roundTypes',getState())
    const currentRoundIndex = indexOf(currentRoundType,allRounds)
    const nextIndex = currentRoundIndex + 1
    return size(allRounds) - 1 < nextIndex ? 'Champion' : allRounds[currentRoundIndex + 1];
}

export const getNextRoundTeamSizeMax = currentRoundType => (dispatch,getState) => {
    const allRounds = get('admin.rounds',getState())
    const currentRoundIndex = findLastIndex(a => a.roundType === currentRoundType,allRounds)
    const nextIndex = currentRoundIndex + 1
    const nextRound = size(allRounds) < nextIndex ? allRounds[currentRoundIndex] : allRounds[currentRoundIndex + 1];
    return nextRound?.teamSizeMax ?? 0
}

export const getRounds = () => async (dispatch,getState) => {
    let existing = get('admin.rounds',getState())
    if(isEmpty(existing)){
        await actions.getRounds()(dispatch,getState)
    }
}

export const getAvailableSchools = () => async (dispatch,getState) => {
    const schools = get('admin.availableSchools',getState())
    if (isEmpty(schools)){
        await actions.getAllTeams()(dispatch,getState)
    }
}