import _ from 'lodash/fp'

/**
 * Copy text to the browser clipboard using the Clipboard API
 * @param text
 * @return {Promise<void>}
 */
// TODO: make this more robust in case clipboard is not available or user did
//  not grant clipboard permission
export const writeToClipboard = async text => {
    if (_.isUndefined(navigator.clipboard))
        throw new Error('clipboard API not available')
    return navigator.clipboard.writeText(text)
}
