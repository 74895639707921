import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {noop, map, groupBy, isEmpty} from "lodash/fp";
import {connect} from 'react-redux'
import * as actions from '../../actions/arbitratorActions'
import PropTypes from "prop-types";
import {
    Box,
    CircularProgress,
    FormControl,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup
} from "@material-ui/core";
import {getSelectedArbitrator, getArbitrationDateTimes} from "../../reducers/arbitratorSelectors";
import Grid from "@material-ui/core/Grid";
import { parseISO } from "date-fns";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";
import {utcToZonedTime, format } from "date-fns-tz";
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import './ArbitratorAvailability.css';
import {defaultArbitrator} from "../registration/registrationUtils";
import {getAllSchools, getAllJurisdictions} from "../../reducers/globalSelectors";
import {getCurrentMoot} from "../../reducers/mootSelectors";
import ordinal from "ordinal-number-suffix";

const Availability = ({ schedule = [],
                          getSelectedArbitrator = noop,
                          existingArbitratorData,
                          arbitrationDateTimes = [],
                          getArbitrationDateTimes = noop,
                          addAvailability = noop,
                          removeAvailability = noop,
                          onArbitratorDataChange = noop,
                          schools=[],
                          jurisdictions = [],
                          getAllSchools = noop,
                          getAllJurisdictions = noop,
                          onWrittenAvailabilityError = noop,
                          onOralAvailabilityError = noop,
                          onIsLoading = noop,
                          moot,
                          getCurrentMoot = noop

}) => {

    const [isLoading, setIsLoading] = useState(true);
    const defaultArbitratorData = !isEmpty(existingArbitratorData) ? existingArbitratorData: defaultArbitrator
    const [arbitratorData, setArbitratorData] = useState(defaultArbitratorData)

    const onScheduleChanged = (e) => {
        e.target.checked ? addAvailability(e.target.value) :
            removeAvailability(e.target.value)
    }

    const localTimeZone = new Date()
        .toLocaleTimeString('en-us', {timeZoneName: 'short'})
        .split(' ')[2]

    useEffect(() => {
        async function getData() {
            setIsLoading(true)
            onIsLoading(true)
            const result = await getSelectedArbitrator()
            await getArbitrationDateTimes()
            await getAllSchools()
            await getAllJurisdictions()
            if (!isEmpty(result)) {
                setArbitratorData(result)
                onArbitratorDataChange(result)
            }
            await getCurrentMoot()
            setIsLoading(false)
            onIsLoading(false)
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const writtenAvailabilityError = (participation) => {
        let result = false
        if (arbitratorData.availableForClaimantEvaluation === false &&
            arbitratorData.availableForRespondentEvaluation === false &&
            arbitratorData.availableForRankingClaimant === false &&
            arbitratorData.availableForRankingRespondent === false &&
            participation !== 'I am available to ONLY act as an arbitrator during oral arguments') {
            result = true
        }
        onWrittenAvailabilityError(result)
        return result
    }

    const oralAvailabilityError = (participation) => {
        let result = false
        if(isEmpty(schedule) &&
            participation !== 'I am available ONLY to rank written memoranda' &&
            participation !== 'I am no longer able to act as an arbitrator' &&
            participation !== null &&
            !isLoading
        ) {
            result = true
        }
        onOralAvailabilityError(result)
        return result
    }

    return (
        <Container style={{paddingBottom: '1rem', padding:'0'}}>
            {isLoading &&
            <CircularProgress/>
            }
            {!isLoading &&
                 <Grid item xs={12}
                      style={{background: "#F0F0F0", padding: "1.75rem", border: "1px solid #D8D8D8"}}>
                    <Typography variant="h6">Availability & Conflicts</Typography>
                    <Grid item xs={12} style={{margin: "1rem 0"}}>
                        <FormControl component='fieldset'>
                            <RadioGroup name="availabilityConflicts">
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            checked={
                                                arbitratorData.participation ===
                                                'I am available ONLY to rank written memoranda'
                                            }
                                            onChange={e => {
                                                setArbitratorData({
                                                    ...arbitratorData,
                                                    participation: e.target.value
                                                })
                                                onArbitratorDataChange({participation: e.target.value})
                                                oralAvailabilityError(e.target.value)
                                                writtenAvailabilityError(e.target.value)
                                            }}
                                            value='I am available ONLY to rank written memoranda'
                                            required
                                        />
                                    }
                                    label='I am available ONLY to rank written memoranda'
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            checked={
                                                arbitratorData.participation ===
                                                'I am available to ONLY act as an arbitrator during oral arguments'
                                            }
                                            onChange={e => {
                                                setArbitratorData({
                                                    ...arbitratorData,
                                                    participation: e.target.value
                                                })
                                                onArbitratorDataChange({participation: e.target.value})
                                                oralAvailabilityError(e.target.value)
                                                writtenAvailabilityError(e.target.value)
                                            }}
                                            value='I am available to ONLY act as an arbitrator during oral arguments'
                                            required
                                        />
                                    }
                                    label='I am available to ONLY act as an arbitrator during oral arguments'
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            checked={
                                                arbitratorData.participation ===
                                                'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                                            }
                                            onChange={e => {
                                                setArbitratorData({
                                                    ...arbitratorData,
                                                    participation: e.target.value
                                                })
                                                onArbitratorDataChange({participation: e.target.value})
                                                oralAvailabilityError(e.target.value)
                                                writtenAvailabilityError(e.target.value)
                                            }}
                                            value='I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                                            required
                                        />
                                    }
                                    label='I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                                />
                                <FormControlLabel
                                    control={
                                        <Radio
                                            color='primary'
                                            checked={
                                                arbitratorData.participation ===
                                                'I am no longer able to act as an arbitrator'
                                            }
                                            onChange={e => {
                                                setArbitratorData({
                                                    ...arbitratorData,
                                                    participation: e.target.value
                                                })
                                                onArbitratorDataChange({participation: e.target.value})
                                                    oralAvailabilityError(e.target.value)
                                                    writtenAvailabilityError(e.target.value)
                                            }}
                                            value='I am no longer able to act as an arbitrator'
                                            required
                                        />
                                    }
                                    label='I am no longer able to act as an arbitrator'
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    {arbitratorData.participation !== 'I am no longer able to act as an arbitrator' &&
                        <>
                        {(arbitratorData.participation === null || arbitratorData.participation !== 'I am available to ONLY act as an arbitrator during oral arguments') &&
                            <Grid item xs={12} style={{marginTop: "1rem"}}>
                                <Typography component="h2" variant="h6" style={{
                                    textAlign: 'center',
                                    fontWeight: 'bold',
                                    margin: '3rem 0 2rem',
                                }}>AVAILABILITY FOR EVALUATING WRITTEN
                                    MEMORANDA</Typography>
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                    I am available to participate in the evaluation of memoranda for claimant. Claimant’s memoranda will be sent to arbitrators for review around 16 December 2024. We require arbitrators to return their rankings and comments to the Vis East Administration <strong><u>no later than 19 January 2025</u></strong>.
                                    </FormLabel>
                                    <RadioGroup
                                        value={arbitratorData.availableForClaimantEvaluation !== null && arbitratorData.availableForClaimantEvaluation.toString()}
                                        onChange={e => {
                                            setArbitratorData({
                                                ...arbitratorData,
                                                availableForClaimantEvaluation: e.target.value === 'true'
                                            })
                                            onArbitratorDataChange({availableForClaimantEvaluation: e.target.value === 'true'})
                                        }}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                    I am available to participate in the evaluation of memoranda for respondent. Respondent’s memoranda will be sent to arbitrators for review by 3 February 2025. We require arbitrators to return their rankings and comments to the Vis East Administration <strong><u>no later than 2 March 2025</u></strong>.
                                    </FormLabel>
                                    <RadioGroup
                                        value={arbitratorData.availableForRespondentEvaluation !== null && arbitratorData.availableForRespondentEvaluation.toString()}
                                        onChange={e => {
                                            setArbitratorData({
                                                ...arbitratorData,
                                                availableForRespondentEvaluation: e.target.value === 'true'
                                            })
                                            onArbitratorDataChange({availableForRespondentEvaluation: e.target.value === 'true'})
                                        }}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                    I am available to participate in the final ranking of the memoranda for claimant. Arbitrators will receive the top ranked claimant’s memoranda from the general round of ranking <strong><u>around 29 January 2025</u></strong>. Arbitrators will be required to submit their final rankings (no comments are necessary) <strong><u>no later than 23 February 2025</u></strong>. Should your availability to review the final claimant’s memoranda change, please inform us <strong><u>no later than 28 January 2025</u></strong>.
                                    </FormLabel>
                                    <RadioGroup
                                        value={arbitratorData.availableForRankingClaimant !== null && arbitratorData.availableForRankingClaimant.toString()}
                                        onChange={e => {
                                            setArbitratorData({
                                                ...arbitratorData,
                                                availableForRankingClaimant: e.target.value === 'true'
                                            })
                                            onArbitratorDataChange({availableForRankingClaimant: e.target.value === 'true'})
                                        }}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component='fieldset' style={{marginBottom:'1.5rem'}}>
                                    <FormLabel component='legend' required>
                                    I am available to participate in the final ranking of the memoranda for respondent. Arbitrators will receive the top ranked respondent’s memoranda from the general round of ranking <strong><u>around 12 March 2025</u></strong>. Arbitrators will be required to submit their final rankings (no comments are necessary) <strong><u>no later than 26 March 2025</u></strong>. Should your availability to review the final respondent’s memoranda change, please inform us <strong><u>no later than 11 March 2025</u></strong>.
                                    </FormLabel>
                                    <RadioGroup
                                        value={arbitratorData.availableForRankingRespondent !== null && arbitratorData.availableForRankingRespondent.toString()}
                                        onChange={e => {
                                            setArbitratorData({
                                                ...arbitratorData,
                                                availableForRankingRespondent: e.target.value === 'true'
                                            })
                                            onArbitratorDataChange({availableForRankingRespondent: e.target.value === 'true'})
                                        }}
                                        row>
                                        <FormControlLabel
                                            value="true"
                                            control={<Radio color='primary' required/>}
                                            label='Yes'
                                        />
                                        <FormControlLabel
                                            value="false"
                                            control={<Radio color='primary' required/>}
                                            label='No'
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {writtenAvailabilityError() &&
                                    <Typography variant='body1' style={{color:'red'}}>You selected "No" to all written memo options even though you indicated that you are available to rank written memoranda above.</Typography>
                                }
                            </Grid>
                        }
                        <Grid item xs={12}>
                            {(arbitratorData.participation === null || arbitratorData.participation !== 'I am available ONLY to rank written memoranda') &&
                                <>
                            <Box>
                                <Typography
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        margin: '3rem 0 2rem',
                                    }}
                                    variant='h6'
                                >
                                    SCHEDULE OF AVAILABILITY FOR ORAL ARGUMENTS
                                </Typography>
                                <Typography variant='body1' paragraph style={{marginBottom: '2rem'}}>
                                    Please <strong>TICK THE BOXES</strong> for the times that <strong>you are available</strong> to act as an arbitrator for the oral arguments. <strong>ALL TIMES</strong> reflect Hong Kong Time (GMT+8). Should any changes occur with your availability, please ensure that you update your Arbitrator Account <strong>immediately</strong>.
                                </Typography>
                            </Box>
                            <Grid container item spacing={3} sx={12}>
                                <Grid item xs={12} md={6} style={{margin: "1rem 0 2rem"}}>
                                    <MUITextField
                                        style={{width: '100%', maxWidth: '220px'}}
                                        className='number-field'
                                        onChange={(e) => {
                                            if (e.target.valueAsNumber < 1 || e.target.valueAsNumber > arbitrationDateTimes.length)
                                                return

                                            setArbitratorData({
                                                ...arbitratorData,
                                                roundsYouCanArbitrate: e.target.valueAsNumber
                                            })
                                            onArbitratorDataChange({roundsYouCanArbitrate: e.target.valueAsNumber})
                                        }}
                                        value={arbitratorData.roundsYouCanArbitrate || 4}
                                        type='number'
                                        label="Number of rounds you can arbitrate"
                                        required
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                    />

                                </Grid>
                            </Grid>
                            <Container style={{padding: 0}}>
                                {!isLoading &&
                                    map(roundGroup =>
                                            (<div style={{marginBottom: '2rem'}} key={roundGroup[0].roundType}>
                                                <Typography variant="h6">
                                                    {roundGroup[0].legibleRoundType}
                                                </Typography>
                                                {map(dayGroup => {
                                                    return (
                                                        <Box
                                                            key={dayGroup[0].hk}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                margin: '0.5rem 0',
                                                                backgroundColor: '#F8F8F8'
                                                            }}
                                                            className='schedule-box'
                                                        >
                                                            <Typography style={{margin: '0'}} className='schedule-date'>
                                                                {format(parseISO(dayGroup[0].hk), 'd MMMM, EEEE')}
                                                            </Typography>
                                                            <FormGroup row className='schedule-form-group'>
                                                                {map(day =>
                                                                        <FormControlLabel
                                                                            key={day.utc}
                                                                            className='schedule-form-control-label'
                                                                            control={
                                                                                <div style={{position: 'relative'}}>
                                                                                    <Checkbox
                                                                                        checked={schedule.includes(day.utc)}
                                                                                        onChange={onScheduleChanged}
                                                                                        value={day.utc}
                                                                                        color='primary'
                                                                                    />
                                                                                    <FormHelperText
                                                                                        style={{
                                                                                            whiteSpace: 'nowrap',
                                                                                            position: 'absolute',
                                                                                            bottom: '-7px',
                                                                                            left: '11px',
                                                                                            color: '#dd1f26',
                                                                                            display: 'none'
                                                                                        }}

                                                                                    >

                                                                                        {day.endDate ? `${format(parseISO(day.utc), 'eee. h:mm aa')} ${localTimeZone} - ${format(parseISO(day.endUtc), 'eee. h:mm aa')} ${localTimeZone} *` : `${format(parseISO(day.utc), 'eee. h:mm aa')} ${localTimeZone}`}
                                                                                    </FormHelperText>
                                                                                </div>
                                                                            }
                                                                            label={day.endDate ? `${format(utcToZonedTime(day.utc, 'Asia/Hong_Kong'), 'h:mm aa')} - ${format(utcToZonedTime(day.endUtc, 'Asia/Hong_Kong'), 'h:mm aa')} *` : format(utcToZonedTime(day.utc, 'Asia/Hong_Kong'), 'h:mm aa')}
                                                                        />
                                                                    , dayGroup)}
                                                            </FormGroup>
                                                        </Box>)
                                                }, groupBy('sortKey',roundGroup))
                                                }
                                            </div>)
                                        , groupBy('roundType', arbitrationDateTimes))
                                }
                            </Container>
                                </>
                            }
                            <Grid item xs={12} md={12} style={{margin: '0 0 2rem 0'}}>
                                <Box>
                                    <Typography style={{margin: '0'}}>
                                        I should not arbitrate these teams due to team affiliations
                                        (professor, lecturer, coach, alumni, etc.)
                                    </Typography>
                                </Box>
                                <Grid item xs={10}>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            setArbitratorData({
                                                ...arbitratorData,
                                                affiliatedSchools: isEmpty(newValue) ? null : newValue
                                            })
                                            onArbitratorDataChange({affiliatedSchools: isEmpty(newValue) ? null : newValue})
                                        }}
                                        value={arbitratorData.affiliatedSchools || []}
                                        options={schools}
                                        freeSolo
                                        autoSelect
                                        multiple
                                        renderInput={(params) => <MUITextField {...params}
                                                                               inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                               variant="standard"
                                                                               label="School"/>}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={12} style={{margin: '0 0 2rem 0'}}>
                                <Box>
                                    <Typography style={{margin: '0'}}>
                                        I should not arbitrate teams from these jurisdictions due to international conflicts with my jurisdiction
                                    </Typography>
                                </Box>
                                <Grid item xs={10}>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            setArbitratorData({
                                                ...arbitratorData,
                                                conflictingJurisdictions: isEmpty(newValue) ? null : newValue
                                            })
                                            onArbitratorDataChange({conflictingJurisdictions: isEmpty(newValue) ? null : newValue})
                                        }}
                                        value={arbitratorData.conflictingJurisdictions || []}
                                        options={jurisdictions}
                                        freeSolo
                                        autoSelect
                                        multiple
                                        renderInput={(params) => <MUITextField {...params}
                                                                               inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                               variant="standard"
                                                                               label="Jurisdictions"/>}
                                    />
                                </Grid>
                            </Grid>
                            {oralAvailabilityError(arbitratorData.participation) &&
                                <Typography variant='body1' style={{color: 'red'}}>You have indicated that you are available
                                    as an arbitrator during oral arguments but have not selected your availability
                                    times.</Typography>
                            }
                        </Grid>
                        </>
                    }
                </Grid>
            }
</Container>
    )
}


Availability.propTypes = {
    arbitrator: PropTypes.object,
    getArbitratorAvailability: PropTypes.func,
    arbitratorAvailabilitySaveError: PropTypes.string,
    getArbitrationDateTimes: PropTypes.func,
    arbitrationDateTimes: PropTypes.array,
    addAvailability: PropTypes.func,
    removeAvailability: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        arbitrator: state.arbitrator.selectedArbitrator,
        schools: state.global.schoolNames,
        jurisdictions: state.global.jurisdictionNames,
        existingArbitratorData: state.registration.arbitratorData,
        arbitrationDateTimes: state.arbitrator.arbitrationDateTimes,
        schedule: state.arbitrator.selectedArbitratorSchedule,
        hideSave: ownProps.hideSave,
        onArbitratorDataChange: ownProps.onArbitratorDataChange,
        onOralAvailabilityError : ownProps.onOralAvailabilityError,
        onWrittenAvailabilityError : ownProps.onWrittenAvailabilityError,
        onIsLoading: ownProps.onIsLoading,
        moot: state.moot.currentMoot
    }),
    {
        getSelectedArbitrator: getSelectedArbitrator,
        getArbitrationDateTimes: getArbitrationDateTimes,
        addAvailability: actions.addAvailability,
        removeAvailability: actions.removeAvailability,
        getAllSchools: getAllSchools,
        getAllJurisdictions: getAllJurisdictions,
        getCurrentMoot: getCurrentMoot
    }
)(Availability)