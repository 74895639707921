import React from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import { useHistory } from 'react-router'
import { Grid,Container, Typography,Button } from '@material-ui/core'

const ExistingAccount = ({signInEmail, router = {}}) => {
    const history = useHistory()
    const returnTo = router.location.query.returnTo
    const teamMember = router.location.query.teamMember

    const goToSignIn = async e => {
        e.preventDefault()
        history.replace(`/login?returnTo=${returnTo}`)
    }

    return (
        <Container style={{ paddingBottom: '10rem' }}>
            <form onSubmit={goToSignIn}>
                <Grid container
                      style={{flexGrow: 1, padding: '20px'}}
                      spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Existing Account</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>The email {signInEmail} is already associated with an existing account. Please click on "SIGN IN" to log into your account, If you have forgotten your password, please click "SIGN IN" then "Forgot Password" to reset your password. <>{ !teamMember && <> To register with another email address, please <Link to="/registration">create a new account</Link></> }</></Typography>
                    </Grid>
                    <Grid item xs={8} md={6}>
                        <Button type="submit" variant='contained' color="primary">
                            SIGN IN
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>)
}


export default connect(
    (state) => ({
        router: state.router,
        signInEmail: state.registration.registrationUser.username
    })
)(ExistingAccount)
