import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl'
import MuiSelect from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  formHelperText: {
    minHeight: '19px',
  },
}))

const Select = ({
  onChange,
  value,
  name = '',
  label,
  options = [],
  style = {},
  helperText,
  required = false,
  disabled = false,
  ...props
}) => {
  const classes = useStyles()

  const handleChange = e => onChange(e, name)

  return (
    <FormControl className={classes.formControl} required={required} disabled={disabled} style={style}>
      <InputLabel>{label}</InputLabel>
      <MuiSelect name={name} value={value || ''} onChange={handleChange} {...props}>
        {options.map((option, index) => {
          if (option.id) {
            return <MenuItem key={option.id + index + Math.random()} value={option.id}>
              <span className={option.className}>{option.name}</span>
            </MenuItem>
          } else {
            return <MenuItem key={option + index + Math.random()} value={option}>
              <span className={option.className}>{option}</span>
            </MenuItem>
          }
        })}
      </MuiSelect>
      <FormHelperText className={classes.formHelperText}>{helperText}</FormHelperText>
    </FormControl>
  )
}
export default Select
