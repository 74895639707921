import Container from '@material-ui/core/Container'
import React, {useEffect, useState} from 'react'
import {Typography, Button, CircularProgress} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import {connect} from "react-redux";
import {
    getAllArbitrators,
    getAllMemoAssignments,
    getAllTeams,
    getMemoAssignableTeams
} from "../../../reducers/adminSelectors";
import {saveArbitratorPairing} from "../../../actions/adminActions"
import {filter, find, head, noop, size} from "lodash/fp";
import Grid from "@material-ui/core/Grid";
import {NavLink, useHistory} from "react-router-dom";
import { textTransform } from '@mui/system';
import {TEAM_ROLE_TYPE} from "../../../utils/constants";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const AddAssignment = ({router,
                           assignableClaimantArbitrators,
                           assignableRespondentArbitrators,
                           memoAssignableTeams,
                           getAllArbitrators = noop,
                           getMemoAssignableTeams = noop,
                           saveArbitratorPairing = noop,
                           getAllMemoAssignments = noop}) => {

    const classes = useStyles()
    const [selectedArbitratorId, setSelectedArbitratorId] = useState()
    const [firstSelectedTeamId, setFirstSelectedTeamId] = useState()
    const [secondSelectedTeamId, setSecondSelectedTeamId] = useState()
    const [thirdSelectedTeamId, setThirdSelectedTeamId] = useState()
    const [fourthSelectedTeamId, setFourthSelectedTeamId] = useState()
    const [fifthSelectedTeamId, setFifthSelectedTeamId] = useState()
    const [sixthSelectedTeamId, setSixthSelectedTeamId] = useState()
    const [isFetching, setIsFetching] = useState(false)
    const history = useHistory()

    const role = router.location.query.role


    const TeamAssignment = ({value, changeHandler}) => {
        return (
            <Autocomplete
                options={getTeamsAssignableForThisRole(memoAssignableTeams)}
                getOptionLabel={value => find(t => value.id ? value.id === t.id : value === t.id, memoAssignableTeams)?.school}
                isOptionEqualToValue={(option, value) => option.id === value}
                value={value}
                autoSelect
                onChange={(e, newValue) => {
                    if (newValue) {
                        changeHandler(newValue.id)
                    }
                }}
                renderInput={(params) => <MUITextField {...params} variant="standard" label="School"
                                                       required/>}
            />
        )
    }
    const getTeamsAssignableForThisRole = teams => {
        return filter(t => {
            return find (d => d.documentType === role,t.TeamDocuments)
        },teams)
    }

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsFetching(true)
                await getAllArbitrators()
                const teams = await getMemoAssignableTeams()
                const assignableTeams = getTeamsAssignableForThisRole(teams)
                if (size(assignableTeams) >= 6) {
                    setFirstSelectedTeamId(assignableTeams[0]?.id)
                    setSecondSelectedTeamId(assignableTeams[1]?.id)
                    setThirdSelectedTeamId(assignableTeams[2]?.id)
                    setFourthSelectedTeamId(assignableTeams[3]?.id)
                    setFifthSelectedTeamId(assignableTeams[4]?.id)
                    setSixthSelectedTeamId(assignableTeams[5]?.id)
                }
                setIsFetching(false)
            } catch (e) {
                //TODO ?
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSave = async e => {
        e.preventDefault()
        try {
            setIsFetching(true)
            const result = await saveArbitratorPairing({
                arbitratorId: selectedArbitratorId,
                teams: [firstSelectedTeamId, secondSelectedTeamId, thirdSelectedTeamId, fourthSelectedTeamId, fifthSelectedTeamId,sixthSelectedTeamId],
                role: role
            })
            if (result) {
                await getAllMemoAssignments(role,true)
                history.replace(`/admin/memoAssignments?role=${role}`)
            }
        }finally {
            setIsFetching(false)
        }

    }
    return (
        <Container maxWidth='xl' className={classes.root}>
            <Typography variant='h4' style={{textTransform: 'capitalize'}}>Add Arbitrator Evaluator for {role?.toLowerCase()} Memos</Typography>
            {isFetching &&
                <CircularProgress/>
            }
            {!isFetching &&
                <form onSubmit={onSave}>
                    <Grid container style={{flexGrow: 1, margin: '1rem 0', maxWidth: '500px'}} spacing={2}>
                        <Grid item xs={12} md={12} style={{marginBottom: '1rem'}}>
                            <Autocomplete
                                options={role === TEAM_ROLE_TYPE.CLAIMANT ? assignableClaimantArbitrators : assignableRespondentArbitrators}
                                getOptionLabel={value => {
                                    const matchingArb = find(t => value.id ? value.id === t.id : value === t.id, role === TEAM_ROLE_TYPE.CLAIMANT ? assignableClaimantArbitrators : assignableRespondentArbitrators)
                                    if (matchingArb)
                                        return `${matchingArb?.firstName} ${matchingArb.lastName}`
                                }}
                                isOptionEqualToValue={(option, value) => {
                                    return option.id === value
                                }}
                                value={selectedArbitratorId}
                                autoSelect
                                onChange={(e, newValue) => {
                                    if (newValue) {
                                        setSelectedArbitratorId(newValue.id)
                                    }
                                }}
                                renderInput={(params) => <MUITextField {...params} variant="standard" label="Arbitrator"
                                                                       required/>}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TeamAssignment value={firstSelectedTeamId}
                                            changeHandler={setFirstSelectedTeamId} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TeamAssignment value={secondSelectedTeamId}
                                            changeHandler={setSecondSelectedTeamId} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TeamAssignment value={thirdSelectedTeamId}
                                            changeHandler={setThirdSelectedTeamId} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TeamAssignment value={fourthSelectedTeamId}
                                            changeHandler={setFourthSelectedTeamId} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TeamAssignment value={fifthSelectedTeamId}
                                            changeHandler={setFifthSelectedTeamId} />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TeamAssignment value={sixthSelectedTeamId}
                                            changeHandler={setSixthSelectedTeamId} />
                        </Grid>
                        
                    </Grid>
                    <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                        <Button variant='contained' type='sumbit' color='primary'>Save</Button>
                        <NavLink className={'color-dark'} to={`/admin/memoAssignments?role=${role}`}>Cancel</NavLink>
                    </div>
                </form>
            }
        </Container>
    )
}

export default connect(
(state, ownProps) => ({
    router: state.router,
    assignableClaimantArbitrators: state.admin.assignableClaimantArbitrators,
    assignableRespondentArbitrators: state.admin.assignableRespondentArbitrators,
    memoAssignableTeams: state.admin.assignableTeams,
    ...ownProps
}), {
        getAllMemoAssignments: getAllMemoAssignments,
        getAllArbitrators: getAllArbitrators,
        getMemoAssignableTeams: getMemoAssignableTeams,
        saveArbitratorPairing: saveArbitratorPairing
    })(AddAssignment)