import Container from '@material-ui/core/Container'
import React, {useEffect} from 'react'
import {Typography} from '@material-ui/core'
import TeamsTable from './TeamsTable'
import ArbitratorTable from './ArbitratorTable'
import ClarificationsTable from './ClarificationsTable'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../../common/TabPanel'
import {connect} from "react-redux";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import _ from "lodash/fp";
import ordinal from "ordinal-number-suffix";
import {isNotEmpty} from "../../../utils/funcUtils";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}))

const AdminDashboard = ({ currentMoot = {}, getCurrentMoot = _.noop}) => {

  const classes = useStyles()
  const [tabValue, setTabValue] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)

  const handleTabChange = (event, newValue) => setTabValue(newValue)

    useEffect(() => {
        const asyncFetchData = async () => {
            setIsLoading(true)
            try{
                await getCurrentMoot()
            }catch(e){
            }
            setIsLoading(false)
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

  return (
      <Container maxWidth='xl' className={classes.root} style={{paddingBottom: '5rem'}}>
        <Typography variant='h4'>Administration Dashboard {isNotEmpty(currentMoot) ? `(${ordinal(currentMoot.mootNumber)} VEM)` : ''}</Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label='Teams' />
          <Tab label='Arbitrators' />
          <Tab label='Clarifications' />
          {/*<Tab label='Pairing' />*/}
        </Tabs>
        <TabPanel value={tabValue} index={0}>
          <TeamsTable />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ArbitratorTable />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ClarificationsTable />
        </TabPanel>
          {/*<TabPanel value={tabValue} index={3}>*/}
          {/*    <Pairing />*/}
          {/*</TabPanel>*/}
      </Container>
  )
}


export default connect(
    (state,ownProps) => ({
        currentMoot: state.moot.currentMoot,
    }),{
        getCurrentMoot: getCurrentMoot
    }
)(AdminDashboard)
