import React, {useState} from 'react'
import {DataGridPro, GridToolbarContainer, LicenseInfo, GridToolbarExport } from '@mui/x-data-grid-pro';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {noop, head, get, sumBy, includes} from "lodash/fp";
import {format} from "date-fns";
import {Snackbar, Tooltip, Typography} from "@material-ui/core";
import {Check} from "@mui/icons-material";
import * as actions from '../../../../actions/adminActions'
import './score.css'
import {Alert, Button} from "@mui/material";
import {getNextRoundTeamSizeMax, getNextRoundTypeToSelect} from "../../../../reducers/adminSelectors";
import {getRoundWinnerFieldFromRoundType} from "./OralArgUtils";
import {ADMIN} from "../../../../consts/roles";
import TeamName from "../../../team/TeamName";


LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const EliminationTeamResults = ({ selectedRoundTeamResults = [],
                                    user ={},
                                    pairRoundError= '',
                                    selectedRoundType = '',
                                    getNextRoundTypeToSelect = noop,
                                    getNextRoundTeamSizeMax = noop,
                                    pairWinnerRound = noop, setTab = noop}) => {
    const [busy, setBusy] = React.useState(false)
    const [saved, setSaved] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <GridToolbarExport 
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
                fileName: `${selectedRoundType} - Team Results - ${format(new Date(),'yyyy-MM-dd')}`,
                utf8WithBom: true,
              }}
            />
          </GridToolbarContainer>
        );
    }

    const onPairRound = async roundType => {
        try{
            setBusy(true)
            const result = await pairWinnerRound(roundType)
            if (result) {
                setTab(0)
            }
        }finally{
            setBusy(false)
        }
    }
    const nextRoundType = getNextRoundTypeToSelect(selectedRoundType)
    const nextRoundTeamSize = getNextRoundTeamSizeMax(selectedRoundType)

    const teamsAdded = () => {
        const fieldName = getRoundWinnerFieldFromRoundType(nextRoundType)
        return sumBy(s => s.roundWinners && s.roundWinners[fieldName] === true ? 1 : 0 ,selectedRoundTeamResults)
    }
    const roundFull = teamsAdded() === nextRoundTeamSize
    const roundAlreadyPaired = get('roundAlreadyPaired',head(selectedRoundTeamResults))
    const columns = [
        {
            field: 'team',
            headerName: 'School',
            flex: 1,
            renderCell: params => <TeamName team={params.row.team} adminUse={true} />,
            valueGetter: params => (params.row.team.hideTeamName === true ? `TEAM ID ${params.row.team.id}` : params.row.team.school)
        },
        {
            field: 'jurisdiction',
            headerName: 'Jurisdiction',
            flex: 1,
        },
        {
            field: 'winner',
            headerName: 'Winner',
            flex: 1,
            renderCell: params => params.row.roundWinners && params.row.roundWinners[getRoundWinnerFieldFromRoundType(nextRoundType)] === true ? <Check /> : null,
            valueGetter: params => params.row.roundWinners && params.row.roundWinners[getRoundWinnerFieldFromRoundType(nextRoundType)] === true ? "TRUE" : null,
        }
    ]
    return (
        <div style={{height: 700, width: '100%'}}>
            <DataGridPro
                loading={busy}
                rows={selectedRoundTeamResults}
                columns={columns}
                disableSelectionOnClick
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
            {selectedRoundType !== 'Finals' &&
                <>

                    <Tooltip title={ roundAlreadyPaired ? "Round already Paired": "Generate draft pairings"}>
                        <span>
                            <Button variant='contained'
                                    disabled={!roundFull || busy || !includes(ADMIN,user.roles) || roundAlreadyPaired}
                                    hint={roundAlreadyPaired ? 'Round already paired' : ''}
                                    onClick={e => onPairRound(nextRoundType)}
                                    style={{margin: '2rem 0'}}>Pair {nextRoundType}
                            </Button>
                        </span>
                    </Tooltip>
                    {pairRoundError &&
                        <Typography color='error'>{pairRoundError}</Typography>
                    }
                </>
            }
            <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                    {roundFull && `Round updated. Round full`
                    }
                    { !roundFull && `Round updated. ${teamsAdded()} teams added so far`}
                </Alert>
            </Snackbar>
        </div>
    )
}


EliminationTeamResults.propTypes = {
    selectedRoundTeamResults: PropTypes.arrayOf(PropTypes.object),
    selectedRoundType: PropTypes.string
}

export default connect(
    (state, ownProps) => ({
        selectedRoundTeamResults : state.admin.selectedRoundTeamResults,
        selectedRoundType : state.admin.selectedRoundType,
        setTab: ownProps.setTab,
        user: state.user,
        pairRoundError: state.admin.pairRoundError
    }), {
        pairWinnerRound : actions.pairWinnerRound,
        getNextRoundTypeToSelect: getNextRoundTypeToSelect,
        getNextRoundTeamSizeMax : getNextRoundTeamSizeMax

    })(EliminationTeamResults)
