//TODO move all these to backend DB tables

export const LEGAL_SYSTEMS = [
  'Common Law',
  'Civil Law',
  'Other'
]

export const ATTENDANCES = ['Virtual Vis East', 'Hybrid Vis East']

export const DEGREES = ['PhD', 'Masters', 'LLB or its equivalent', 'Juris Doctor or its equivalent', 'Other']

export const ROLES = ['Competitor','Coach']

export const GENDERS = ['Male', 'Female', 'Other/Not Disclosed']

export const NATIONALITIES = [
  'Afghan',  
  'Australian',
  'Austrian',
  'Brazilian',
  'British',
  'Burmese',
  'Cambodian',
  'Canadian',
  'Chilean',
  'Chinese',
  'Czech',
  'Dutch',
  'Egyptian',
  'Filipino',
  'Finnish',
  'French',
  'German',
  'Hong Kong Chinese',
  'Indian',
  'Indonesian',
  'Iranian',
  'Japanese',
  'Korean',
  'Malaysian',
  'Maldivians',
  'Mexican',
  'Mozambican',
  'Nepalese',
  'Pakistani',
  'Polish',
  'Romanian',
  'Russian',
  'Saudis',
  'Serbian',
  'Singaporean',
  'Slovenian',
  'Sri Lankan',
  'Swiss',
  'Taiwanese',
  'Thai',
  'Turkish',
  'Ukrainian',
  'U.S.A.',
  'Vietnamese'
]

export const TEAM_ROLE_TYPE = {
  CLAIMANT: "CLAIMANT",
  RESPONDENT: "RESPONDENT"
}