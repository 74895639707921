import * as actionTypes from "../actions/actionTypes/registrationActionTypes"
import * as teamActionTypes from "../actions/actionTypes/teamActionTypes"
import * as arbitratorActionTypes from "../actions/actionTypes/arbitratorActionTypes"
import {combineReducers} from "redux";


const registrationUser = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.USER_SIGNUP_REQUESTED) {
        return payload
    }else {
        return state
    }
}

const teamData = (state = {}, action) => {
    const { type, payload } = action
    if (type === actionTypes.SAVE_TEAM_REGISTRATION_DATA_SUCCEEDED){
        return payload
    }else if (type ===  teamActionTypes.GET_TEAM_SUCCEEDED){
        return payload
    }
    return state
}

const arbitratorData = ( state = {}, action) => {
    const { type, payload } = action
    if (type === actionTypes.SAVE_ARBITRATOR_REGISTRATION_DATA_SUCCEEDED){
        return payload
    }else if (type ===  arbitratorActionTypes.GET_ARBITRATOR_SUCCEEDED){
        return payload
    }
    return state
}

export default combineReducers({
    registrationUser,
    teamData,
    arbitratorData
})