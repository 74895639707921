import UserPool from "./UserPool";
import {AuthenticationDetails, CognitoUser} from "amazon-cognito-identity-js";

export const login = async (email, password) => {
    return new Promise((resolve,reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        })

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password
        })
        user.authenticateUser(authDetails,{
            onSuccess: data => {
                resolve(data)
            },
            onFailure: err => reject(err),
            newPasswordRequired: data =>  reject(data)
       })
    });
}

export const logoutUserSession = () => {
    const user = UserPool.getCurrentUser();
    if (user)
        user.signOut()
}

export const forgotPassword = async(email) => {
    return new Promise((resolve,reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        })

        user.forgotPassword({
            onSuccess: function (result) {
                resolve(result)
            },
            onFailure: function(err) {
                reject(err);
            }
        });
    })
}


export const createUserCognitoAccount = async (email, password) => {
    return new Promise((resolve, reject) => {
        try {
            UserPool.signUp(email, password, [], null, (err, data) => {
                if (err) {
                    reject(err)
                    return
                }
                //The user ID for this registrant
                resolve(data.userSub);
            })
        }catch(e)
        {
            reject(e)
        }
    });
}
export const confirmPassword = async(email,confirmationCode, newPassword) => {
    return new Promise((resolve,reject) => {
        const user = new CognitoUser({
            Username: email,
            Pool: UserPool
        })

        user.confirmPassword(confirmationCode,newPassword,{
            onSuccess: function (result) {
                resolve(result);
            },
            onFailure: function(err) {
                reject(err);
            }
        });
    })
}

export const getCurrentUser = async () => {
    return new Promise((resolve,reject) => {
        const user = UserPool.getCurrentUser()
        if (user){
            user.getSession((err,session) => {
                if (err) {
                    reject(err)
                }else {
                    resolve(session)
                }
            })
        }else
            reject()
    });
}

export const getCurrentUsername = () => {
    new Promise((resolve,reject) => {
        const user = UserPool.getCurrentUser()
        if (user){
            user.getSession((err,session) => {
                if (err) {
                    reject(err)
                }else {
                    resolve(session)
                }
            })
        }else
            reject()
    });

}
