import React from 'react'
import {CircularProgress } from '@material-ui/core'
import {DataGridPro, LicenseInfo} from '@mui/x-data-grid-pro';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getArbitratorOralArgumentSchedule, getSelectedArbitrator} from "../../reducers/arbitratorSelectors";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
//import {dateFormater, timeFormater} from "../../utils/dateUtils";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {friendlyRoundName} from "../helpers";
import {getCurrentMoot} from "../../reducers/mootSelectors";
import {get, noop} from "lodash/fp";
import TeamName from "../team/TeamName";
import {CustomTooltip} from "../helpers";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const ScheduleTable = ({
                           arbitrator = {},
                           oralArgumentSchedule = [],
                           getArbitratorOralArgumentSchedule = [],
                           getSelectedArbitrator = noop,
                           getCurrentMoot = noop,
                           moot
                       }) => {
    const [busy, setBusy] = React.useState(false)

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setBusy(true)
                await getCurrentMoot()
                await getSelectedArbitrator()
                await getArbitratorOralArgumentSchedule()
                setBusy(false)
            } catch (e) {
                //TODO ?
                setBusy(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const isEmergencyArbitrator = (firstReserveArbitratorId, secondReserveArbitratorId, thirdReserveArbitratorId) => {
        if (arbitrator.id === firstReserveArbitratorId || 
            arbitrator.id === secondReserveArbitratorId ||
            arbitrator.id === thirdReserveArbitratorId) {
            return true

        } else {
            return false
        }
    }


    const columns = [
        {
            field: 'roundType',
            headerName: 'Round',
            flex: 1,
            valueGetter: params => friendlyRoundName(params.row.roundType)
        },
        {
            field: 'pairingDate',
            headerName: 'Date',
            width: 130,
            align: 'right',
            sortable: false,
            //renderCell : params => dateFormater(params.row.pairingTime)
            renderCell: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'iii, dd MMMM'),
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'yyyy/MM/dd HH:mm'),
            
        },
        {
            field: 'pairingTime',
            headerName: 'Time',
            align: 'right',
            width: 100,
            sortable: false,
            //renderCell: params => timeFormater(params.row.pairingTime)
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'h:mm aaa')
        },
        {
            field: 'claimant',
            headerName: 'Claimant',
            flex: 1,
            renderCell: params => (isEmergencyArbitrator(params.row.firstReserveArbitratorId, params.row.secondReserveArbitratorId, params.row.thirdReserveArbitratorId) ? '—' : 
            (params.row.claimant ? <a href={`${params.row.claimantDocUrl}`}><TeamName team={params.row.claimant} /></a> : 'available soon'))
        },
        {
            field: 'respondent',
            headerName: 'Respondent',
            flex: 1,
           renderCell: params => (isEmergencyArbitrator(params.row.firstReserveArbitratorId, params.row.secondReserveArbitratorId, params.row.thirdReserveArbitratorId) ? '—' : 
           (params.row.respondent ? <a href={`${params.row.respondentDocUrl}`}><TeamName team={params.row.respondent} /></a> : 'available soon'))
        },
        {
            field: 'room',
            headerName: 'Room',
            flex: 1,
            sortable: false,
            renderCell: params => {
                if(isEmergencyArbitrator(params.row.firstReserveArbitratorId, params.row.secondReserveArbitratorId, params.row.thirdReserveArbitratorId) ) {
                    return <CustomTooltip content="Report to Vis East Centre – Reserve Arbitrator" />                    
                } else  if (!get('row.room',params)){
                    return <CustomTooltip content='available soon' />
                } else {                    
                      return <CustomTooltip content={params.row.room} />
                }
            }
        },
        /*
        {
            field: 'hearingLink',
            headerName: 'Virtual Hearing Link',
            flex: 1,
            hide: true,
            renderCell: params => {
                if (!params.row.hearingLink){
                    return (<span>coming soon</span>)
                }else{
                    return (<a target="_blank" rel="noreferrer" href={`${params.row.hearingLink}`}>Click Here</a>)
                }
            }
        },
        */
       /*        
        {
            field: 'chairArbitrator',
            headerName: 'Arbitrator',
            flex: 1,            
            valueGetter: params => {                
                if(params.row.draft === false 
                    || params.row.chairArbitratorId === arbitrator.id) {
                    return `${params.row.chairArbitrator?.firstName ?? ''} ${params.row.chairArbitrator?.lastName ?? ''}`
                } else {
                    return ''
                }
            }
        },
        {
            field: 'secondArbitrator',
            headerName: 'Arbitrator',
            flex: 1,            
            valueGetter: params => {                
                if(params.row.draft === false 
                    || params.row.secondArbitratorId === arbitrator.id) {
                    return `${params.row.secondArbitrator?.firstName ?? ''} ${params.row.secondArbitrator?.lastName ?? ''}`
                } else {
                    return ''
                }
            }
        },
        {
            field: 'thirdArbitrator',
            headerName: 'Arbitrator',
            flex: 1,            
            valueGetter: params => {                
                if(params.row.draft === false 
                    || params.row.thirdArbitratorId === arbitrator.id) {
                    return `${params.row.thirdArbitrator?.firstName ?? ''} ${params.row.thirdArbitrator?.lastName ?? ''}`
                } else {
                    return ''
                }
            }
        },
        {
            field: 'firstReserveArbitrator',
            headerName: 'Emergency Arbitrator',
            flex: 1,            
            valueGetter: params => {                
                if(moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === false 
                    || params.row.firstReserveArbitratorId === arbitrator.id) {
                    return `${params.row.firstReserveArbitrator?.firstName ?? ''} ${params.row.firstReserveArbitrator?.lastName ?? ''}`
                } else {
                    return ''
                }
            }
        },
        {
            field: 'secondReserveArbitrator',
            headerName: 'Emergency Arbitrator',
            hide: true,
            flex: 1,            
            valueGetter: params => {                
                if(moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === false 
                    || params.row.secondReserveArbitratorId === arbitrator.id) {
                    return `${params.row.secondReserveArbitrator?.firstName ?? ''} ${params.row.secondReserveArbitrator?.lastName ?? ''}`
                } else {
                    return ''
                }
            }
        },
        {
            field: 'thirdReserveArbitrator',
            headerName: 'Emergency Arbitrator',
            hide: true,
            flex: 1,            
            valueGetter: params => {                
                if(moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === false 
                    || params.row.thirdReserveArbitratorId === arbitrator.id) {
                    return `${params.row.thirdReserveArbitrator?.firstName ?? ''} ${params.row.thirdReserveArbitrator?.lastName ?? ''}`
                } else {
                    return ''
                }
            }
        },
        */
    ]
    const classes = useStyles()

    return (
        <Container maxWidth='xl' className={classes.root} style={{paddingBottom: '10rem'}}>
            <Typography variant='h4' component='h1'>Oral Arguments</Typography>
            {busy &&
            <CircularProgress />
            }
            {!busy && moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === true &&
                <Typography style={{marginTop: '1rem'}}>The schedule is still tentative and being finalized. The Vis East Administration will send an email when the schedules are finalized.</Typography>
            }
           {!busy && moot.showOralDetailsArbitratorsOpenDate?.notYetOpen === false &&
           <>
            <Typography style={{marginTop: '1rem'}}>Please see below your arbitrator schedule for the week of oral arguments. Your schedule is subject to changes and we request that you keep your availability and conflicts up to date in order to ensure a smooth process during oral arguments week.</Typography>
            <Typography style={{marginTop: '1rem'}}>As a kind reminder, please make sure to check-in at the Vis East Centre at least <strong><u>15 minutes</u></strong> before your scheduled hearing. All oral arguments will take place <strong>in person</strong> at the Vis East Centre, which is on the 2/F of the West Wing, Justice Place, 11 Ice House Street (on the corner of Ice House Street and Queens Road Central), in Central on Hong Kong Island.</Typography>
            <Typography style={{marginTop: '1rem'}}>Please also ensure that you bring a smart device such as a smartphone, tablet or laptop (a device that can connect to the internet) for the oral argument as the scoresheets will be accessed online. For any questions or concerns, please email the Vis East Administration at: <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a> or ask the Vis East Administration at the Vis East Centre.
            </Typography>
            <div style={{height: 700, width: '100%', marginTop: '2rem'}}>
                <DataGridPro
                    loading={busy}
                    rows={oralArgumentSchedule}
                    columns={columns}
                    disableSelectionOnClick
                />
            </div>            
            </>
            }
            
        </Container>
    )
}


ScheduleTable.propTypes = {
    oralArgumentSchedule: PropTypes.arrayOf(PropTypes.object),
    getArbitratorOralArgumentSchedule: PropTypes.func,
    getSelectedArbitrator: PropTypes.func,
}

export default connect(
    (state, ownProps) => ({
        arbitrator: state.arbitrator.selectedArbitrator,
        oralArgumentSchedule: state.arbitrator.oralArgumentSchedule,
        moot: state.moot.currentMoot,
    }), {
        getSelectedArbitrator: getSelectedArbitrator,
        getArbitratorOralArgumentSchedule: getArbitratorOralArgumentSchedule,
        getCurrentMoot: getCurrentMoot
    })(ScheduleTable)
