import {Invoice} from "./Invoice";
import {pdf} from "@react-pdf/renderer";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {get, last} from "lodash/fp";
import React from "react";

const downloadInvoice = async (team, paymentHistory, isTeamMemberOpen) => {
    const invoice = <Invoice team={team} transactions={paymentHistory} isTeamMemberOpen={isTeamMemberOpen}  />
    const data = await pdf(invoice).toBlob();

    const fullName = `Invoice-VEM-${team.id}-${ format(utcToZonedTime(get('createdAt',last(paymentHistory)), 'Asia/Hong_Kong'),'yyyyMMdd')}.pdf`;

    if ('download' in HTMLAnchorElement.prototype) {
        // Create an object URL for the blob object
        const url = URL.createObjectURL(data);

        // Create a new anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = fullName;

        // Programmatically trigger a click on the anchor element
        // Useful if you want the download to happen automatically
        // Without attaching the anchor element to the DOM
        a.click();

        // https://github.com/eligrey/FileSaver.js/issues/205
        setTimeout(() => {
            URL.revokeObjectURL(url);
        });
    }
}

export default downloadInvoice