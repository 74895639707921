
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
        const spliced = reader.result.split(',');
        const header = spliced[0];
        spliced.shift();
        resolve({
            header: header,
            body: spliced.join('')
        });
    }
    reader.onerror = error => reject(error);
});

module.exports = {
    toBase64
}