import * as React from 'react';
import { Button, Menu, MenuItem, Divider, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'
import {logoutUserSession} from "../../utils/cognitoHelpers";
import {connect} from "react-redux";
import {TEAM_OWNER, ARBITRATOR, ADMIN, SCORER, TEAM_MEMBER} from 'consts/roles';
import { replace } from 'connected-react-router'
import {head, noop, includes, isEmpty} from "lodash/fp";
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContentAdmin from '@mui/icons-material/SupervisorAccount';
import ContentHome from '@mui/icons-material/Home';
import ContentPayment from '@mui/icons-material/Payment';
import ContentRegister from '@mui/icons-material/AppRegistration';
import ContentLogout from '@mui/icons-material/Logout';
import {isNotEmpty} from "../../utils/funcUtils";

const MainMenu = ({user = {},  replace = noop, selectedTeam = {} }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    logoutUserSession()
    window.location.href = '/login'
  }

  const userRole = (currentUser) => {
    return includes(TEAM_OWNER,currentUser.roles) ? 'Role: Authorized Team Representative' : (includes(TEAM_MEMBER,currentUser.roles) ? 'Role: Team Member' : '')
  }

  const teamMemberInvolvement = currentUser => {
    if(isNotEmpty(currentUser?.TeamMember)) {
      return ' / ' + head(currentUser.TeamMember)?.involvement;
    } 
  }

  return (
    <div>
      {!user.username && (
        <Button
        onClick={ () => replace('/login') }
        style={{color:'#fff', marginTop:'0.95rem', marginRight:'-8px'}}
        >Sign in
        </Button>
      )}
      {user.username && (
        <>
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            style={{color:'#fff', marginTop:'0.95rem', marginRight:'-12px'}}
          >
            Menu
          </Button>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >

            <MenuItem disabled={true}>{user.username}</MenuItem>
            <Divider />
            {/*user.roles && user.roles.includes(ADMIN) && (
              <>
                <MenuItem component={Link} to='/admin' onClose={handleClose}>Dashboard</MenuItem>
                <Divider />
              </>  
            )*/}
            {(includes(TEAM_OWNER,user.roles) || includes(ARBITRATOR,user.roles) || includes(ADMIN,user.roles) || isEmpty(user.roles)) &&
              <div>
                <MenuItem component={Link} to='/registration'>
                  <ListItemIcon style={{minWidth:'30px', marginBottom:'4px'}}>
                    <ContentRegister fontSize="small" />
                  </ListItemIcon>
                  <ListItemText><span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif'}}>Registration</span></ListItemText>
                </MenuItem>
              </div>
           }

            <Divider />
            { (includes(TEAM_OWNER,user.roles) || includes(TEAM_MEMBER,user.roles)) && isNotEmpty(selectedTeam) &&
              <div>
                <MenuItem component={Link} to={includes(TEAM_OWNER,user.roles) ? '/team-owner' : '/team-member'} onClose={handleClose}>
                  <ListItemIcon style={{minWidth:'30px', marginBottom:'4px'}}>
                    <ContentHome fontSize="small" />
                  </ListItemIcon>
                  <ListItemText style={{margin: 0}}>
                    <div style={{display:"flex", flexDirection: "column", margin: 0}}>
                      <span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight:'bold'}}>Team Account</span>                  
                      <span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontSize: '0.688rem', fontWeight:'normal'}}>{userRole(user)} {teamMemberInvolvement(user)}</span>
                    </div>        
                  </ListItemText>
                </MenuItem>
              </div>
            }
            {(includes(TEAM_OWNER,user.roles) || includes(TEAM_MEMBER,user.roles)) &&
            <div>
              {/* 
              //Routes to reference
              to='/team/clarification-request'
              to='/team/documents'
              to='/team/members'
              to='/team/oral-arguments'
              */}
              {/* To-do: How can this be supported when registration is closed?
              <MenuItem style={{paddingLeft: '46px' }} to='/registration/team' component={Link}>Update Registration</MenuItem>
              */}
              <MenuItem style={{paddingLeft: '46px' }} to='/team/clarification-request' component={Link}>Clarification Requests</MenuItem>
              <MenuItem style={{paddingLeft: '46px' }} to='/team/documents' component={Link}>Written Submissions</MenuItem>
              <MenuItem style={{paddingLeft: '46px' }} to='/team/members' component={Link}>Team Members</MenuItem>
              <MenuItem style={{paddingLeft: '46px'}} to='/team/oral-arguments' component={Link}>Oral Arguments</MenuItem>
              {/* Example of coming soon treatment
              <Tooltip title='Coming Soon' enterTouchDelay={0} arrow> 
                <MenuItem style={{paddingLeft: '46px', cursor: 'default', opacity: '0.5'}} component={Link}>Oral Arguments</MenuItem>
              </Tooltip>
              */}
              {(includes(TEAM_OWNER,user.roles) &&
              <MenuItem to='/team/payments' component={Link}>
                <ListItemIcon style={{minWidth:'30px', marginBottom:'4px'}}>
                  <ContentPayment fontSize="small" />
                </ListItemIcon>
                <ListItemText><span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight: 'bold'}}>Payment & Finances</span></ListItemText>
              </MenuItem>
              )}
            </div>
           }
            <Divider />

            {user.roles && user.roles.includes(ARBITRATOR) && (
              <div>
                <MenuItem component={Link} to='/arbitrator' onClose={handleClose}>
                  <ListItemIcon style={{minWidth:'30px', marginBottom:'4px'}}>
                    <ContentHome fontSize="small" />
                  </ListItemIcon>
                  <ListItemText><span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight:'bold'}}>Arbitrator Account</span></ListItemText>
                </MenuItem>
                <MenuItem style={{paddingLeft: '46px' }} component={Link} to='/registration/arbitrator'>Update Registration</MenuItem>
                <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/arbitrator/availability' onClose={handleClose}>Availability & Conflicts</MenuItem>
                <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/arbitrator/pairings' onClose={handleClose}>Ranking of Written Submissions</MenuItem>
                <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/arbitrator/oral-arguments' onClose={handleClose}>Oral Arguments</MenuItem>
              </div>
            )}
                      
            {user.roles && user.roles.includes(ADMIN) && (
                <div>
                  <Divider />
                  <MenuItem component={Link} to='/admin' onClose={handleClose}>
                    <ListItemIcon style={{minWidth:'30px', marginBottom:'4px'}}>
                      <ContentAdmin fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight:'bold'}}>Admin Account</span></ListItemText>
                  </MenuItem>
                  <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/admin' onClose={handleClose}>Dashboard</MenuItem>
                  <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/admin/memoAssignments' onClose={handleClose}>Memo Assignments</MenuItem>
                  <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/admin/memoRankings' onClose={handleClose}>Memo Rankings</MenuItem>
                  <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/admin/oral-arguments' onClose={handleClose}>Oral Arguments</MenuItem>
                  <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/admin/settings' onClose={handleClose}>Settings</MenuItem>
                </div>
            )}
            {user.roles && user.roles.includes(SCORER) && (
                <div>
                  <MenuItem style={{paddingLeft: '46px'}} component={Link} to='/admin/oral-arguments' onClose={handleClose}>Oral Arguments</MenuItem>
                </div>
            )}
            {user.username && (
                <div>
                  <Divider />
                  <MenuItem onClick={logout}>
                    <ListItemIcon style={{minWidth:'30px', marginBottom:'4px'}}>
                      <ContentLogout fontSize="small" />
                    </ListItemIcon>
                    <ListItemText><span style={{fontFamily: '"Avenir Medium", Arial, Helvetica, sans-serif', fontWeight:'bold'}}>Sign out</span></ListItemText>
                  </MenuItem>
                </div>
              )}
          </Menu>
        </>
      )}
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    router: state.router,
    user: state.user,
    selectedTeam: state.team.selectedTeam
  }),{
      replace
  }

)(MainMenu)