import Container from '@material-ui/core/Container'
import React, {useEffect} from 'react'
import {Typography} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from '../../../common/TabPanel'
import Schedule from "./Schedule";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getRounds} from "../../../../reducers/adminSelectors";
import {selectRound} from "../../../../actions/adminActions";
import {CircularProgress} from "@mui/material";
import Select from "../../../common/Select";
import GeneralTeamResults from "./GeneralTeamResults";
import EliminationTeamResults from "./EliminationTeamResults";
import OralistResults from "./OralistResults";
import EliminationSchedule from "./EliminationSchedule";
import {noop} from "lodash/fp";
import './OralArguments.css'
import {getCurrentMoot} from '../../../../reducers/mootSelectors'
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const OralArgumentsDashboard = ({ router = {}, selectedRoundType = '', roundTypes = [], moot, selectRound = noop, getRounds = noop, getCurrentMoot = noop}) => {
    const history = useHistory()

    const classes = useStyles()
    const [busy, setBusy] = React.useState(false)
    const [tabValue, setTabValue] = React.useState(0)

    useEffect(() => {
        setTabValue(0)
    }, [selectedRoundType]);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
        history.push(`${router.location.pathname}?tab=${newValue}`)
    }

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setBusy(true)
                await getCurrentMoot()
                await getRounds();
                if (!selectedRoundType) {
                    await selectRound('General Rounds')
                }
                setTabValue(parseInt(router.location.query.tab) || 0)
                setBusy(false)
            } catch (e) {
                //TODO ?
                setBusy(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const onSelectRound = async round =>{
        try{
            setBusy(true)
            let roundType = round
            if (round.roundType) {
                roundType = round.roundType
            }
            await selectRound(roundType)
            history.push(`${router.location.pathname}`)
            setTabValue(0)

        }finally {
            setBusy(false)
        }
    }

    const setTab = (index) =>{
        handleTabChange({},index)
    }

    return (
        <Container maxWidth='xl' className={classes.root}>
                    <Typography variant='h4'>Oral Arguments</Typography>
                    <Select name="roundSelector"
                            options={roundTypes}
                            value={selectedRoundType}
                            onChange={e => onSelectRound(e.target.value)}/>
            {busy &&
                <CircularProgress/>
            }{ !busy &&
            <>
                <Tabs value={tabValue} onChange={handleTabChange}>
                    {selectedRoundType === 'General Rounds' &&
                        <Tab label='All'/>
                    }
                    {selectedRoundType !== 'General Rounds' &&
                        <Tab label='Schedule'/>
                    }
                    {selectedRoundType === 'General Rounds' &&
                        <Tab label='Monday'/>
                    }
                    {selectedRoundType === 'General Rounds' &&
                        <Tab label='Tuesday'/>
                    }
                    {selectedRoundType === 'General Rounds' &&
                        <Tab label='Wednesday'/>
                    }
                    {selectedRoundType === 'General Rounds' &&
                        <Tab label='Thursday' />
                    }
                    <Tab label='Team Results'/>
                    {selectedRoundType === 'General Rounds' &&
                        <Tab label='Oralist Results'/>
                    }
                </Tabs>
                <TabPanel value={tabValue} index={0}>
                    {selectedRoundType === 'General Rounds' &&
                        <Schedule moot={moot}/>
                    }
                    {selectedRoundType !== 'General Rounds' &&
                        <EliminationSchedule moot={moot}/>
                    }
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    {selectedRoundType === 'General Rounds' &&
                        <Schedule moot={moot} dateFilter={'Monday'}/>
                    }
                    {selectedRoundType !== 'General Rounds' &&
                        <EliminationTeamResults setTab={setTab}/>
                    }
                </TabPanel>
                {selectedRoundType === 'General Rounds' &&
                    <>
                        <TabPanel value={tabValue} index={2}>
                            <Schedule moot={moot} dateFilter={'Tuesday'}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={3}>
                            <Schedule moot={moot} dateFilter={'Wednesday'}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={4}>
                            <Schedule moot={moot} dateFilter={'Thursday'}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={5}>
                            <GeneralTeamResults setTab={setTab}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={6}>
                            <OralistResults />
                        </TabPanel>
                    </>
                }
            </>
        }
        </Container>

    )
}


OralArgumentsDashboard.propTypes = {
    selectedRound: PropTypes.object,
    selectedRoundType: PropTypes.string,
    roundTypes: PropTypes.arrayOf(PropTypes.string),
    selectRoundType: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        router: state.router,
        selectedRoundType: state.admin.selectedRoundType,
        selectedRound: state.admin.selectedRound,
        rounds: state.admin.rounds,
        roundTypes: state.admin.roundTypes,
        moot: state.moot.currentMoot,
    }),{
        selectRound: selectRound,
        getRounds: getRounds,
        getCurrentMoot: getCurrentMoot
    })(OralArgumentsDashboard)
