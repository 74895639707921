import * as Actions from './actionTypes/mootActionTypes'
import * as api from '../api'
import makeAction from "./actionCreator";
import {ACTIVATE_MOOT_SUCCEEDED} from "./actionTypes/mootActionTypes";

export const getAllMoots = () => async (dispatch,getState) => {
    dispatch(makeAction(Actions.GET_ALL_MOOTS_REQUESTED))

    try {
        const result = await api.getMoot()
        dispatch(makeAction(Actions.GET_ALL_MOOTS_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.GET_ALL_MOOTS_FAILED, {},e))
    }
}

export const selectMoot = moot => async (dispatch,getState) =>{
    dispatch(makeAction(Actions.SELECT_MOOT,moot))
}

export const saveMoot = (moot,schedule) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.SAVE_MOOT_REQUESTED, {moot,schedule}))

    try {
        const result = await api.saveMoot(moot,schedule)
        dispatch(makeAction(Actions.SAVE_MOOT_SUCCEEDED, result))
        return result
    } catch (e) {
        dispatch(makeAction(Actions.SAVE_MOOT_FAILED, {},e))
    }
}

export const activateMoot = (mootId) => async (dispatch,getState) => {
    dispatch(makeAction(Actions.ACTIVATE_MOOT_REQUESTED))
    try {
        const result = await api.activateMoot(mootId)
        dispatch(makeAction(Actions.ACTIVATE_MOOT_SUCCEEDED, result))
    } catch (e) {
        dispatch(makeAction(Actions.ACTIVATE_MOOT_FAILED, {},e))
    }
}