import * as actionTypes from "../actions/actionTypes/globalActionTypes"
import {combineReducers} from "redux";
import _ from "lodash/fp";


const schools = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_GLOBALS_SUCCEEDED) {
        return _.get('schools',payload)
    }else {
        return state
    }
}
const schoolNames = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_GLOBALS_SUCCEEDED) {
        return _.map(s => s.name,_.get('schools',payload))
    }else {
        return state
    }
}

const jurisdictions = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_GLOBALS_SUCCEEDED) {
        return _.get('jurisdictions',payload)
    }else {
        return state
    }
}
const jurisdictionNames = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_GLOBALS_SUCCEEDED) {
        return _.map(s => s.name,_.get('jurisdictions',payload))
    }else {
        return state
    }
}

const clarificationCategories = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_GLOBALS_SUCCEEDED) {
        return _.get('clarificationCategories',payload)
    }else {
        return state
    }
}

export default combineReducers({
    schools,
    schoolNames,
    jurisdictions,
    jurisdictionNames,
    clarificationCategories
})