import PaymentHistory from "../../team/PaymentHistory";
import {connect} from "react-redux";
import { noop, filter, head, get } from 'lodash/fp'
import {getAllTeams} from "../../../reducers/adminSelectors";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import TextInput from "../../common/TextInput";
import {asHKCurrency, formatCurrency} from "../../helpers";
import {Box, Checkbox, CircularProgress, Container, Button, FormControlLabel, FormGroup, FormLabel, Grid, Tooltip, Typography} from "@material-ui/core";
import {saveTeamOrder} from 'actions/teamActions'
import { Link } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {getIsTeamMemberOpen} from "../../../reducers/mootSelectors"
import HelpIcon from '@mui/icons-material/Help';
import { margin } from "@mui/system";


const AdminTeamPayments = ({selectedTeamId = 0,teams = [],getTeams = noop, saveTeamOrder = noop, getIsTeamMemberOpen = noop, isTeamMemberOpen = {}}) =>{

    const [busy, setBusy] = useState(false)
    const [description, setDescription] = useState('')
    const [amount,setAmount] = useState('')
    const [bankTransfer,setBankTransfer] = useState(false)
    const [selectedTeam,setSelectedTeam] = useState(head(filter(t => t.id === parseInt(selectedTeamId),teams)))

    const onAddPayment = async() => {
        try{
            setBusy(true)
            const finalDescription = (bankTransfer ? 'Bank Transfer: ' : '') + description
            const type = parseFloat(amount) < 0 ? 'FEE' : 'CREDIT'
            await saveTeamOrder(selectedTeamId, {}, finalDescription, amount, type )            //Save payment
            const teams = await getTeams(true)
            setSelectedTeam(head(filter(t => t.id === parseInt(selectedTeamId),teams)))
            setDescription('')
            setAmount('')
            setBankTransfer(false)

        }catch
        {}
        setBusy(false)
    }

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setBusy(true)
                const teams = await getTeams()
                setSelectedTeam(head(filter(t => t.id === parseInt(selectedTeamId),teams)))
                await getIsTeamMemberOpen()               
                setBusy(false)
            }catch(e){
                //TODO ?
                setBusy(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <Container style={{paddingBottom: '10rem'}}>
            { !selectedTeam &&
                <CircularProgress />
            }
            {selectedTeam &&
                <>
                    <Button style={{ margin: '0 0 2rem 0' }} variant='text' component={Link}  to='/admin' >
                            <ArrowBackIosIcon /> Back to Administration Dashboard
                    </Button>
                    <Typography variant='h4' component='h1'>{selectedTeam.school}</Typography>
                    <PaymentHistory busy={busy} showAdminFeatures={true}
                                    paymentHistory={get('PaymentHistory', selectedTeam)}
                                    overrideTeam={selectedTeam}
                                    isTeamMemberOpen={isTeamMemberOpen}
                                    />
                    <Box style={{
                        background: "#F0F0F0",
                        marginTop: "4rem",
                        padding: "1.75rem",
                        border: "1px solid #D8D8D8"
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography>To apply a credit to the account enter a positive dollar amount. To apply a
                                    fee to the account enter a negative dollar amount (e.g. -1000)</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onChange={e => {
                                    setDescription(e.target.value)
                                }}
                                           value={description}
                                           label='Description'
                                           type="text"
                                           required>

                                </TextInput>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onChange={e => {
                                    setAmount(e.target.value)
                                }}
                                           value={amount}
                                           label='Amount'
                                           type="number"
                                           helperText={
                                               amount && formatCurrency(amount, `This will apply a ${parseInt(amount) < 0 ? 'fee' : 'credit'} of`)
                                           }
                                           required>
                                </TextInput>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop: '0'}}>
                                <FormGroup row style={{alignItems: 'center'}}>
                                    
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={bankTransfer}
                                                        onChange={e => setBankTransfer(e.target.checked)}
                                                        color='primary'
                                                        required
                                                    />
                                                }
                                                style={{marginRight:'4px'}}
                                                label={`Mark as Bank Transfer`}
                                            />
                                            <Tooltip title='Mark as Bank Transfer will prepend the text "Bank Transfer: " to the description after adding the transaction.'>
                                                <HelpIcon sx={{ fontSize: 18 }}/>
                                            </Tooltip>
                                        </FormGroup>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button variant='contained' color="primary" disabled={busy}
                                        onClick={onAddPayment}>Add</Button>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            }
        </Container>
    )
}

AdminTeamPayments.prototype = {
    isTeamMemberOpen: PropTypes.object,
    getIsTeamMemberOpen: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        selectedTeamId: ownProps.match.params.teamId,
        teams: state.admin.teams,
        isTeamMemberOpen: state.moot.currentMoot.isTeamMemberOpen,
    }),{
        getTeams: getAllTeams,
        saveTeamOrder: saveTeamOrder,
        getIsTeamMemberOpen: getIsTeamMemberOpen
    })( AdminTeamPayments)