import Container from '@material-ui/core/Container'
import React, {useState} from 'react'
import {Button, CircularProgress, Snackbar, Typography} from '@material-ui/core'
import {makeStyles} from '@material-ui/core/styles'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {map, noop, filter, chain} from "lodash/fp";
import {getSelectedPairing} from "../../../../reducers/adminSelectors";
import {saveScores} from "../../../../actions/adminActions";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import Grid from "@material-ui/core/Grid";
import Select from "../../../common/Select";
import TextInput from "../../../common/TextInput";
import {Alert} from "@mui/material";
import { Link, useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TeamName from 'components/team/TeamName';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}))

const ScorePairing = ({selectedPairingId = 0, getSelectedPairing = noop, selectedPairing = {}, saveScores = noop, scoringSaveError = ''}) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(true)
    const [isSaving, setIsSaving] = React.useState(false)
    const [newScoring, setNewScoring] = React.useState({})
    const [saved, setSaved] = useState(false);

    const onSave = async e => {
        e.preventDefault()
        setIsSaving(true)
        const result = await saveScores(selectedPairingId,newScoring)
        setIsSaving(false)
        if (result) {
            setSaved(true)
        }
    }

    const history = useHistory()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    React.useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setIsLoading(true)
                const result = await getSelectedPairing(selectedPairingId)
                const score = result.PairingScore
                setNewScoring({
                    firstClaimantId: score.firstClaimantId,
                    secondClaimantId: score.secondClaimantId,
                    firstRespondentId: score.firstRespondentId,
                    secondRespondentId: score.secondRespondentId,
                    firstClaimantChairScore: score.firstClaimantChairScore,
                    firstClaimantArb1Score: score.firstClaimantArb1Score,
                    firstClaimantArb2Score: score.firstClaimantArb2Score,
                    secondClaimantChairScore: score.secondClaimantChairScore,
                    secondClaimantArb1Score: score.secondClaimantArb1Score,
                    secondClaimantArb2Score: score.secondClaimantArb2Score,
                    firstRespondentChairScore: score.firstRespondentChairScore,
                    firstRespondentArb1Score: score.firstRespondentArb1Score,
                    firstRespondentArb2Score: score.firstRespondentArb2Score,
                    secondRespondentChairScore: score.secondRespondentChairScore,
                    secondRespondentArb1Score: score.secondRespondentArb1Score,
                    secondRespondentArb2Score: score.secondRespondentArb2Score,
                })
                setIsLoading(false)
            } catch (e) {
                //TODO ?
                setIsLoading(false)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const availableClaimantMembers =  filter(m => m.willBeInPerson === true && m.involvement === 'Competitor' ,selectedPairing.claimant?.members)
    const claimantMembers = chain(availableClaimantMembers)
        .map(m => ({
            id: m.id,
            name: `${m.firstName} ${m.lastName}`,
            lastName: m.lastName
        }))
        .sortBy(['lastName']) // Sort by 'lastName' ascending
        .value();

    const availableRespondentMembers = filter(m => m.willBeInPerson === true && m.involvement === 'Competitor' ,selectedPairing.respondent?.members)    
    const respondentMembers = chain(availableRespondentMembers)
        .map(m => ({
            id: m.id,
            name: `${m.firstName} ${m.lastName}`,
            lastName: m.lastName
        }))
        .sortBy(['lastName']) // Sort by 'lastName' ascending
        .value();

    return (
        <Container maxWidth='xl' className={classes.root}>
            {isLoading &&
                <CircularProgress/>
            }
            {!isLoading &&
                <form onSubmit={onSave}>
                    <Button style={{ margin: '0 0 2rem 0' }} variant='text' component={Link}  onClick={() => history.go(-1)} >
                        <ArrowBackIosIcon /> Back to Oral  Arguments
                    </Button>
                    <Typography variant='h4'>Input Scores</Typography>

                    <Typography style={{marginBottom: '2rem'}}> <strong><TeamName team={selectedPairing.claimant} adminUse={true} /> vs. <TeamName team={selectedPairing.respondent} adminUse={true} /></strong> ({format(utcToZonedTime(selectedPairing.pairingTime, 'Asia/Hong_Kong'), 'iii, dd MMMM hh:mm aaa')})</Typography>
                       
                    <Typography><strong>Claimant: <TeamName team={selectedPairing.claimant} adminUse={true} /></strong> ({selectedPairing.claimant.jurisdiction})</Typography>
                    <Grid container style={{padding: '0 0 0 20px',}} spacing={6}>
                        <Grid item xs={4} md={4}>
                            <Select name='firstClaimantId' required
                                    onChange={e => setNewScoring({...newScoring, firstClaimantId: e.target.value})}
                                    value={newScoring.firstClaimantId} label='Competitor'
                                    options={claimantMembers}/>

                        </Grid>
                        <Grid item xs={2} md={2}>
                            <TextInput type="number"
                                       name='firstClaimantChairScore'
                                       required
                                       width="100"
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           firstClaimantChairScore: e.target.value
                                       })}
                                       value={newScoring.firstClaimantChairScore}
                                       label='Arbitrator 1 score'
                                       helperText="between 50 and 100"
                                       min={50}
                                       max={100}
                            />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <TextInput type="number"
                                       name='firstClaimantArb1Score'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           firstClaimantArb1Score: e.target.value
                                       })}
                                       value={newScoring.firstClaimantArb1Score}
                                       label='Arbitrator 2 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <TextInput type="number"
                                       name='firstClaimantArb2Score'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           firstClaimantArb2Score: e.target.value
                                       })}
                                       value={newScoring.firstClaimantArb2Score}
                                       label='Arbitrator 3 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{padding: '0 0 0 20px', }} spacing={6}>
                        <Grid item xs={4} md={4} style={{backgroundColor: '#F8F8F8',}}>
                            <Select name='secondClaimantId' required
                                    onChange={e => setNewScoring({...newScoring, secondClaimantId: e.target.value})}
                                    value={newScoring.secondClaimantId} label='Competitor'
                                    options={claimantMembers}/>
                        </Grid>
                        <Grid item xs={2} md={2} style={{backgroundColor: '#F8F8F8',}}>
                            <TextInput type="number"
                                       name='secondClaimantChairScore'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           secondClaimantChairScore: e.target.value
                                       })}
                                       value={newScoring.secondClaimantChairScore}
                                       label='Arbitrator 1 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2} style={{backgroundColor: '#F8F8F8',}}>
                            <TextInput type="number"
                                       name='secondClaimantArb1Score'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           secondClaimantArb1Score: e.target.value
                                       })}
                                       value={newScoring.secondClaimantArb1Score}
                                       label='Arbitrator 2 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2} style={{backgroundColor: '#F8F8F8',}}>
                            <TextInput type="number"
                                       name='secondClaimantArb2Score'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           secondClaimantArb2Score: e.target.value
                                       })}
                                       value={newScoring.secondClaimantArb2Score}
                                       label='Arbitrator 3 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                    </Grid>
                    <Typography style={{marginTop: '4rem'}}> <strong>Respondent: <TeamName team={selectedPairing.respondent} adminUse={true} /></strong> ({selectedPairing.respondent.jurisdiction})</Typography>
                    <Grid container style={{flexGrow: '1', padding: '0 0 0 20px',}} spacing={6}>
                        <Grid item xs={4} md={4}>
                            <Select name='firstRespondentId' required
                                    onChange={e => setNewScoring({...newScoring, firstRespondentId: e.target.value})}
                                    value={newScoring.firstRespondentId} label='Competitor'
                                    options={respondentMembers}/>
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <TextInput type="number"
                                       name='firstRespondentChairScore'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           firstRespondentChairScore: e.target.value
                                       })}
                                       value={newScoring.firstRespondentChairScore}
                                       label='Arbitrator 1 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <TextInput type="number"
                                       name='firstRespondentArb1Score'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           firstRespondentArb1Score: e.target.value
                                       })}
                                       value={newScoring.firstRespondentArb1Score}
                                       label='Arbitrator 2 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2}>
                            <TextInput name='firstRespondentArb2Score'
                                       required
                                       type='number'
                                       onChange={e => {
                                           setNewScoring({
                                               ...newScoring,
                                               firstRespondentArb2Score: e.target.value
                                           })
                                       }}
                                       value={newScoring.firstRespondentArb2Score}
                                       label='Arbitrator 3 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: '1', padding: '0 0 0 20px', }} spacing={6}>
                        <Grid item xs={4} md={4} style={{backgroundColor: '#F8F8F8',}}>
                            <Select name='secondRespondentId' required
                                    onChange={e => setNewScoring({...newScoring, secondRespondentId: e.target.value})}
                                    value={newScoring.secondRespondentId} label='Competitor'
                                    options={respondentMembers}/>
                        </Grid>
                        <Grid item xs={2} md={2} style={{backgroundColor: '#F8F8F8',}}>
                            <TextInput type="number"
                                       name='secondRespondentChairScore'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           secondRespondentChairScore: e.target.value
                                       })}
                                       value={newScoring.secondRespondentChairScore}
                                       label='Arbitrator 1 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2} style={{backgroundColor: '#F8F8F8',}}>
                            <TextInput type="number"
                                       name='secondRespondentArb1Score'
                                       required
                                       onChange={e => setNewScoring({
                                           ...newScoring,
                                           secondRespondentArb1Score: e.target.value
                                       })}
                                       value={newScoring.secondRespondentArb1Score}
                                       label='Arbitrator 2 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                        <Grid item xs={2} md={2} style={{backgroundColor: '#F8F8F8',}}>
                            <TextInput name='secondRespondentArb2Score'
                                       required
                                       type='number'
                                       onChange={e => {
                                           setNewScoring({
                                               ...newScoring,
                                               secondRespondentArb2Score: e.target.value
                                           })
                                       }}
                                       value={newScoring.secondRespondentArb2Score}
                                       label='Arbitrator 3 score'
                                       helperText="between 50 and 100"
                                       min="50"
                                       max="100"
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{flexGrow: '1', padding: '20px 0 0 0', }} spacing={6}>
                    <Grid item xs={12} md={12} style={{display:'flex', alignItems: 'center'}}>
                        <Button variant='contained'
                                type='submit'
                                color='primary'
                                disabled={isSaving}>Submit</Button>
                        {isSaving &&
                            <CircularProgress size={30} style={{marginLeft: '2rem'}}/>
                        }
                    </Grid>
                    </Grid>
                    {scoringSaveError &&
                        <Typography color='error'>{scoringSaveError}</Typography>
                    }
                    <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                        <Alert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
                            Scores saved
                        </Alert>
                    </Snackbar>
                </form>

            }
        </Container>
    )
}


ScorePairing.propTypes = {
    selectedPairing: PropTypes.object,
    getSelectedPairing: PropTypes.func,
}

export default connect(
    (state, ownProps) => ({
        selectedPairing: state.admin.selectedPairing,
        selectedPairingId: ownProps.match.params.pairingId,
        scoringSaveError: state.admin.scoringSaveError
    }), {
        getSelectedPairing: getSelectedPairing,
        saveScores: saveScores
    })(ScorePairing)

