import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const SubHeading = ({ children }) => (
  <Typography style={{ margin: '1rem 0' }}>{children}</Typography>
)


const TeamAcceptedForReview = () => {
  return (
      <Container style={{ paddingBottom: '10rem', paddingTop: '4rem' }}>
        <Typography variant='h4' component='h1'>
          Your registration has been submitted.
        </Typography>
        <SubHeading>
        Thank you for submitting an application to take part in the 20th Vis East Competition. If your application is complete, the Vis East Administration will review your Application and revert back to you shortly with respect to whether your Application was successful. If you remitted your non-refundable deposit via bank transfer, please ensure that you have included your Team Name in the reference. For speedier processing, we would suggest that you provide proof of bank transfer to the Vis East Administration by emailing: <a href="mailto:info@cisgmoot.org">info@cisgmoot.org</a>.
        </SubHeading>       
      </Container>
  )
}

export default TeamAcceptedForReview
