/**
 *
 * @param {string} [type] - A string that represents the ActionType
 * @param {object} [payload] - an object that represents the payload for the action
 * @param {object} [error] - If the action is an error then an object that contains the error. The existence of a non empty error
 * property indicates the action is an error
 * @param {object} [meta] An object that contains additional data about the action that doesn't make sense in the payload
 * or error fields
 * @returns {{payload: *, meta: *, type: *, error: *}}
 */
export default function makeAction(type, payload, error, meta) {
    return {
        type,
        payload,
        error,
        meta
    }
}
