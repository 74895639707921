export const GET_ALL_MOOTS_REQUESTED = 'GET_ALL_MOOTS_REQUESTED'
export const GET_ALL_MOOTS_SUCCEEDED = 'GET_ALL_MOOTS_SUCCEEDED'
export const GET_ALL_MOOTS_FAILED = 'GET_ALL_MOOTS_FAILED'

export const SELECT_MOOT = 'SELECT_MOOT';

export const SAVE_MOOT_REQUESTED = 'SAVE_MOOT_REQUESTED'
export const SAVE_MOOT_SUCCEEDED = 'SAVE_MOOT_SUCCEEDED'
export const SAVE_MOOT_FAILED = 'SAVE_MOOT_FAILED'


export const ACTIVATE_MOOT_REQUESTED = 'ACTIVATE_MOOT_REQUESTED'
export const ACTIVATE_MOOT_SUCCEEDED = 'ACTIVATE_MOOT_SUCCEEDED'
export const ACTIVATE_MOOT_FAILED = 'ACTIVATE_MOOT_FAILED'
