import {useStripe, useElements, PaymentElement, AddressElement} from '@stripe/react-stripe-js';
import {Button} from "@material-ui/core";
import _ from 'lodash/fp';

const CheckoutForm = ({onProcessStripePayment = _.noop} ) => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            redirect: "if_required"
        });

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            onProcessStripePayment()
        }
    };

    return (
        <div>
            <PaymentElement />
            <AddressElement options={{mode: 'billing'}} />
            <Button onClick={handleSubmit} color="primary" size="large" style={{marginTop: "1rem"}} variant="contained" disabled={!stripe}>Pay Now</Button>
        </div>
    )
};

export default CheckoutForm;