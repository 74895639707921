import {isEmpty, noop} from "lodash/fp";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, CircularProgress, Snackbar} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@mui/material";
import {connect} from "react-redux";
import * as actions from "../../actions/arbitratorActions";
import {saveArbitratorRegistrationData} from "../../actions/registrationActions";
import ArbitratorAvailability from "./Availability";
import Container from '@material-ui/core/Container'

const AvailabilityForm = ({   arbitratorAvailabilitySaveError = '',
                              saveAvailability = noop,
                              saveArbitratorRegistrationData = noop,
}) => {

    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [saved, setSaved] = useState(false)
    const [hasOralAvailabilityError,setHasOralAvailabilityError] = useState(false)
    const [hasWrittenAvailabilityError,setHasWrittenAvailabilityError] = useState(false)
    const [arbitratorData, setArbitratorData] = useState()

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    }

    const onSave = async (e) => {
        try {
            e.preventDefault()
            setIsSaving(true)
            const didSave = await saveAvailability()
            await saveArbitratorRegistrationData(arbitratorData)

            if (didSave) {
                setSaved(true)
            }
        } finally {
            setIsSaving(false)
        }
    }
    return (
        <Container style={{paddingBottom: '1rem', padding:'0'}}>
        <form onSubmit={onSave}>
            <ArbitratorAvailability onOralAvailabilityError={hasError => setHasOralAvailabilityError(hasError) }
                                    onWrittenAvailabilityError={hasError => setHasWrittenAvailabilityError(hasError) }
                                    onArbitratorDataChange={newValue => setArbitratorData({
                                        ...arbitratorData,
                                        ...newValue
                                    })}
                                    onIsLoading={newValue => setIsLoading(newValue)}
            />
            <Grid item xs={12} style={{margin: '2rem 0', display:'flex'}}>
                { !isLoading &&
                    <Button variant='contained'
                            type='submit'
                            color='primary'
                            disabled={ isSaving || hasWrittenAvailabilityError || hasOralAvailabilityError}
                    >Save</Button>
                }
                {isSaving &&
                    <CircularProgress style={{marginLeft: '1rem'}} size={30}/>
                }
                {arbitratorAvailabilitySaveError &&
                    <Typography color='error'>{arbitratorAvailabilitySaveError}</Typography>
                }

                <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000}>
                    <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                        Availability & Conflicts saved
                    </Alert>
                </Snackbar>
            </Grid>
        </form>
        </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        arbitratorAvailabilitySaveError: state.arbitrator.arbitratorAvailabilitySaveError,
    }),
    {
        saveAvailability: actions.saveAvailability,
        saveArbitratorRegistrationData: saveArbitratorRegistrationData,
    }
)(AvailabilityForm)