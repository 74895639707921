import React, {useState} from 'react'
import {DataGridPro, GridToolbarContainer, GridToolbarExport, LicenseInfo} from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {noop, head, map, get, sumBy, filter, includes} from "lodash/fp";
import {Snackbar, Tooltip, Typography} from "@material-ui/core";
import {Check, DoNotDisturb} from "@mui/icons-material";
import * as actions from '../../../../actions/adminActions'
import './score.css'
import {Alert, Button} from "@mui/material";
import {getNextRoundTeamSizeMax, getNextRoundTypeToSelect} from "../../../../reducers/adminSelectors";
import {format} from "date-fns";
import {ADMIN} from "../../../../consts/roles";
import TeamName from "../../../team/TeamName";


LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const GeneralTeamResults = ({ selectedRoundTeamResults = [],
                                selectedRoundType = '',
                                saveWinnerError = '',
                                pairRoundError = '',
                                getNextRoundTypeToSelect = noop,
                                saveRoundWinners = noop,
                                pairRound = noop,
                                getNextRoundTeamSizeMax = noop,
                                user={},
                                setTab = noop}) => {
    const [busy, setBusy] = React.useState(false)
    const [saved, setSaved] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const setAsTop32 = async (teamId, isTop32) => {
        try {
            setBusy(true)
            await saveRoundWinners(teamId, {roundType: 'Elimination Rounds: Round of 32', winner: isTop32})
            setSaved(true)
        }finally {
            setBusy(false)
        }
    }

    const onPairRound = async roundType => {
        try{
            setBusy(true)

            const scores = map(w => {
                const totalScore = sumBy(s => s.isPractice ? 0 : s.score,w.scores)
                return {
                    teamId: w.id,
                    totalScore
                }
            },filter(s => s.roundWinners?.roundOf32,selectedRoundTeamResults))

            await pairRound(roundType,scores)
        }finally{
            setBusy(false)
        }
    }
    const teamsAdded = () => {
        return sumBy(s => s.roundWinners?.roundOf32 === true ? 1 : 0 ,selectedRoundTeamResults)
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport
                    className="toolbar-button"
                    printOptions={{ disableToolbarButton: true }}
                    csvOptions={{
                        fileName: `Team Results - ${format(new Date(),'yyyy-MM-dd')}`,
                        utf8WithBom: true,
                    }}
                />
                <Typography style={{fontSize:'11px', color:'#808080', margin:'0'}}>(Export DOES NOT indicate scores that are practice rounds)</Typography>
            </GridToolbarContainer>
        );
    }


    const nextRoundType = getNextRoundTypeToSelect(selectedRoundType)
    const nextRoundTeamSize = getNextRoundTeamSizeMax(selectedRoundType)
    const roundFull = teamsAdded() === nextRoundTeamSize
    const roundAlreadyPaired = get('roundAlreadyPaired',head(selectedRoundTeamResults))
    const columns = [
        {
            field: 'team',
            headerName: 'School',
            flex: 1,
            renderCell: params => <TeamName team={params.row.team} adminUse={true} />,
            valueGetter: params => (params.row.team.hideTeamName === true ? `TEAM ID ${params.row.team.id}` : params.row.team.school)
        },
        {
            field: 'jurisdiction',
            headerName: 'Jurisdiction',
            width: 140,
        },
        {
            field: 'arg1Score',
            headerName: 'Argument 1',
            width: 90,
            align: 'right',
            renderCell: params => (params.row.scores[0]?.isPractice ? <span className='practice-score'>{params.row.scores[0]?.score}</span> : <span>{params.row.scores[0]?.score}</span>),
            valueGetter: params => (params.row.scores[0]?.score)
        },
        {
            field: 'arg2Score',
            headerName: 'Argument 2',
            width: 90,
            align: 'right',
            renderCell: params => (params.row.scores[1]?.isPractice ? <span className='practice-score'>{params.row.scores[1]?.score}</span> : <span>{params.row.scores[1]?.score}</span>),
            valueGetter: params => (params.row.scores[1]?.score)
        },
        {
            field: 'arg3Score',
            headerName: 'Argument 3',
            width: 90,
            align: 'right',
            renderCell: params => (params.row.scores[2]?.isPractice ? <span className='practice-score'>{params.row.scores[2]?.score}</span> : <span>{params.row.scores[2]?.score}</span>),
            valueGetter: params => (params.row.scores[2]?.score)
        },
        {
            field: 'arg4Score',
            headerName: 'Argument 4',            
            width: 90,
            align: 'right',
            renderCell: params => (params.row.scores[3]?.isPractice ? <span className='practice-score'>{params.row.scores[3]?.score}</span> : <span>{params.row.scores[3]?.score}</span>),
            valueGetter: params => (params.row.scores[3]?.score)
        },
        {
            field: 'arg5Score',
            headerName: 'Argument 5',
            width: 90,
            align: 'right',
            renderCell: params => (params.row.scores[4]?.isPractice ? <span className='practice-score'>{params.row.scores[4]?.score}</span> : <span>{params.row.scores[4]?.score}</span>),
            valueGetter: params => (params.row.scores[4]?.score)
        },
        {
            field: 'total',
            headerName: 'Total Score',
            width: 110,
            align: 'right',
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            width: 110,
            getActions: params => [
                <GridActionsCellItem label="Top 32?"
                                     onClick={() => setAsTop32(params.row.id,true)}
                                     icon={
                                         <Tooltip title='Add to top 32'>
                                             <Check htmlColor={!params.row.roundWinners?.roundOf32 && teamsAdded() < nextRoundTeamSize ? 'green' : ''}/>
                                         </Tooltip>
                                     }
                                     disabled={params.row.roundWinners?.roundOf32 === true || teamsAdded() >= nextRoundTeamSize || !includes(ADMIN,user.roles) } />,
                <GridActionsCellItem
                    icon={<Tooltip title='Remove from top 32'><DoNotDisturb
                        htmlColor={params.row.roundWinners?.roundOf32 === true ? 'red' : ''}/></Tooltip>}
                    title='Deny'
                    disabled={!params.row.roundWinners?.roundOf32 || !includes(ADMIN,user.roles)}
                    onClick={() => setAsTop32(params.row.id, false)}
                    label="Deny"/>
            ]
        },
    ]
    return (
        <div style={{height: 700, width: '100%'}}>
            <DataGridPro
                loading={busy}
                rows={selectedRoundTeamResults}
                columns={columns}
                disableSelectionOnClick
                initialState={{
                    sorting: {
                      sortModel: [{ field: 'team', sort: 'asc' }],
                    },
                  }}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
            <Tooltip title={ roundAlreadyPaired ? "Round already Paired": "Generate draft pairings"}>
                <span>
                    <Button variant='contained'
                        disabled={!roundFull || busy || !includes(ADMIN,user.roles) || roundAlreadyPaired}
                        onClick={e => onPairRound('Elimination Rounds: Round of 32')}
                        style={{marginTop:'2rem'}}>Pair {nextRoundType}
                    </Button>
                </span>
            </Tooltip>
            {pairRoundError &&
                <Typography color='error'>{pairRoundError}</Typography>
            }

            <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000} >
                <>
                    { saveWinnerError !== '' &&
                        <Alert onClose={handleClose} severity="error" sx={{width: '100%'}}>
                            {saveWinnerError}
                        </Alert>
                    }
                    {saveWinnerError === '' &&
                        <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                            {roundFull && `Round updated. Round full`
                            }
                            {!roundFull && `Round updated. ${teamsAdded()} teams added so far`}
                        </Alert>
                    }
                </>
            </Snackbar>
        </div>
    )
}


GeneralTeamResults.propTypes = {
    selectedRoundTeamResults: PropTypes.arrayOf(PropTypes.object),
    selectedRoundType: PropTypes.string,
    getNextRoundTeamSizeMax: PropTypes.func,
    pairRound: PropTypes.func,
    saveRoundWinners: PropTypes.func,
    setTab : PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        selectedRoundTeamResults : state.admin.selectedRoundTeamResults,
        selectedRoundType : state.admin.selectedRoundType,
        setTab: ownProps.setTab,
        user: state.user,
        saveWinnerError : state.admin.saveWinnerError,
        pairRoundError : state.admin.pairRoundError
    }), {
        saveRoundWinners: actions.saveRoundWinners,
        getNextRoundTeamSizeMax: getNextRoundTeamSizeMax,
        getNextRoundTypeToSelect: getNextRoundTypeToSelect,
        pairRound : actions.pairRound
    })(GeneralTeamResults)
