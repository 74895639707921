import {isEmpty, map, find, indexOf} from "lodash/fp";

export const mapWithIndex = map.convert({ cap: false })

// :: a => bool
export const isNotEmpty = x => !isEmpty(x)

export const replaceNull = (someObj, replaceValue = "") => {
    const replacer = (key, value) =>
        String(value) === "null" || String(value) === "undefined" ? replaceValue : value;
    return JSON.parse( JSON.stringify(someObj, replacer));
}

export const upsert = function (findPred, newval, arr) {
    const newArr = [...arr]
    const match = find(findPred,newArr);
    if(match){
        const index = indexOf(find(findPred,newArr),newArr);
        newArr.splice(index, 1, newval);
    } else {
        newArr.push(newval);
    }
    return newArr
};