import * as actionTypes from '../actions/actionTypes/teamActionTypes'
import {combineReducers} from "redux";
import {filter} from "lodash/fp";
import * as regActionTypes from "../actions/actionTypes/registrationActionTypes";


const existingClarifications = (state =[], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_TEAM_CLARIFICATIONS_SUCCEEDED) {
        return payload
    }
    if (type === actionTypes.SAVE_TEAM_CLARIFICATION_SUCCEEDED) {
        return [ ...state, payload ]
    }else {
        return state
    }
}

const clarificationSaveError = (state ='',action) => {
    const {type, error } = action
    if (type === actionTypes.SAVE_TEAM_CLARIFICATION_FAILED) {
        if (error?.message === 'Closed'){
            return 'Unable to save clarification. Submissions are closed'
        }else {
            return 'Unable to save clarification. Please try again'
        }
    }
    if (type === actionTypes.SAVE_TEAM_CLARIFICATION_SUCCEEDED) {
            return ''
    } else {
        return state
    }
}


const selectedTeam = (state = {}, action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_TEAM_SUCCEEDED) {
        return payload
    } else if (type === actionTypes.SAVE_TEAM_MEMBER_SUCCEEDED) {
        return {...state, members: [...filter(m => m.id !== payload.id, state.members), payload]}
    } else if (type === actionTypes.DELETE_TEAM_MEMBER_SUCCEEDED) {
        return {...state, members: filter(m => m.id !== payload.id, state.members)}
    } else if (type === actionTypes.UPLOAD_TEAM_DOCUMENT_SUCCEEDED){
        const docsWithoutNewOne = filter(doc => doc.id !== payload.id, state.TeamDocuments)
        return {...state, TeamDocuments: [...docsWithoutNewOne, payload ] }
    }
    else if (type === regActionTypes.SAVE_TEAM_REGISTRATION_DATA_SUCCEEDED){
        return {...state, ...payload }
    }
    else {
        return state
    }
}

const teamMemberSaveError = (state ='',action) => {
    const {type,error} = action
    if (type === actionTypes.SAVE_TEAM_MEMBER_FAILED) {
        if (error?.message === 'Closed') {
            return 'Unable to save team member. Submissions are closed'
        } else if (error?.message === 'ExistingTeamOwner'){
            return 'The email address is not unique within the current moot. Please provide a unique email address to continue.'
        } else if (error?.message === 'ExistingTeamMember'){
            return `The email address is not unique within the current moot. Please provide a unique email address to continue.`
        }else if (error?.message === 'ExistingArbitrator') {
            return `Existing Arbitrators cannot not be invited as students`
        }else {
            return `Unable to save team member. Please try again`
        }
    }
    if (type === actionTypes.SAVE_TEAM_MEMBER_SUCCEEDED) {
        return ''
    } else {
        return state
    }
}

const uploadDocumentError = (state ='',action) => {
    const {type, payload,error} = action
    if (type === actionTypes.UPLOAD_TEAM_DOCUMENT_FAILED) {
        //TODO How much more info should we give?
        if (error?.message === 'Closed'){
            return 'Unable to save document. Submissions are closed'
        }else {
            return 'Unable to save document. Please try again.'
        }
    }
    if (type === actionTypes.UPLOAD_TEAM_DOCUMENT_SUCCEEDED) {
        return ''
    } else {
        return state
    }
}

const selectedTeamsPairings = (state = [], action) => {
    const {type,payload} = action
    if (type === actionTypes.GET_SELECTED_TEAMS_PAIRINGS_SUCCEEDED){
        return payload
    }else{
        return state
    }
}
const paymentHistory = (state=[],action) => {
    const {type,payload} = action
    if (type === actionTypes.GET_TEAM_PAYMENT_HISTORY_SUCCEEDED){
        return payload
    }else{
        return state
    }
}


const inviteLink = (state="",action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_INVITE_LINK_SUCCEEDED) {
        return payload
    }else if (type === actionTypes.GET_INVITE_LINK_REQUESTED) {
        return ""
    }else {
        return state
    }
}

const inviteLinkErrors = (state=[],action) => {
    const {type, payload} = action
    switch (type){
        case actionTypes.GET_INVITE_LINK_FAILED :
        case actionTypes.SEND_INVITE_LINK_FAILED:
        return "Error"
        case actionTypes.GET_INVITE_LINK_REQUESTED :
        case actionTypes.SEND_INVITE_LINK_REQUESTED :
            return []
        default:
            return state
    }

}

export default combineReducers({
    existingClarifications,
    clarificationSaveError,
    selectedTeam,
    teamMemberSaveError,
    uploadDocumentError,
    selectedTeamsPairings,
    paymentHistory,
    inviteLink,
    inviteLinkErrors
})
