import React, {useCallback, useState} from 'react';
import Cropper from 'react-easy-crop';
import {Button, Slider} from "@mui/material";
import { getCroppedImgCanvas } from "./cropImage";
import {Tooltip, Typography} from "@material-ui/core";
import {noop} from "lodash/fp";
import Rotate90DegreesCwIcon from '@mui/icons-material/Rotate90DegreesCw';
import CropIcon from '@mui/icons-material/Crop';

const ImageResize = ({existingImage = null, onImageCropped = noop}) => {

    const inputRef = React.useRef();
    const [src, setSrc] = useState('')
    const [croppedArea, setCroppedArea] = React.useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [rotation,setRotation] = useState(0)
    const [croppedImage,setCroppedImage] = useState()
    const triggerFileSelectPopup = () => inputRef.current.click();

    const onFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                    setSrc(reader.result)
                    setCroppedImage(null)
                    setZoom(1)
                    setCrop({x: 0, y: 0})
                    setRotation(0)
                }
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels);
    }, [])



    const saveCrop = async () => {
        const croppedImageCanvas = await getCroppedImgCanvas(src,croppedArea, rotation)
        const croppedImageBlob  = await new Promise((resolve, reject) => {
            croppedImageCanvas.toBlob((file) => {
                resolve(URL.createObjectURL(file))
            }, 'image/jpeg')
        })
        const croppedImageData = croppedImageCanvas.toDataURL('image/jpg')
        setCroppedImage(croppedImageBlob)
        setSrc(null)
        onImageCropped(croppedImageData)
    };

    return (
        <div style={{marginBottom: '2rem'}}>
            <div style={{maxWidth: '450px'}}>
                {(croppedImage  || existingImage) && !src &&
                    <img alt="Avatar" style={{height: '300px', width: '100%', objectFit: 'contain', objectPosition:'left'}} src={croppedImage ? croppedImage : existingImage}
                        onError={event => {
                            event.target.src = "/avatar.jpg"
                            event.onerror = null
                        }}/>
                }
                {!croppedImage  && !existingImage && !src &&
                    <img alt="Avatar" style={{height: '300px', width: '100%', objectFit: 'contain', objectPosition:'left'}} src='/avatar.jpg'/>
                }
                <div style={{height: '90%', padding: '10px'}}>
                {src &&
                    <>
                        <div style={{position: 'relative', height: '400px', maxWidth: '450px'}}>
                            <Cropper
                                image={src}
                                crop={crop}
                                cropShape="rect"
                                zoom={zoom}
                                rotation={rotation}
                                aspect={1}
                                onRotationChange={setRotation}
                                onZoomChange={setZoom}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        {src && !croppedImage &&
                            <Typography variant='body2'>Drag, zoom, and/or rotate to position your photo within the crop area. Press "CROP" when finish.</Typography>
                        }
                        <div style={{height: '10%', display: 'flex', alignItems: 'center', margin: 'auto', width: '100%'}}>
                            <Typography style={{marginRight: '1rem'}}  variant='caption'>Zoom</Typography>
                            <Slider
                                min={1}
                                max={3}
                                step={0.1}
                                value={zoom}
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                            <Button style={{marginLeft: '2rem', padding:'6px 25px'}} variant='text' onClick={() => {
                                setRotation(rotation + 90)
                            }} ><Rotate90DegreesCwIcon fontSize="small" style={{marginRight: '8px'}}/> ROTATE</Button>

                        </div>
                    </>
                }
                    <div>
                        <input
                            type='file'
                            accept='image/*'
                            ref={inputRef}
                            onChange={onFileChange}
                            style={{ display: "none" }}
                        />
                        <Tooltip title="300x300 is the ideal size">
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={triggerFileSelectPopup}
                            style={{ marginRight: "10px" }}>
                            Choose image
                        </Button>
                        </Tooltip>
                        {src && !croppedImage &&
                            <Button variant='contained' color='secondary' onClick={saveCrop}>
                                <CropIcon fontSize="small" style={{marginRight: '8px'}}/> Crop
                            </Button>
                        }
                    </div>
                </div>
            </div>        
        </div>
    )
}

export default ImageResize;