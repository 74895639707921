import React from 'react'
import {DataGridPro, LicenseInfo, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid-pro';
import {noop} from 'lodash/fp';
import {connect} from "react-redux";
import {getAllClarifications} from "../../../reducers/adminSelectors";
import PropTypes from "prop-types";
import {clarificationGridColumnDefinition} from "../../helpers";
import {format} from "date-fns";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);

const ClarificationsTable = ({ clarifications  = [], getClarifications = noop}) => {

  const [isFetching, setFetching] = React.useState(false)

  React.useEffect(() => {
    const asyncFetchData = async () => {
      try {
        setFetching(true)
        await getClarifications()
        setFetching(false)
      }catch(e){
        //TODO ?
      }
    }
    asyncFetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
              <GridToolbarExport
                  className="toolbar-button"
                  printOptions={{ disableToolbarButton: true }}
                  csvOptions={{
                      fileName: `Clarifications - ${format(new Date(),'yyyy-MM-dd')}`,
                      utf8WithBom: true,
                  }}                  
            />               
            </GridToolbarContainer>
        );
    }
    return (
        <div style={{height: 700, width: '100%'}}>
          <DataGridPro
              loading={isFetching}
              rows={clarifications}
              columns={clarificationGridColumnDefinition(true)}
              disableSelectionOnClick
              components={{
                  Toolbar: CustomToolbar,
              }}
          />
        </div>
    )
  }



ClarificationsTable.propTypes = {
  clarifications: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string,
    clarificationSought: PropTypes.string,
    rationale: PropTypes.string,
    createdAt: PropTypes.string
  })),
  getClarifications: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
      clarifications: state.admin.clarifications
    }),{
      getClarifications: getAllClarifications
    })( ClarificationsTable)