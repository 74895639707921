const getRoundWinnerFieldFromRoundType = roundType => {
    switch(roundType){
        case 'Elimination Rounds: Round of 32':
            return 'roundOf32'
        case 'Elimination Rounds: Round of 16':
            return 'roundOf16'
        case 'Elimination Rounds: Quarter-Finals':
            return 'quarterfinals'
        case 'Elimination Rounds: Semi-Finals':
            return 'semifinals'
        case 'Finals':
            return 'final'
        case 'Champion':
            return 'champion'
        default:
            return ''
    }
}

export{
    getRoundWinnerFieldFromRoundType
}