import {compact, includes, map} from "lodash/fp";

export const hasConflict = (claimant,respondent,arbitrator) => {
    const conflictingJurisdictionNames = map(j => j.name ,arbitrator?.conflictingJurisdictions)
    const affiliatedSchoolNames = map(j => j.name ,arbitrator?.affiliatedSchools)

    if (!claimant || !respondent || !arbitrator) return false


    const result = includes(claimant.jurisdiction,conflictingJurisdictionNames) ||
        includes(respondent.jurisdiction,conflictingJurisdictionNames) ||
        includes(claimant.school,affiliatedSchoolNames) ||
        includes(respondent.school,affiliatedSchoolNames) ||
        arbitrator?.participation === 'I am no longer able to act as an arbitrator' ||
        arbitrator?.isOverRoundCount === true ||
        arbitrator.isAlreadyPaired === true ||
        arbitrator.isAvailable === false

    return result
}

export const buildArbitratorOptionList = (claimant,respondent,allArbitrators) => {
    const arbs = compact(map(a=> {
        if (a?.status === 'accepted' && a?.participation !== 'I am no longer able to act as an arbitrator') {
            return {id: a.id, name: `${a.firstName} ${a.lastName}`, className: hasConflict(claimant,respondent,a) ? 'arbitrator-conflict': ''}
        }
        return null
    },allArbitrators))

    return [{id: -1, name: "--Select Arbitrator--"},...arbs]
}

export const getArbitratorConflictGridCell = (claimant,respondent,arbitrator) => {
    return (
        <span className={hasConflict(claimant, respondent,arbitrator) ? 'arbitrator-conflict' : ''}>{arbitrator?.firstName ?? ''} {arbitrator?.lastName ?? ''}</span>
    )
}