import React from 'react'
import {DataGridPro, LicenseInfo, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import {join, noop, filter, size, map} from 'lodash/fp';
import {connect} from "react-redux";
import {getAllJuryMemoRankings} from "../../../reducers/adminSelectors";
import { exportAllMemoRankings, exportArbitratorMissingRankings} from '../../../actions/adminActions'
import PropTypes from "prop-types";
import {format} from "date-fns";
import { TEAM_ROLE_TYPE } from "../../../utils/constants";
import {Box, Button, Typography} from "@material-ui/core";
import {SaveAlt} from "@material-ui/icons";
import {clientsideDownloadFile} from "../../helpers";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);



const MemoJuryRankingsTable = ({ role  = TEAM_ROLE_TYPE.CLAIMANT,
                               juryClaimantRankings = [],
                               juryRespondentRankings = [],
                               getAllJuryMemoRankings = noop,
                               exportArbitratorMissingRankings = noop}) => {

    const [isFetching, setFetching] = React.useState(false)

    const rankings = role === TEAM_ROLE_TYPE.CLAIMANT ? juryClaimantRankings : juryRespondentRankings;

    const onExportArbitratorMissingRankings = async () => {
        setFetching(true)
        const data = await exportArbitratorMissingRankings(role,true)
        const blob = new Blob([join('\n',data)], {
            type: 'text/comma-separated-values',
        });

        const fullName = `Arbitrators missing ${role} Jury rankings - ${format(new Date(),'yyyy-MM-dd')}.csv`;

        clientsideDownloadFile(blob,fullName)
        setFetching(false)
    }
    const CustomToolbar = () => {
        return (
          <GridToolbarContainer>
            <GridToolbarColumnsButton className="toolbar-button" />
            <GridToolbarFilterButton className="toolbar-button" />
            <GridToolbarDensitySelector className="toolbar-button" />
            <GridToolbarExport
            className="toolbar-button" 
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
                fileName: `JURY ${role} RANKINGS - ${format(new Date(),'yyyy-MM-dd')}`,
                utf8WithBom: true,
              }}
            />

          <Button color="primary"
                  size="small"
                  startIcon={<SaveAlt />}
                  aria-haspopup="menu"
                  onClick={onExportArbitratorMissingRankings}
          >Export arbitrators yet to rank</Button>
          </GridToolbarContainer>
        );
    }
    
    const rankingGridColumnDefinition = () => {
        return [
            {
                field: 'id',
                headerName: "Id",
                flex : 1,
                hide: true
            },
            {
                field: 'school',
                headerName: 'School',
                flex: 1
            },
            {
                field: 'teamAvgRank',
                headerName: 'Avg Rank',
                flex: 1,
                valueGetter: params => Number(params.row.teamAvgRank)
            },
            {
                field: 'rankCount',
                headerName: 'Rank Count',
                flex: 1,
                valueGetter: params => size(params.row.teamRankings)

            },
            {
                field: 'teamRankings',
                headerName: 'Ranking Values',
                flex: 1,
                valueGetter: params => join(',',map(r => r.ranking,params.row.teamRankings))

            },
            {
                field: 'contact',
                headerName: 'Contact',
                flex: 1,
                hide: true
            },
            {
                 field: 'email',
                 headerName: 'Email',
                 flex: 1,
                hide: true
             }
        ]
    }

  React.useEffect(() => {
    const asyncFetchData = async () => {
      try {
        setFetching(true)
        await getAllJuryMemoRankings(role)
        setFetching(false)
      }catch(e){
        //TODO ?
      }
    }
    asyncFetchData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
   
    return (
        <div style={{height: 700, width: '100%'}}>
          <DataGridPro
              loading={isFetching}
              rows={filter(r => r.role === role,rankings)}
              columns={rankingGridColumnDefinition(true)}
              disableSelectionOnClick
              getDetailPanelContent={({row}) => {
                  return <Box sx={{ padding: '1rem 2rem', backgroundColor:'#F0F0F0' }}>
                      <Typography variant="h6" gutterBottom component="div" style={{fontSize: '16px'}}>
                          Submitted Rankings
                      </Typography>
                      <Table size="small" aria-label="purchases">
                          <TableHead>
                              <TableRow>
                                  <TableCell>Rank</TableCell>
                                  <TableCell>Arbitrator</TableCell>
                                  <TableCell>Arbitrator's Email</TableCell>
                              </TableRow>
                          </TableHead>
                          <TableBody>
                              {map(ranking => {
                                  return (
                                      <TableRow key={ranking.id}>
                                          <TableCell component="th" scope="row">
                                              {ranking.ranking}
                                          </TableCell>
                                          <TableCell>{ranking.arbitrator}</TableCell>
                                          <TableCell><a href={`mailto:${ranking.arbitratorEmail}`}>{ranking.arbitratorEmail}</a></TableCell>
                                      </TableRow>
                                  )
                              }, row.teamRankings)}
                          </TableBody>
                      </Table>
                  </Box>
              }}
              getDetailPanelHeight={({row}) => "auto"}
              components={{
                  Toolbar: CustomToolbar,
              }}
          />
        </div>
    )
  }

MemoJuryRankingsTable.propTypes = {
    getAllJuryMemoRankings: PropTypes.func,
    exportAllMemoRankings: PropTypes.func,
    exportArbitratorMissingRankings: PropTypes.func
}

export default connect(
    (state, ownProps) => ({
        juryClaimantRankings: state.admin.juryClaimantRankings,
        juryRespondentRankings: state.admin.juryRespondentRankings,
      ...ownProps
    }),{
      getAllJuryMemoRankings: getAllJuryMemoRankings,
      exportAllMemoRankings: exportAllMemoRankings,
      exportArbitratorMissingRankings: exportArbitratorMissingRankings
    })( MemoJuryRankingsTable)