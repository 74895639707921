import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import {noop} from "lodash/fp";

const useStyles = makeStyles(theme => ({
  input: {
    width: '100%',
  },
  helperText: {
    minHeight: '19px',
  },
}))

const TextInput = ({
  id,
  label,
  value,
  onChange,
  onBlur = noop,
  type = 'text',
  helperText = ' ',
  name = '',
  notRequired,
  maxLength = Number.MAX_SAFE_INTEGER,
  autocomplete,
  multiline,
  minRows,
  min,
  max,
  pattern,
  inputMode,
  error,
    disabled,
}) => {
  const classes = useStyles()

  let helperTextFormatted = helperText
  if (maxLength !== Number.MAX_SAFE_INTEGER){
    if (value) {
      const remainingChars = `${value.length}/${maxLength}`
      helperTextFormatted = `${helperText} ${remainingChars}`
    }
  }

  pattern = !notRequired ? "^[\\w\\d\\(\\)\\*\\.].*" : null

  return (
    <TextField
      required={!notRequired}
      type={type}
      className={classes.input}
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      autoComplete={autocomplete}
      helperText={helperTextFormatted}
      FormHelperTextProps={{ className: classes.helperText }}
      multiline={multiline}
      disabled={disabled}
      inputProps={{
        maxLength,
        min,
        max,
        pattern,
        inputMode
      }}
      minRows={minRows}
      error={error}
    />
  )
}

export default TextInput
