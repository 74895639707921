import * as actions from '../actions/globalActions'
import { isEmpty, get } from "lodash/fp";

export const getAllSchools = () => async (dispatch,getState) => {
    const schools = get('global.schools', getState())
    if (isEmpty(schools)) {
        await (actions.getGlobals())(dispatch, getState)
    }
}

export const getAllJurisdictions = () => async (dispatch,getState) => {
    const jurisdictions = get('global.jurisdictions', getState())
    if (isEmpty(jurisdictions)) {
        await (actions.getGlobals())(dispatch, getState)
    }
}

export const getClarificationCategories = () => async (dispatch,getState) => {
    const existing = get('global.clarificationCategories',getState())
    if (isEmpty(existing)){
        await (actions.getGlobals())(dispatch,getState)
    }
}

