import * as actions from '../actions/arbitratorActions'
import { isEmpty, get, filter } from "lodash/fp";
import {TEAM_ROLE_TYPE} from "../utils/constants";

export const getSelectedArbitrator = () => async (dispatch,getState) => {
    let selectedArbitrator = get('arbitrator.selectedArbitrator',getState())
    if (isEmpty(selectedArbitrator.email)){
        await (actions.getArbitrator())(dispatch,getState)
        selectedArbitrator = get('arbitrator.selectedArbitrator',getState())
    }
    return selectedArbitrator
}


export const getSelectedArbitratorRankings = roleType => async (dispatch,getState) => {
    return filter(r => r.roleType === roleType ,get('arbitrator.selectedArbitrator.ArbitratorRankings',getState()))
}


export const getArbitratorClaimantPairings = () => async (dispatch, getState) => {
    const pairings = get('arbitrator.claimantPairings',getState())
    if (isEmpty(pairings)){
        await (actions.getArbitratorClaimantPairings())(dispatch,getState)
    }
}

export const getJuryTeams = role => async (dispatch, getState) => {
    let teams = []
    if (role === TEAM_ROLE_TYPE.CLAIMANT){
        teams = get('arbitrator.claimantJuryTeams',getState())
    }else if (role === TEAM_ROLE_TYPE.RESPONDENT){
        teams = get('arbitrator.respondentJuryTeams',getState())
    }
    if (isEmpty(teams)){
        await (actions.getJuryTeams(role))(dispatch,getState)
    }
}


export const getArbitratorRespondentPairings = () => async (dispatch, getState) => {
    const pairings = get('arbitrator.respondentPairings',getState())
    if (isEmpty(pairings)){
        await (actions.getArbitratorRespondentPairings())(dispatch,getState)
    }
}

export const getArbitrationDateTimes = () =>async (dispatch,getState) => {
    const dateTimes = get('arbitrator.arbitrationDateTimes',getState())
    if (isEmpty(dateTimes)){
        await (actions.getArbitrationDateTimes())(dispatch,getState)
    }
}



export const getArbitratorOralArgumentSchedule = () => async (dispatch, getState) => {
    const existing = get('arbitrator.oralArgumentSchedule',getState())
    if (isEmpty(existing)){
        await (actions.getArbitratorOralArgumentSchedule())(dispatch,getState)
    }
}