import { Box, Typography } from '@material-ui/core'
import {connect} from "react-redux";
import MainMenu from './MainMenu'
import './Header.css'
const Header = () => {

  return (
    <Box className='header'>
      <Box className="branding">
        <img
          style={{ width: 'auto' }}
          className='logo'
          src='/logos/East-Vis-Moot-Logo@2x.png'
          alt='logo'
        />
        <Typography style={{ color: '#fff' }}>
          Vis East Moot Foundation Ltd.
        </Typography>
      </Box>
      <Box className="menu" style={{minWidth:'86px'}}>

        <a style={{ textDecoration: 'none', color:'#fff', fontSize: '0.75rem' }} href='https://www.cisgmoot.org/'>
                  cisgmoot.org
        </a>      
        <MainMenu/>
      </Box>
            
    </Box>
  )
}


export default connect(
    (state, ownProps) => ({
      router: state.router,
        user: state.user
    })
)(Header)