import {Button, CircularProgress, FormControlLabel, Grid, Link, Typography} from "@material-ui/core";
import TextField from "../common/TextInput";
import {Autocomplete, TextField as MUITextField} from "@mui/material";
import {PrintOutlined} from "@material-ui/icons";
import downloadInvoice from "../team/downloadInvoice";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {getSelectedTeam, getTeamPaymentHistory} from "../../reducers/teamSelectors";
import Checkbox from "@material-ui/core/Checkbox";
import _ from "lodash/fp";
import {getAllJurisdictions} from "../../reducers/globalSelectors";

const InvoiceDetails = ({
                            getSelectedTeam = _.noop,
                            selectedTeam = {},
                            onSetData = _.noop,
                            jurisdictions,
                            paymentHistory,
                            getTeamPaymentHistory = _.noop,
                            getAllJurisdictions = _.noop,
                            onSaveTeamData = _.noop,
                            paymentInProcess,
                            alwaysInEditMode = false,
                            showPrintInvoice = false


                        }) => {
    const [isPrintInvoiceDisabled, setIsPrintInvoiceDisabled] = useState(true);

    const [teamData,setTeamData] = useState({})
    const [isEditing,setIsEditing] = useState(alwaysInEditMode);
    const [isSaving,setIsSaving] = useState(false);

    const handleTeamDataChange = data => {
        setTeamData(data)
        onSetData(data)
    }
    const onCancel = () => {
        setTeamData(selectedTeam)
        setIsEditing(false)
    }

    const saveTeamData = async (e) => {
        try {
            e.preventDefault()
            setIsSaving(true)
            await onSaveTeamData(teamData)
        }finally {
            setIsSaving(false)
            setIsEditing(false)
        }
    }

    useEffect(() => {
        const asyncFetchData = async () => {
            await getAllJurisdictions()
            const result = await getSelectedTeam()
            if (result?.id) {
                await getTeamPaymentHistory(result?.id)
            }
            if (!_.isEmpty(result)) {
                setTeamData(result)
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam])

    // Effect for print invoice form validation
    useEffect(() => {
        const { useSchoolAddressForBilling, billingName, billingAddress1, billingCity, billingState, billingJurisdiction, billingPostalCode } = teamData;

        if ((useSchoolAddressForBilling === undefined ? true : useSchoolAddressForBilling) || (billingName && billingAddress1 && billingCity && billingState && billingJurisdiction && billingPostalCode)) {
            setIsPrintInvoiceDisabled(false);
        } else {
            setIsPrintInvoiceDisabled(true);
        }

    }, [teamData]);

    return (<>
        <Grid item xs={12}
              style={{background: "#F0F0F0", marginBottom: "1rem"}}>
            <Typography variant='h6'>
                Billing Information
            </Typography>
            <Typography variant='body1' style={{marginBottom: "0"}}>
                This information will be shown on the invoice
            </Typography>
                <form onSubmit={saveTeamData}>
                    <Grid style={{border: "1px solid #CCC", padding: "1rem"}}>
                        { isEditing === true &&
                        <FormControlLabel
                            control={
                                <Checkbox checked={teamData.useSchoolAddressForBilling === undefined ? true : teamData.useSchoolAddressForBilling}
                                          onChange={e => handleTeamDataChange({
                                              ...teamData,
                                              useSchoolAddressForBilling: e.target.checked
                                          })}
                                          color='primary'>

                                </Checkbox>
                            }
                            label={`Use school address for billing`}
                        />
                        }
                        {teamData.useSchoolAddressForBilling === false && isEditing === true &&
                            <>
                                <Grid container item spacing={3} sx={12}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={teamData.billingName || ''}
                                            onChange={e => handleTeamDataChange({
                                                ...teamData,
                                                billingName: e.target.value
                                            })}
                                            name='billingName'
                                            label='Name'
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container item spacing={3} sx={12}>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={teamData.billingAddress1 || ''}
                                            onChange={e => handleTeamDataChange({
                                                ...teamData,
                                                billingAddress1: e.target.value
                                            })}
                                            name='billingAddress1'
                                            label='Address Line 1'
                                            helperText='Example: Banne Bikslaan 101C'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            value={teamData.billingAddress2 || ''}
                                            onChange={e => handleTeamDataChange({
                                                ...teamData,
                                                billingAddress2: e.target.value
                                            })}
                                            name='billingAddress2'
                                            label='Address Line 2'
                                            notRequired
                                            helperText='Example: 3rd floor'
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='billingCity'
                                            value={teamData.billingCity || ''}
                                            onChange={e => handleTeamDataChange({
                                                ...teamData,
                                                billingCity: e.target.value
                                            })}
                                            label='City'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            name='billingState'
                                            value={teamData.billingState || ''}
                                            onChange={e => handleTeamDataChange({
                                                ...teamData,
                                                billingState: e.target.value
                                            })}
                                            label='State/Province'
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Autocomplete
                                            onChange={(event, newValue) => handleTeamDataChange({
                                                ...teamData,
                                                billingJurisdiction: newValue
                                            })}
                                            value={teamData.billingJurisdiction}
                                            options={jurisdictions}
                                            freeSolo
                                            autoSelect
                                            renderInput={(params) => <MUITextField {...params}
                                                                                   inputProps={{...params.inputProps,pattern: "^[\\w\\d\\(\\)\\*\\.].*"}}
                                                                                   variant="standard"
                                                                                   label='Jurisdiction'
                                                                                   required/>}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            type='zip'
                                            onChange={e => handleTeamDataChange({
                                                ...teamData,
                                                billingPostalCode: e.target.value
                                            })}
                                            value={teamData.billingPostalCode || ''}
                                            label='Postal Code'
                                            required
                                            helperText='If no postal code, enter "00000"'
                                        />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        {teamData.useSchoolAddressForBilling === false && isEditing === false &&
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{margin: "0"}}>{teamData.billingName}</Typography>
                                <Typography variant='body1' style={{margin: "0"}}>
                                    {teamData.billingAddress2 ? teamData.billingAddress1 + ' ' + teamData.billingAddress2 : teamData.billingAddress1}
                                </Typography>
                                <Typography variant='body1' style={{margin: "0"}}>
                                    {teamData.billingCity} {teamData.billingState} {teamData.billingPostalCode}
                                </Typography>
                                <Typography variant='body1' style={{margin: "0"}}>{teamData.billingJurisdiction}
                                </Typography>
                            </Grid>
                        }
                        {teamData.useSchoolAddressForBilling !== false &&
                            <Grid item xs={12}>
                                <Typography variant='body1' style={{margin: "0"}}>{teamData.school}</Typography>
                                <Typography variant='body1' style={{margin: "0"}}>
                                    {teamData.unit ? teamData.street + ' ' + teamData.unit : teamData.street} 
                                </Typography>
                                <Typography variant='body1' style={{margin: "0"}}>
                                    {teamData.city} {teamData.state} {teamData.zip}
                                </Typography>
                                <Typography variant='body1' style={{margin: "0"}}>{teamData.jurisdiction}
                                </Typography>
                            </Grid>
                        }
                        { isEditing &&
                        <Grid container item spacing={3} sx={12} style={{marginTop:'0'}}>
                            <Grid item xs={12} md={6}>                              
                                <TextField
                                    onChange={e => handleTeamDataChange({
                                        ...teamData,
                                        billingReferenceCode: e.target.value
                                    })}
                                    value={teamData.billingReferenceCode || ''}
                                    label='Reference Code'
                                    notRequired
                                    helperText='Use this field to input a reference code if needed'
                                />                                                              
                            </Grid>
                        </Grid>
                        }
                        { isEditing === false && teamData.billingReferenceCode &&
                        <Grid container item spacing={3} sx={12}>
                            <Grid item xs={12} md={6}>
                            <Typography style={{marginBottom:'0'}}>Reference Code: {teamData.billingReferenceCode}</Typography>
                            </Grid>
                        </Grid>    
                        }
                        { isEditing && !alwaysInEditMode &&
                            <Grid container item style={{marginTop: '1rem'}}>
                                <Button color="primary" style={{marginRight: '1rem'}} variant='contained' type='submit' disabled={isSaving}>Save</Button>
                                <Button onClick={() => onCancel()} disabled={isSaving}>Cancel</Button>
                                { isSaving &&
                                    <CircularProgress style={{marginLeft: '2rem'}} size={25} />
                                }
                        </Grid>
                        }
                    </Grid>
                </form>
            { isEditing === false && !alwaysInEditMode &&
                <Typography style={{marginBottom:'3rem'}}>
                    <Link component="button" style={{textDecoration:"underline"}} onClick={() => setIsEditing(true)}>Edit</Link> <Typography variant='body2' component="span">or</Typography> <Link component="button" style={{textDecoration:"underline"}} onClick={() => setIsEditing(true)}>Add reference code</Link></Typography>
            }

            { showPrintInvoice &&
                <>
                <Button color="primary"
                size="small"
                variant="outlined"
                style={{marginTop: "1rem"}}
                startIcon={<PrintOutlined/>}
                aria-haspopup="menu"
                disabled={paymentInProcess || isPrintInvoiceDisabled}
                onClick={() => downloadInvoice(teamData, paymentHistory)}>Print Invoice</Button>
                {
                    isPrintInvoiceDisabled &&
                    <Typography style={{fontSize:"0.75rem", color:"rgba(0, 0, 0, 0.6)"}}>Please fill out the required fields above to print invoice</Typography>
                }
                </>
            }
        </Grid>
    </>)
}


export default connect(
    (state, ownProps) => ({
        router: state.router,
        selectedTeam: state.team.selectedTeam,
        paymentInProcess: ownProps.paymentInProcess,
        jurisdictions: state.global.jurisdictionNames,
        onSetTeamData : ownProps.onSetTeamData,
        paymentHistory: state.team.paymentHistory
    }), {
        getAllJurisdictions: getAllJurisdictions,
        getSelectedTeam: getSelectedTeam,
        getTeamPaymentHistory: getTeamPaymentHistory
    }
)(InvoiceDetails)