import React, {useState} from 'react'
import {DataGridPro, GridToolbarContainer, LicenseInfo, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from '@mui/x-data-grid-pro';
import { GridActionsCellItem } from '@mui/x-data-grid';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {getNextRoundTypeToSelect} from "../../../../reducers/adminSelectors";
import {getRoundWinnerFieldFromRoundType} from "./OralArgUtils";
import {publishRound} from "../../../../actions/adminActions";
import {noop, get, includes, isEmpty} from "lodash/fp";
import {format} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {Button, CircularProgress, Snackbar, Tooltip, Typography} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {Edit,Gavel} from "@mui/icons-material";
import { useHistory } from 'react-router'
import Grid from "@material-ui/core/Grid";
import {Alert} from "@mui/material";
import {ADMIN} from "../../../../consts/roles";
import {getArbitratorConflictGridCell} from "../../../../utils/arbitratorUtils";
import TeamName from 'components/team/TeamName';
import {CustomTooltip} from "../../../helpers";

LicenseInfo.setLicenseKey(
    '19a99a1195c5f52fabd5f3310b083226T1JERVI6Mjk5MzAsRVhQSVJZPTE2NjQxNDA4NTIwMDAsS0VZVkVSU0lPTj0x',
);


const ColorButton = withStyles(() => ({
    root: {
      color: '#FFFFFF',
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700],
      },
    },
  }))(Button);

const EliminationScheduleTable = ({ selectedRoundSchedule = [],
                                      selectedRoundType = '',
                                      publishRound = noop,
                                      getNextRoundTypeToSelect = noop,
                                      publishRoundError = '',
                                      moot,
                                      user ={}}) => {
    const history = useHistory()

    const onEdit = pairingId => {
        history.push(`/admin/oral-arguments/edit/${pairingId}`)
    }
    const onSelectWinner = pairingId => {
        history.push(`/admin/oral-arguments/selectWinner/${pairingId}`)
    }

    const [isSaving, setIsSaving] = React.useState(false)
    const [saved, setSaved] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSaved(false);
    };

    const nextRoundTypeField = getRoundWinnerFieldFromRoundType(getNextRoundTypeToSelect(selectedRoundType))

    let isDraft = selectedRoundSchedule[0]?.draft === true;

    const CustomToolbar = () => {
        return (
          <GridToolbarContainer style={{flexDirection:'row', alignItems:'start', }}>
            {isDraft || isEmpty(selectedRoundSchedule) ?
                <>
                {/* 
                    <Typography style={{margin:'-4px -4px 0', padding:'0.25rem', width:'100%', textAlign:'center', fontSize:'11px', color:'#000000', backgroundColor:'#FFFF8A'}}>{selectedRoundType} schedule <strong>is not</strong> visible to Teams{moot.showOralDetailsArbitratorsOpenDate.notYetOpen === true ? ' and Arbitrators.' : <span>. The schedule <strong>is</strong> visible to Arbitrators without the claimant and respondent values.</span>} </Typography>
                    */}
                    <Typography style={{margin:'-4px -4px 0', padding:'0.25rem', width:'100%', textAlign:'center', fontSize:'11px', color:'#000000', backgroundColor:'#FFFF8A'}}>{selectedRoundType} schedule <strong>is not</strong> visible to Teams.</Typography>
                </>
                :
                <>
                    <Typography style={{margin:'-4px -4px 0', padding:'0.25rem', width:'100%', textAlign:'center', fontSize:'11px', color:'#000000', backgroundColor:'#B9E9C9'}}>{selectedRoundType} schedule <strong>is</strong> visible to Teams and Arbitrators.</Typography>
                </>
            }
            <GridToolbarColumnsButton className="toolbar-button" />
            <GridToolbarFilterButton className="toolbar-button" />
            <GridToolbarDensitySelector className="toolbar-button" />
            <GridToolbarExport
            className="toolbar-button"
            printOptions={{ disableToolbarButton: true }}
            csvOptions={{
                fileName: `${selectedRoundType} - ${format(new Date(),'yyyy-MM-dd')}`,
                utf8WithBom: true,
              }}
            />
           
          </GridToolbarContainer>
        );
    }

    
    const onPublish = async () => {
        try {
            setIsSaving(true)
            await publishRound(selectedRoundType)
            setSaved(true)

        }finally{
            setIsSaving(false)
        }
    }
    const columns = [
        {
            field: 'id',
            headerName: 'Id',
            flex: 1,
            hide: true
        },
        {
            field: 'claimant',
            headerName: 'Claimant',
            flex: 1,
            renderCell: params => <TeamName team={params.row.claimant} adminUse={true} />,
            valueGetter: params => (params.row.claimant?.hideTeamName === true ? `TEAM ID ${params.row.claimant?.id}` : params.row.claimant?.school)
        },
        {
            field: 'respondent',
            headerName: 'Respondent',
            flex: 1,            
            renderCell: params => <TeamName team={params.row.respondent} adminUse={true} />,
            valueGetter: params => (params.row.respondent?.hideTeamName === true ? `TEAM ID ${params.row.respondent?.id}` : params.row.respondent?.school)
        },
        {
            field: 'pairingDate',
            headerName: 'Date HKT',
            align: 'right',
            flex: 1,
            renderCell: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'iii, dd MMMM'),
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'yyyy/MM/dd'), 
        },
        {
            field: 'pairingTime',
            headerName: 'Time HKT',
            align: 'right',
            flex: 1,
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'hh:mm aaa')
        },
        {
            field: 'pairingDateTime',
            headerName: 'Date & Time HKT',
            align: 'right',
            flex: 1,
            hide: true,
            valueGetter: params => format(utcToZonedTime(params.row.pairingTime,'Asia/Hong_Kong'),'yyyy/MM/dd HH:mm'),
        },
        {
            field: 'hearingLink',
            headerName: 'Virtual Hearing Link',
            flex: 1,
            hide: true,
            sortable: false,
            renderCell: params => {
                if (!params.row.hearingLink){
                    return (<span>coming soon</span>)
                }else{
                    return (<a target="_blank" rel="noreferrer" href={`${params.row.hearingLink}`}>Click Here</a>)
                }
            }
        },
        {
            field: 'room',
            headerName: 'Room',
            flex: 1,
            sortable: false,
            renderCell: params => {
                if (!get('row.room',params)){                    
                    return <CustomTooltip content='available at check-in' />
                } else {
                    return <CustomTooltip content={params.row.room} />
                }
            }
        },
        {
            field: 'chairArbitrator',
            headerName: 'Chair',
            flex: 1,
            valueGetter: params => (`${params.row.chairArbitrator?.firstName ?? ''} ${params.row.chairArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.chairArbitrator)

        },
        {
            field: 'secondArbitrator',
            headerName: 'Second Arbitrator',
            flex: 1,
            valueGetter: params => (`${params.row.secondArbitrator?.firstName ?? ''} ${params.row.secondArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.secondArbitrator)
        },
        {
            field: 'thirdArbitrator',
            headerName: 'Third Arbitrator',
            flex: 1,
            valueGetter: params => (`${params.row.thirdArbitrator?.firstName ?? ''} ${params.row.thirdArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.thirdArbitrator)
        },
        {
            field: 'firstReserveArbitrator',
            headerName: 'First Reserve',
            flex: 1,
            hide: true,
            valueGetter: params => (`${params.row.firstReserveArbitrator?.firstName ?? ''} ${params.row.firstReserveArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.firstReserveArbitrator)
        },
        {
            field: 'secondReserveArbitrator',
            headerName: 'Second Reserve',
            flex: 1,
            hide: true,
            valueGetter: params => (`${params.row.secondReserveArbitrator?.firstName ?? ''} ${params.row.secondReserveArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.secondReserveArbitrator)
        },
        {
            field: 'thirdReserveArbitrator',
            headerName: 'Third Reserve',
            flex: 1,
            hide: true,
            valueGetter: params => (`${params.row.thirdReserveArbitrator?.firstName ?? ''} ${params.row.thirdReserveArbitrator?.lastName ?? ''}`).trim(),
            renderCell : params => getArbitratorConflictGridCell(params.row.claimant,params.row.respondent,params.row.thirdReserveArbitrator)
        },
        {
            field: 'winner',
            headerName: 'Winner',
            flex: 1,
            valueGetter: params => {
                const claimantWon = get(`claimant.EliminationRoundWinners[${nextRoundTypeField}]`, params.row) === true
                const respondentWon = get(`respondent.EliminationRoundWinners[${nextRoundTypeField}]`, params.row) === true
                
                if (claimantWon) {
                    return (get('claimant.hideTeamName', params.row) === true ? `TEAM ID ${get('claimant.id', params.row)}` : get('claimant.school', params.row))
                } else if (respondentWon) {
                    return (get('respondent.hideTeamName', params.row) === true ? `TEAM ID ${get('respondent.id', params.row)}` : get('respondent.school', params.row))
                
                }
                return ''
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Action',
            flex: 1,
            getActions: params => [
                 <GridActionsCellItem label="Edit" onClick={() => onEdit(params.row.id)}
                                      disabled={!includes(ADMIN,user.roles)}
                                      icon={
                                          <Tooltip title='Edit'>
                                              <Edit/>
                                          </Tooltip>
                                      }/>,
                 <GridActionsCellItem label="Select Winner"
                                      onClick={() => onSelectWinner(params.row.id)}
                                      disabled={!params.row.claimant || !params.row.respondent}
                                      icon={
                                          <Tooltip title='Select Winner'>
                                              <Gavel/>
                                          </Tooltip>
                                      }
                 />
                 ]
        },
    ]
    return (
        <div style={{height: 700, width: '100%'}}>
            <DataGridPro
                rows={selectedRoundSchedule}
                columns={columns}
                disableSelectionOnClick
                components={{
                    Toolbar: CustomToolbar,
                }}
            />            
                
            <Grid item xs={12} md={12}>
                <ColorButton variant='contained'
                        onClick={onPublish}
                        style={{margin: '2rem 0'}}
                        color='primary'
                        disabled={isSaving || !includes(ADMIN,user.roles) || !isDraft}>Publish</ColorButton>
                {isSaving &&
                    <CircularProgress style={{marginLeft: '2rem'}}/>
                }
            </Grid>
            {publishRoundError &&
                <Typography color='error'>{publishRoundError}</Typography>
            }                
            
            <Snackbar open={saved} onClose={handleClose} autoHideDuration={6000}>
                <Alert onClose={handleClose} severity="success" sx={{width: '100%'}}>
                    Schedule Published
                </Alert>
            </Snackbar>

        </div>
    )
}


EliminationScheduleTable.propTypes = {
    selectedRoundSchedule: PropTypes.arrayOf(PropTypes.object),
    selectedRoundType: PropTypes.string
}

export default connect(
    (state, ownProps) => ({
        selectedRoundSchedule: state.admin.selectedRoundSchedule,
        selectedRoundType: state.admin.selectedRoundType,
        publishRoundError: state.admin.publishRoundError,
        user: state.user
    }),{
        publishRound: publishRound,
        getNextRoundTypeToSelect: getNextRoundTypeToSelect
    })( EliminationScheduleTable)
