import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import teamReducer from './teamReducer'
import arbitratorReducer from './arbitratorReducer'
import adminReducer from './adminReducer'
import globalReducer from './globalReducer'
import registrationReducer from './registrationReducer'
import mootReducer from './mootReducer'
import user from './userReducer'

const rootReducer = history =>
    combineReducers({
            router: connectRouter(history),
            user,
            team: teamReducer,
            admin: adminReducer,
            arbitrator: arbitratorReducer,
            global: globalReducer,
            registration: registrationReducer,
            moot: mootReducer,
    })
export default rootReducer