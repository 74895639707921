import React, {useState} from 'react'
import Container from '@material-ui/core/Container'
import {Box, Button, TextField, Typography} from '@material-ui/core'
import {confirmPassword} from "../../utils/cognitoHelpers";
import { useHistory } from 'react-router'
import {isEmpty} from "lodash";
import {isPasswordValid} from "../../utils/validation";

const PasswordSent = (props) => {
  const [verificationCode, setVerificationCode] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newConfirmPassword, setConfirmNewPassword] = useState('')
  const [isValid,setIsValid] = useState(false)
  const [error,setError] = useState('')
   const history = useHistory()

  function getQuery(key) {
    return new URLSearchParams(window.location.search).get(key);
  }

  const handleUpdatePassword = async () => {
    try {
      await confirmPassword(getQuery("email"), verificationCode, newPassword);

      history.push('/login/password-reset/complete')
    }catch(e)
    {
      if (e.code === 'CodeMismatchException'){
        setError('The code you entered is incorrect.')
      }else{
        setError('Something went wrong. Your password was not reset. Please try again. If you continue to see this message please reach out to <a href="mailto:info@cisgmoot.org">info@cisgmoot.org</a>')
      }
    }}

    const doPasswordsMatch = newPassword === newConfirmPassword

    const isIncorrectPassword = !isPasswordValid(newPassword)

    const updateIsValid = () => {
      setIsValid(
          !isEmpty(verificationCode) &&
          !isEmpty(newPassword) &&
          !isEmpty(newConfirmPassword))
    }

    return (
      <Container maxWidth='sm'>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4'>Password Reset</Typography>
          <Typography style={{ margin: '2rem 0' }} variant='body2'>
            If the email you entered is a valid use then a password reset verification token has been sent to your email
          </Typography>
          <Typography variant='body2'>
            {' '}
            If you do not see the email in your inbox, please check your spam or
            junk folder. If you are unable to receive the email, please get in
            contact with us.
          </Typography>
        </Box>

        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography style={{ margin: '2rem 0' }} variant='body2'>
            Please enter your verification token and a new password
          </Typography>
          <TextField
              value={verificationCode}
              required
              label='Verification Code'
              onChange={e => {
                setVerificationCode(e.target.value)
                updateIsValid()
              }}
          />
          <TextField
              value={newPassword}
              required
              type='password'
              label='New Password'
              onChange={e => {
                setNewPassword(e.target.value)
                updateIsValid()
              }}
              helperText='Min. 8 characters, one lower, one upper, one number, one special'
          />
            {isIncorrectPassword && <Typography color='error'>Password not strong enough</Typography>}
            <TextField
              value={newConfirmPassword}
              required
              type='password'
              label='Confirm New Password'
              onChange={e => {
                setConfirmNewPassword(e.target.value)
                updateIsValid()
              }}
          />
            {!doPasswordsMatch && <Typography color='error'>Passwords must match</Typography>}


          <Box style={{ textAlign: 'center', margin: '2rem 0' }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
          <Button onClick={handleUpdatePassword} disabled={!isValid} variant='contained'>
            Update your Password
          </Button>
            </Box>
            <Typography color='error' variant='h6' hidden={isEmpty(error)} dangerouslySetInnerHTML={{ __html: error}}/>

          </Box>
        </Box>
      </Container>
  )
}

export default PasswordSent
