import {Button, CircularProgress, Grid, Typography} from "@material-ui/core";
import TextField from "../common/TextInput";
import FormHelperText from "@mui/material/FormHelperText";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {isEmailValid, isPasswordValid} from "../../utils/validation";
import {connect} from "react-redux";
import {signUpUser} from "../../actions/registrationActions";
import {noop} from "lodash/fp"


const SignUp = ({signUpUser = noop,onSignUpComplete = noop}) => {
    const [pending, setPending] = useState(false)

    const [email, setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')

    const emailsMatch = email === confirmEmail
    const passwordsMatch = password === confirmPassword

    const isInvalidEmail = email && !isEmailValid(email)
    const isInvalidPassword = password && !isPasswordValid(password)

    const canSubmit = emailsMatch && passwordsMatch && !isInvalidPassword && !isInvalidEmail && email && password
    const handleSignup = async (e) => {
        e.preventDefault()
        setError('')
        setPending(true)
        try {
            const result = await signUpUser(email,password)
            setPending(false)
            onSignUpComplete(result)
        } catch (error) {
            setError('Error signing up. Please try again.')
            setPending(false)
        }
    }

    return (
        <form onSubmit={handleSignup}>
            <Grid container
                  style={{flexGrow: 1, padding: '20px'}}
                  spacing={2}>

            <Grid item xs={12} md={6}>
                <TextField
                    value={email}
                    label='Login Email'
                    autocomplete="username"
                    onChange={e => setEmail(e.target.value)}
                />
                {isInvalidEmail && <Typography color='error' style={{marginTop: '-1rem'}}>Please enter Valid
                    Email</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    value={confirmEmail}
                    label='Confirm Login Email'
                    autocomplete="username"
                    onChange={e => setConfirmEmail(e.target.value)}
                />
                {!emailsMatch &&
                    <Typography color='error' style={{marginTop: '-1rem'}}>Emails must match</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name='password'
                    type='password'
                    onChange={e => setPassword(e.target.value)}
                    value={password}
                    autocomplete='new-password'
                    label='Create Password'
                    helperText='Min. 8 characters, one lower, one upper, one number, one special'
                />
                {isInvalidPassword && <Typography color='error'>Password is not strong enough</Typography>}
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    name='password'
                    type='password'
                    onChange={e => setConfirmPassword(e.target.value)}
                    value={confirmPassword}
                    autocomplete='new-password'
                    label='Confirm Password'
                />
                {!passwordsMatch &&
                    <Typography color='error' style={{marginTop: '-1rem'}}>Passwords must match</Typography>}
            </Grid>
            <Grid item xs={8} md={6} style={{display: 'flex', gap: '10px'}}>
                <Button type="submit" variant='contained' color="primary" disabled={!canSubmit}>
                    Next
                </Button>
                {pending && <CircularProgress size={35}/>}
            </Grid>
            <Grid>
                <FormHelperText style={{verticalAlign: "bottom"}}>Already have a login and password? <Link
                    to="/login" style={{color: '#000000'}}>Sign In</Link></FormHelperText>
            </Grid>
            {error && <Typography style={{color: 'red'}}>{error}</Typography>}
            </Grid>
        </form>
    )
}

export default connect((state, ownProps) => ({
    onSignUpComplete: ownProps.onSignUpComplete
}),{
    signUpUser: signUpUser
})(SignUp)
