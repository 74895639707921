import * as actionTypes from '../actions/actionTypes/mootActionTypes'
import {combineReducers} from "redux";
import _ from 'lodash/fp'


const currentMoot = (state = {}, action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_MOOTS_SUCCEEDED) {
        return _.find(m => m.active ,payload)
    } else {
        return state
    }
}

const allMoots = (state =[], action) => {
    const { type, payload } = action
    switch (type){
        case actionTypes.GET_ALL_MOOTS_SUCCEEDED:
        case actionTypes.SAVE_MOOT_SUCCEEDED:
        case actionTypes.ACTIVATE_MOOT_SUCCEEDED:
            return payload
        default:
            return state
    }
}

const selectedMoot = (state = {}, action) => {
    const {type, payload} = action
    if (type === actionTypes.SELECT_MOOT) {
        return payload
    } else
        return state
}

const saveMootError = (state ='',action) => {
    const {type} = action
    switch (type){
        case actionTypes.SAVE_MOOT_FAILED:
            //TODO How much more info should we give?
            return 'Unable to save moot. Please try again'
        case actionTypes.SAVE_MOOT_SUCCEEDED:
            return ''
        default:
            return state
    }
}


export default combineReducers({
    currentMoot,
    allMoots,
    selectedMoot,
    saveMootError

})
