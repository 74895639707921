import React from 'react'
import Container from '@material-ui/core/Container'
import { useHistory } from 'react-router'
import {Grid, Typography} from "@material-ui/core";
import {connect} from "react-redux";
import {isEmpty} from "lodash/fp";
const { Button } = require('@material-ui/core')

const Registration = ({user}) => {
    const history = useHistory()

    const buildDestinationLink = (type,destination) => {
        if (!isEmpty(user.username)){
            return destination
        }else{
            return `/${type}signup?returnTo=${destination}`
        }
    }

    return (
        <Container style={{ paddingBottom: '10rem' }}>
                <Grid container
                      style={{flexGrow: 1, padding: '20px'}}
                      spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h4'>Welcome</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='body1'>Welcome to Vis East Moot. Please select the appropriate registration form to begin</Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12} style={{display:'flex', flexWrap:'wrap', gap:'20px'}}>
                            <Button variant='contained' className='buttonRedOutline' onClick={ () => history.push(buildDestinationLink('team','/registration/team')) } >
                                Team  Registration
                            </Button>
                            <Button variant='contained' className='buttonRedOutline' onClick={ () => history.push(buildDestinationLink('arbitrator','/registration/arbitrator')) } >
                                Arbitrator Registration
                            </Button>
                        </Grid>
                        
                    </Grid>
                </Grid>
        </Container>
    )
}


export default connect(
    (state) => ({
        user: state.user,
    }))(Registration)

