import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

const SubHeading = ({ children }) => (
  <Typography style={{ margin: '1rem 0' }}>{children}</Typography>
)


const TeamAcceptedForReview = () => {
  return (
      <Container style={{ paddingBottom: '10rem', paddingTop: '4rem' }}>
        <Typography variant='h4' component='h1'>
          Registration is accepted for review
        </Typography>
        <SubHeading>
          Thank you for submitting an application to take part in the 19th Vis East Competition.
        </SubHeading>
        <SubHeading>
          An email has been sent to you with instructions on how to verify your email address. Please make sure to check your spam box for such email.
        </SubHeading>
        <SubHeading>
          If you do not receive such email within one (1) hour of submitting your application online, please email the Vis East Administration at: <a href ='mailto:info@cisgmoot.org'>info@cisgmoot.org</a> with the subject line: "CONFIRMATION EMAIL NOT RECEIVED FOR 19TH VIS EAST REGISTRATION".
        </SubHeading>
        <SubHeading>
          The Vis East Administration will only begin to process and review your application upon verification of your email address.
        </SubHeading>
      </Container>
  )
}

export default TeamAcceptedForReview
