import Container from "@material-ui/core/Container";
import {CircularProgress, Typography} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import _ from "lodash/fp";
import { useHistory} from 'react-router-dom'
import {getSelectedTeam} from "../../../reducers/teamSelectors";
import {getCurrentMoot} from "../../../reducers/mootSelectors";
import {makeStyles} from "@material-ui/core/styles";
import ordinal from "ordinal-number-suffix";
import {isNotEmpty} from "../../../utils/funcUtils";

const TeamMemberDashboard = ({selectedTeam = {}, currentMoot, currentUser, getSelectedTeam = _.noop, getCurrentMoot = _.noop}) => {

    const useStyles = makeStyles(() => ({
        root: {
            flexGrow: 1,
        }
    }))

    const history = useHistory()
    const [fetching,setFetching] = useState(false)
    const [error,setError] = useState()

    useEffect(() => {
        const asyncFetchData = async () => {
            try {
                setFetching(true)
                const team = await getSelectedTeam(true)
                await getCurrentMoot()
                setFetching(false)
            }catch(e){
                setFetching(false)
                setError('There was an error loading your information. Please try again later')
            }
        }
        asyncFetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const classes = useStyles()

    const teamMember = _.find(m => m.email === currentUser.username,selectedTeam?.members)
    const teamMemberName = `${teamMember?.firstName} ${teamMember?.lastName}`

    return (
        <Container maxWidth='lg' className={classes.root}>
            { fetching && <CircularProgress /> }
            {error && <Typography style={{ color: 'red' }}>{error}</Typography>}

            {!fetching && _.isEmpty(selectedTeam) &&
                <Typography style={{ color: 'red' }}>Your account is no longer associated with a team</Typography>
            }
            {!fetching && !error && isNotEmpty(selectedTeam) &&
                <div>
                    <Typography variant='h6' component='h1' style={{ margin: '1rem 0' }}>{teamMemberName}, congratulations on being a part of {selectedTeam.school}’s {ordinal(currentMoot.mootNumber)} Vis East Moot team!</Typography>
                    <Typography variant='body1' paragraph>Welcome to your individual Team Member account, which provides you with a  read-only access of {selectedTeam.school}’s Team Account for the {ordinal(currentMoot.mootNumber)} Vis East Moot Competition. This means that you will have on hand all important information needed for the {ordinal(currentMoot.mootNumber)} Vis East Moot Competition but <strong><u>no</u></strong> ability to make any changes. <strong><u>ONLY</u></strong> the Authorized Team Account will have the ability to make content changes to the Team Account, where allowed by the {ordinal(currentMoot.mootNumber)} Vis East Moot Competition Rules.
                    </Typography>
                    <Typography variant='body1' paragraph>All the information provided in the {selectedTeam.school}’s Team Account is available for you to see. In order to access {selectedTeam.school}’s Team Account, please click on the MENU button on the top right of the screen.</Typography>
                    <Typography variant='body1' paragraph>For any questions or concerns, whether with your Team Account or the Vis East Moot in general, please do not hesitate to contact the Vis East Moot Administration via email:  <a href="mailto:info@cisgmoot.org" className="color-blue">info@cisgmoot.org</a>.</Typography>                    
                </div>
            }
        </Container>
    )
}

export default connect(
    (state, ownProps) => ({
        selectedTeam: state.team.selectedTeam,
        currentMoot: state.moot.currentMoot,
        currentUser: state.user,
    }),{
        getSelectedTeam: getSelectedTeam,
        getCurrentMoot: getCurrentMoot,
    })( TeamMemberDashboard)