import * as actionTypes from '../actions/actionTypes/adminActionTypes'
import {combineReducers} from "redux";
import {filter, find, map, size, sumBy, uniq, uniqBy, head, join, compact} from "lodash/fp";
import {TEAM_ROLE_TYPE} from "../utils/constants";

const clarifications = (state =[], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_CLARIFICATIONS_SUCCEEDED) {
        return payload
    }else {
        return state
    }
}

const arbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
            return payload
    } else {
        return state
    }
}
const assignableClaimantArbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
        const arbs = map(a => {
            if (a.status === 'accepted') {
                if (a.availableForClaimantEvaluation &&
                    (
                        a.participation === 'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                        || a.participation === 'I am available ONLY to rank written memoranda'
                    )
                ){
                    return {
                        ...a,
                        conflictingJurisdictions: join(',',map(c => c.name, a.conflictingJurisdictions)),
                        affiliatedSchools: join(',',map(c => c.name,a.affiliatedSchools))
                    }
            }else {
                    return null
                }
        }}, payload)

        return compact(arbs)
    } else {
        return state
    }
}

const juryAssignableClaimantArbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
        const arbs = map(a => {
            if (a.status === 'accepted') {
                if (a.availableForRankingClaimant &&
                    (
                        a.participation === 'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                        || a.participation === 'I am available ONLY to rank written memoranda'
                    )
                ){
                    return {
                        ...a,
                        conflictingJurisdictions: join(',',map(c => c.name, a.conflictingJurisdictions)),
                        affiliatedSchools: join(',',map(c => c.name,a.affiliatedSchools))
                    }
                }else {
                    return null
                }
            }}, payload)

        return compact(arbs)
    } else {
        return state
    }
}

const juryAssignedClaimantArbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
        return compact(map(a => {
            if (a.selectedToRankJuryRoundClaimants) {
                return {
                    ...a,
                    conflictingJurisdictions: join(',', map(c => c.name, a.conflictingJurisdictions)),
                    affiliatedSchools: join(',', map(c => c.name, a.affiliatedSchools))
                }
            }else{
                return null
            }
        },payload))
    }else{
    return state
    }
}

const juryAssignedRespondentArbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
        return compact(map(a => {
            if (a.selectedToRankJuryRoundRespondents) {
                return {
                    ...a,
                    conflictingJurisdictions: join(',', map(c => c.name, a.conflictingJurisdictions)),
                    affiliatedSchools: join(',', map(c => c.name, a.affiliatedSchools))
                }
            }else{
                return null
            }
        },payload))
    }else{
        return state
    }
}

const assignableRespondentArbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
        const arbs = map(a => {
            if (a.status === 'accepted') {
                if (a.availableForRespondentEvaluation &&
                    (
                        a.participation === 'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                        || a.participation === 'I am available ONLY to rank written memoranda'
                    )
                ){
                    return {
                        ...a,
                        conflictingJurisdictions: join(',',map(c => c.name, a.conflictingJurisdictions)),
                        affiliatedSchools: join(',',map(c => c.name,a.affiliatedSchools))
                    }
                }else {
                    return null
                }
            }}, payload)
        return compact(arbs)
    } else {
        return state
    }
}

const juryAssignableRespondentArbitrators = (state =[],action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_ARBITRATORS_SUCCEEDED) {
        const arbs = map(a => {
            if (a.status === 'accepted') {
                if (a.availableForRankingRespondent &&
                    (
                        a.participation === 'I am available to BOTH rank written memoranda AND act as an arbitrator during oral arguments week'
                        || a.participation === 'I am available ONLY to rank written memoranda'
                    )
                ){
                    return {
                        ...a,
                        conflictingJurisdictions: join(',',map(c => c.name, a.conflictingJurisdictions)),
                        affiliatedSchools: join(',',map(c => c.name,a.affiliatedSchools))
                    }
                }else {
                    return null
                }
            }}, payload)
        return compact(arbs)
    } else {
        return state
    }
}

const teams = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_TEAMS_SUCCEEDED) {
        return payload
    }else {
        return state
    }
}

const pairing = (state = [], action) =>{
    const { type, payload } = action
    if (type === actionTypes.RUN_PAIRING_SUCCEEDED) {
        return payload
    }else{
        return state
    }
}

const claimantRankings = (state =[], action) =>{
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_CLAIMANT_MEMO_RANKINGS_SUCCEEDED) {

        return payload
    }else{
        return state
    }
}
const respondentRankings = (state =[], action) =>{
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_RESPONDENT_MEMO_RANKINGS_SUCCEEDED) {

        return payload
    }else{
        return state
    }
}


const juryClaimantRankings = (state =[], action) =>{
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_JURY_CLAIMANT_MEMO_RANKINGS_SUCCEEDED) {

        return payload
    }else{
        return state
    }
}

const juryRespondentRankings = (state =[], action) =>{
    const { type, payload } = action
    if (type === actionTypes.GET_ALL_JURY_RESPONDENT_MEMO_RANKINGS_SUCCEEDED) {

        return payload
    }else{
        return state
    }
}

const oralArgumentSchedule = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED) {
        return payload
    }else{
        return state
    }
}

const selectedPairing = (state = {}, action) => {
    const { type, payload } = action
    if (type === actionTypes.SELECT_PAIRING && payload) {
        return payload
    }else{
        return state
    }
}

const pairingSaveError = (state ='', action) => {
    const { type, payload } = action
    if (type === actionTypes.SAVE_PAIRING_FAILED && payload) {
        return 'Unable to save pairing edits. Please try again'
    }else if (type === actionTypes.SAVE_PAIRING_SUCCEEDED && payload) {
        return ''
    }else{
        return state
    }
}
const scoringSaveError = (state ='', action) => {
    const { type, payload } = action
    if (type === actionTypes.SAVE_SCORES_FAILED && payload) {
        return 'Unable to save score. Please try again'
    }
    else if (type === actionTypes.SAVE_SCORES_SUCCEEDED && payload) {
        return ''
    }else{
        return state
    }
}

const rounds = (state = [], action) =>{
    const { type, payload } = action
    if (type === actionTypes.GET_ROUNDS_SUCCEEDED) {
        return payload
    }else{
        return state
    }
}

const selectedRound = (state = {}, action) =>{
    const { type, payload } = action
    if (type === actionTypes.SELECT_ROUND && payload) {
        return payload
    } else{
        return state
    }
}

const selectedRoundSchedule = (state = [], action) =>{
    const { type, payload } = action
    if (type === actionTypes.GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED){
        return payload
    }else{
        return state
    }
}

const buildClaimantTeamScore = (scheduleEntry,existingScores) => {
    const claimantScore = scheduleEntry.PairingScore?.firstClaimantChairScore
        + scheduleEntry.PairingScore?.firstClaimantArb1Score
        + scheduleEntry.PairingScore?.firstClaimantArb2Score
        + scheduleEntry.PairingScore?.secondClaimantChairScore
        + scheduleEntry.PairingScore?.secondClaimantArb1Score
        + scheduleEntry.PairingScore?.secondClaimantArb2Score

    const entry = {
        teamId: scheduleEntry.claimantId,
        team: scheduleEntry.claimant,
        jurisdiction: scheduleEntry.claimant.jurisdiction,
        roundWinners: scheduleEntry.claimant.EliminationRoundWinners,
        scores: [{ isPractice:scheduleEntry.claimantPractice, score: claimantScore || 0}],
        roundAlreadyPaired: scheduleEntry.roundAlreadyPaired
    }
    const existingTeamScore = find(s => s.teamId === entry.teamId,existingScores)

    if (existingTeamScore){
        existingTeamScore.scores.push(...entry.scores)
    }else{
        existingScores.push(entry)
    }
    return existingScores
}


const buildRespondentTeamScore = (scheduleEntry,existingScores) => {
    const respondentScore = scheduleEntry.PairingScore?.firstRespondentChairScore
        + scheduleEntry.PairingScore?.firstRespondentArb1Score
        + scheduleEntry.PairingScore?.firstRespondentArb2Score
        + scheduleEntry.PairingScore?.secondRespondentChairScore
        + scheduleEntry.PairingScore?.secondRespondentArb1Score
        + scheduleEntry.PairingScore?.secondRespondentArb2Score

    const entry = {
        teamId: scheduleEntry.respondentId,
        team: scheduleEntry.respondent,
        jurisdiction: scheduleEntry.respondent.jurisdiction,
        roundWinners: scheduleEntry.respondent.EliminationRoundWinners,
        scores: [{ isPractice: scheduleEntry.respondentPractice, score: respondentScore || 0}],
        roundAlreadyPaired: scheduleEntry.roundAlreadyPaired
    }

    const existingTeamScore = find(s => s.teamId === entry.teamId,existingScores)

    if (existingTeamScore){
        existingTeamScore.scores.push(...entry.scores)
    }else{
        existingScores.push(entry)
    }

    return existingScores

}

const buildTeamScores = (roundSchedule,) => {
    let totalScores = []
    for (let i = 0; i < size(roundSchedule); i++) {
        const scheduleEntry = roundSchedule[i]
        totalScores = buildClaimantTeamScore(scheduleEntry, totalScores)
        totalScores = buildRespondentTeamScore(scheduleEntry, totalScores)
    }
    return map(s => {
            const nonPracticeScores = filter(score => score.isPractice === false,s.scores)
            return {
                id: s.teamId,
                hideTeamName: s.hideTeamName,
                team: s.team,
                jurisdiction: s.jurisdiction,
                scores: s.scores,
                total: sumBy( np => np.score,nonPracticeScores),
                roundWinners: s.roundWinners,
                roundAlreadyPaired: s.roundAlreadyPaired
            }
        }
    , totalScores)
}

const buildEliminationTeamResults = roundSchedule => {
    let totalScores = []
    for (let i = 0; i < size(roundSchedule); i++) {
        const scheduleEntry = roundSchedule[i]
        if (scheduleEntry.claimantId) {
            const claimantEntry = {
                id: scheduleEntry.claimantId,
                team: scheduleEntry.claimant,
                jurisdiction: scheduleEntry.claimant?.jurisdiction,
                roundWinners: scheduleEntry.claimant?.EliminationRoundWinners,
                roundAlreadyPaired: scheduleEntry.roundAlreadyPaired
            }
            totalScores.push(claimantEntry)
        }
        if (scheduleEntry.respondentId) {
            const respondentEntry = {
                id: scheduleEntry.respondentId,
                team: scheduleEntry.respondent,
                jurisdiction: scheduleEntry.respondent?.jurisdiction,
                roundWinners: scheduleEntry.respondent?.EliminationRoundWinners,
                roundAlreadyPaired: scheduleEntry.roundAlreadyPaired
            }
            totalScores.push(respondentEntry)
        }
    }
   return totalScores
}


const buildClaimantScore = (scheduleEntry,existingScores) => {
    if ( scheduleEntry.PairingScore?.firstClaimantId && scheduleEntry.PairingScore?.secondClaimantId) {
        const firstClaimantScore = scheduleEntry.PairingScore?.firstClaimantChairScore
            + scheduleEntry.PairingScore?.firstClaimantArb1Score
            + scheduleEntry.PairingScore?.firstClaimantArb2Score

        const secondClaimantScore = scheduleEntry.PairingScore?.secondClaimantChairScore
            + scheduleEntry.PairingScore?.secondClaimantArb1Score
            + scheduleEntry.PairingScore?.secondClaimantArb2Score

        const firstClaimantEntry = {
            id: scheduleEntry.PairingScore.firstClaimantId,
            firstName: scheduleEntry.PairingScore.firstClaimant.firstName,
            lastName: scheduleEntry.PairingScore.firstClaimant.lastName,
            school: scheduleEntry.claimant,
            jurisdiction: scheduleEntry.claimant.jurisdiction,
            scores: []
        }
        const secondClaimantEntry = {
            id: scheduleEntry.PairingScore.secondClaimantId,
            firstName: scheduleEntry.PairingScore.secondClaimant.firstName,
            lastName: scheduleEntry.PairingScore.secondClaimant.lastName,
            school: scheduleEntry.claimant,
            jurisdiction: scheduleEntry.claimant.jurisdiction,
            scores: []
        }

        firstClaimantEntry.scores.push({scoreType: TEAM_ROLE_TYPE.CLAIMANT, isPractice: scheduleEntry.claimantPractice, score: firstClaimantScore || 0})
        secondClaimantEntry.scores.push({scoreType: TEAM_ROLE_TYPE.CLAIMANT, isPractice: scheduleEntry.claimantPractice, score: secondClaimantScore || 0})

        const existingFirstClaimantScore = find(s => s.id === firstClaimantEntry.id, existingScores)
        const existingSecondClaimantScore = find(s => s.id === secondClaimantEntry.id, existingScores)

        if (existingFirstClaimantScore) {
            existingFirstClaimantScore.scores.push(...firstClaimantEntry.scores)
        } else {
            existingScores.push(firstClaimantEntry)
        }

        if (existingSecondClaimantScore) {
            existingSecondClaimantScore.scores.push(...secondClaimantEntry.scores)
        } else {
            existingScores.push(secondClaimantEntry)
        }
    }
    return existingScores
}

const buildRespondentScore = (scheduleEntry,existingScores) => {
    if (scheduleEntry.PairingScore?.firstRespondentId && scheduleEntry.PairingScore?.secondRespondentId) {
        const firstRespondentScore = scheduleEntry.PairingScore?.firstRespondentChairScore
            + scheduleEntry.PairingScore?.firstRespondentArb1Score
            + scheduleEntry.PairingScore?.firstRespondentArb2Score

        const secondRespondentScore = scheduleEntry.PairingScore?.secondRespondentChairScore
            + scheduleEntry.PairingScore?.secondRespondentArb1Score
            + scheduleEntry.PairingScore?.secondRespondentArb2Score

        const firstRespondentEntry = {
            id: scheduleEntry.PairingScore.firstRespondentId,
            school: scheduleEntry.respondent,
            firstName: scheduleEntry.PairingScore.firstRespondent.firstName,
            lastName: scheduleEntry.PairingScore.firstRespondent.lastName,
            jurisdiction: scheduleEntry.respondent.jurisdiction,
            scores: []
        }
        const secondRespondentEntry = {
            id: scheduleEntry.PairingScore.secondRespondentId,
            school: scheduleEntry.respondent,
            firstName: scheduleEntry.PairingScore.secondRespondent.firstName,
            lastName: scheduleEntry.PairingScore.secondRespondent.lastName,
            jurisdiction: scheduleEntry.respondent.jurisdiction,
            scores: []
        }

        firstRespondentEntry.scores.push({scoreType: TEAM_ROLE_TYPE.RESPONDENT, isPractice: scheduleEntry.respondentPractice, score: firstRespondentScore || 0})
        secondRespondentEntry.scores.push({scoreType: TEAM_ROLE_TYPE.RESPONDENT, isPractice: scheduleEntry.respondentPractice, score: secondRespondentScore|| 0})

        const existingFirstRespondentScore = find(s => s.id === firstRespondentEntry.id, existingScores)
        const existingSecondRespondentScore = find(s => s.id === secondRespondentEntry.id, existingScores)

        if (existingFirstRespondentScore) {
            existingFirstRespondentScore.scores.push(...firstRespondentEntry.scores)
        } else {
            existingScores.push(firstRespondentEntry)
        }

        if (existingSecondRespondentScore) {
            existingSecondRespondentScore.scores.push(...secondRespondentEntry.scores)
        } else {
            existingScores.push(secondRespondentEntry)
        }
    }
    return existingScores
}
const buildOralistScores = roundSchedule => {
    let totalScores = []

    for (let i =0; i < size(roundSchedule); i++){
        const scheduleEntry = roundSchedule[i]
        totalScores = buildClaimantScore(scheduleEntry,totalScores)
        totalScores = buildRespondentScore(scheduleEntry,totalScores)
    }
    return map(s =>
        {
            const nonPracticeScores = filter(s => s.isPractice === false,s.scores)

            return {
                id: s.id,
                firstName: s.firstName,
                lastName: s.lastName,
                school: s.school,
                jurisdiction: s.jurisdiction,
                timesClaimant: sumBy(t => t.scoreType === TEAM_ROLE_TYPE.CLAIMANT ? 1 : 0, nonPracticeScores),
                timesRespondent: sumBy(t => t.scoreType === TEAM_ROLE_TYPE.RESPONDENT ? 1 : 0, nonPracticeScores),
                scores: s.scores,
                total: sumBy(t => t.score, nonPracticeScores),
                avg: sumBy(t => t.score, nonPracticeScores) / size(nonPracticeScores)
            }
        }, totalScores)
}

const selectedRoundTeamResults = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED){
        const firstRound = head(payload)
        if (!firstRound) {
            return []
        }

        const roundType = firstRound.roundType
        if (roundType === 'General Rounds') {
            return buildTeamScores(payload)
        }else{
            return buildEliminationTeamResults(payload)
        }
    }else {
        return state
    }
}

const selectedRoundOralistResults = (state = [], action) => {
    const { type, payload } = action
    if (type === actionTypes.GET_ORAL_ARGUMENT_SCHEDULE_SUCCEEDED){
        const roundSchedule = filter(a => a.roundType === 'General Rounds',payload)
        return buildOralistScores(roundSchedule)
    }else {
        return state
    }
}

const availableSchools = (state = [], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ALL_TEAMS_SUCCEEDED) {
        const acceptedSchools = filter(p => p.status === 'accepted',payload)
        return uniq(map(p => ({ name: p.school, id: p.id}),acceptedSchools))
    }else {
        return state
    }
}
const selectedRoundType = (state = '', action) => {
    const {type, payload} = action
    if (type === actionTypes.SELECT_ROUND) {
        return payload.roundType
    }else {
        return state
    }
}

const roundTypes = (state = [], action) => {
    const {type, payload} = action
    if (type === actionTypes.GET_ROUNDS_SUCCEEDED) {
        return map(rt => rt.roundType,uniqBy(r => r.roundType,payload))
    }else {
        return state
    }
}

const saveWinnerError = (state = '', action) => {
    const {type } = action
    if (type === actionTypes.SAVE_ROUND_WINNERS_FAILED){
        return 'Unable to save winner. Please try again'}
    else if (type === actionTypes.SAVE_ROUND_WINNERS_SUCCEEDED){
        return ''
    }else {
        return state
    }
}

const pairRoundError = (state = '', action) => {
    const {type } = action
    if (type === actionTypes.PAIR_ROUND_FAILED || type === actionTypes.PAIR_WINNER_ROUND_FAILED){
        return 'Unable to pair round. Please try again'}
    else if (type === actionTypes.PAIR_ROUND_SUCCEEDED || actionTypes.PAIR_WINNER_ROUND_SUCCEEDED){
        return ''
    }else {
        return state
    }

}


const publishRoundError = (state = '', action) => {
    const {type } = action
    if (type === actionTypes.PUBLISH_ROUND_FAILED){
        return 'Failed to publish round. Please try again'}
    else if (type === actionTypes.PUBLISH_ROUND_SUCCEEDED){
        return ''
    }else {
        return state
    }
}

const claimantAssignments = (state =[], action) => {
    const {type } = action
    if (type === actionTypes.GET_ALL_CLAIMANT_MEMO_ASSIGNMENTS_SUCCEEDED){
        return action.payload
    }else {
        return state
    }
}

const respondentAssignments = (state =[], action) => {
    const {type } = action
    if (type === actionTypes.GET_ALL_RESPONDENT_MEMO_ASSIGNMENTS_SUCCEEDED){
        return action.payload
    }else {
        return state
    }
}

const assignableTeams = (state =[], action) => {
    const {type } = action
    if (type === actionTypes.GET_MEMO_ASSIGNABLE_TEAMS_SUCCEEDED){
        return action.payload
    }else {
        return state
    }
}


export default combineReducers({
    arbitrators,
    assignableClaimantArbitrators,
    juryAssignableClaimantArbitrators,
    assignableRespondentArbitrators,
    juryAssignableRespondentArbitrators,
    juryAssignedClaimantArbitrators,
    juryAssignedRespondentArbitrators,
    teams,
    assignableTeams,
    clarifications,
    pairing,
    claimantRankings,
    claimantAssignments,
    respondentAssignments,
    respondentRankings,
    juryClaimantRankings,
    juryRespondentRankings,
    oralArgumentSchedule,
    selectedPairing,
    pairingSaveError,
    scoringSaveError,
    rounds,
    selectedRound,
    selectedRoundType,
    roundTypes,
    selectedRoundSchedule,
    selectedRoundTeamResults,
    selectedRoundOralistResults,
    availableSchools,
    saveWinnerError,
    publishRoundError,
    pairRoundError
})

