import React from 'react'
import Container from '@material-ui/core/Container'
import {Box, Typography} from '@material-ui/core'
import {NavLink} from "react-router-dom";

const PasswordResetComplete = () => {
  return (
      <Container maxWidth='sm'>
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant='h4'>Password Reset</Typography>
          <Typography style={{ margin: '2rem 0' }} variant='body2'>
            Your password has been reset
          </Typography>
          <NavLink to='/login'>Click here to login</NavLink>
        </Box>
      </Container>
  )
}

export default PasswordResetComplete
